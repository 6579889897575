import React, { useRef } from "react";
import { useTableStyles } from "../../../../../styles/table.style";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Button,
  IconButton,
} from "@material-ui/core";
import clsx from "clsx";
import PrintIcon from "@material-ui/icons/Print";
import {
  LoadPOSInvById,
  SetPOSInvToPrint,
} from "../../../../../store/checkout/checkout.action";
import { useDispatch, useSelector } from "react-redux";
import { ComponentToPrint } from "../../../../../components/componentToPrint";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { useTranslation } from "react-i18next";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { Ticket } from "../../../../payement/components/ticket";

const useStyles = makeStyles((theme) => ({
  pointer: {
    cursor: "pointer",
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 5px",
    "& .MuiTableRow-root": {
      display: "table",
      width: "100%",
      tableLayout: "auto",
    },
    marginBottom: "15px",
  },
  tableBody: {
    display: "block",
    height: 200,
    overflow: "auto",
  },
  tableRow: {
    height: 25,
    "& .MuiTableCell-root:first-child": {
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
    },
    "& .MuiTableCell-root:last-child": {
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
    },
  },
  print: {
    display: "none",
  },
}));

export const RecentOrdersTable = ({ data }) => {
 
  const tableClasses = useTableStyles();
  const classes = useStyles();
  const invoice2Print = useRef();
  const componentRefTicket = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation(["common"]);
  const posInv2Print = useSelector((state) => state.Checkout.posInv2Print);
  const contactCompany = useSelector((state) => state.Login.selectedData);

  const handleDuplicataPrint = useReactToPrint({
    content: () => componentRefTicket.current,
    onAfterPrint: () => dispatch(SetPOSInvToPrint(null)),
  });

  return (
    <Box className={tableClasses.paper} mt={3}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow className={classes.tableRow}>
            <TableCell className={tableClasses.tablecellHeader}>
              {t("common:ref")}
            </TableCell>
            <TableCell className={tableClasses.tablecellHeader}>
              {t("common:client")}
            </TableCell>
            <TableCell className={tableClasses.tablecellHeader}>
              {t("common:MontTotal")}
            </TableCell>
            <TableCell className={tableClasses.tablecellHeader}>
              {t("common:dateAchat")}
            </TableCell>
            <TableCell
              className={tableClasses.tablecellHeader}
              align="right"
              width={10}
            ></TableCell>
            <TableCell
              className={tableClasses.tablecellHeader}
              align="right"
              width={10}
            ></TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {data &&
            Array.isArray(data) &&
            data.length !== 0 &&
            data?.map((row, i) => (
              <TableRow className={classes.tableRow} key={i}>
                <TableCell className={tableClasses.tableCell}>
                  {row.name ?? "-"}
                </TableCell>
                <TableCell className={tableClasses.tableCell}>
                  {row.customer ?? "-"}
                </TableCell>
                <TableCell className={tableClasses.tableCell}>
                  {row.grand_total?.toFixed(3) ?? "-"} {row.currency}
                </TableCell>
                <TableCell className={tableClasses.tableCell}>
                  {row?.posting_date ?? "-"}
                </TableCell>
                <TableCell
                  className={clsx(tableClasses.tableCell, classes.pointer)}
                  align="right"
                  width={10}
                >
                  <ReactToPrint
                    trigger={() => <PrintIcon />}
                    content={() => posInv2Print && invoice2Print.current}
                    onBeforeGetContent={() =>
                      dispatch(LoadPOSInvById(row.name))
                    }
                    onAfterPrint={() => dispatch(SetPOSInvToPrint(null))}
                  />
                </TableCell>
                <TableCell
                  className={clsx(tableClasses.tableCell, classes.pointer)}
                  align="right"
                  width={10}
                >
                  <ReactToPrint
                    trigger={() => <FileCopyIcon />}
                    content={() =>
                      componentRefTicket && componentRefTicket.current
                    }
                    onBeforeGetContent={() =>
                      dispatch(LoadPOSInvById(row.name))
                    }
                    onAfterPrint={() => dispatch(SetPOSInvToPrint(null))}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <div className={classes.print}>
        {posInv2Print && (
          <ComponentToPrint
            isFactureClient={true}
            isDeliveryDocument={false}
            document={posInv2Print}
            order={posInv2Print}
            title={"Facture"}
            ref={invoice2Print}
            contactCompany={contactCompany && contactCompany.contact}
          />
        )}
      </div>
      <div className={classes.print}>
        {posInv2Print && (
          <Ticket
            ref={componentRefTicket}
            isDuplicata={true}
            row={posInv2Print}
          />
        )}
      </div>
    </Box>
  );
};
