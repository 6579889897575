import React from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Tabs, Tab, Box } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { MaterialRequest } from "./components/materialReaquest";
import { QuotationRequest } from "./components/quotationRequest";
import { SupplierQuotation } from "./components/supplierQuotation";
import { PurchaseRequest } from "./components/purchaseRequest";
import { PurchaseInvoiceRequest } from "./components/purchaseInvoiceReq";
import { PaymentEntryRequest } from "./components/paymentEntryReq";
import { useTranslation } from "react-i18next";
export const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    "& .MuiTabs-flexContainer": {
      borderBottom: "2px solid #ECF2F5",
    },
  },
  tab: {
    borderBottom: "none",
    fontSize: "0.8rem",
  },
}));

export const BuyingTabs = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation(["buying"]);
  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();

  const [value, setValue] = React.useState(
    query.get("tab") ? parseInt(query.get("tab")) : 0
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onTabClick = (tab) => history.push(`/buying?tab=${tab}`);

  return (
    <div className={classes.root}>
      <Box position="static" color="default">
        <Tabs
          value={value}
          className={classes.tabs}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab
            className={classes.tab}
            label={t("buying:MaterialRequest")}
            {...a11yProps(0)}
            onClick={() => onTabClick(0)}
          />
          {/* <Tab
            className={classes.tab}
            label={t("buying:RequestQuotation")}
            {...a11yProps(1)}
            onClick={() => onTabClick(1)}
          /> */}
          <Tab
            className={classes.tab}
            label={t("buying:quotationList")}
            {...a11yProps(1)}
            onClick={() => onTabClick(1)}
          />
          <Tab
            className={classes.tab}
            label={t("buying:PurchaseOrder")}
            {...a11yProps(1)}
            onClick={() => onTabClick(2)}
          />
          <Tab
            className={classes.tab}
            label={t("buying:PurchaseReceipt")}
            {...a11yProps(1)}
            onClick={() => onTabClick(3)}
          />
          <Tab
            className={classes.tab}
            label={t("buying:PurchaseInvoice")}
            {...a11yProps(1)}
            onClick={() => onTabClick(4)}
          />
          {/*   <Tab className={classes.tab} label={t("buying:Payment")} {...a11yProps(5)} onClick={() => onTabClick(5)}/> */}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <MaterialRequest />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <SupplierQuotation />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <PurchaseRequest />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <PurchaseInvoiceRequest />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <PaymentEntryRequest />
      </TabPanel>
    </div>
  );
};
