import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useStyles } from "../../style";
import {
  Box,
  Radio,
  FormControl,
  FormControlLabel,
  Typography,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { fr } from "date-fns/esm/locale";
import DateFnsUtils from "@date-io/date-fns";
import { formatDate } from "../../../../helpers/utils";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { createCoffreOperation } from "../../../../store/pos/pos.action";
import { UpdatePayment } from "../../../../store/checkout/checkout.action";

export const FormCheque = (props) => {
  const { handleRedirect, selectedPayment } = props;
  const selectedData = useSelector((state) => state.Login.selectedData);
  const posOpeningEntry = useSelector((state) => state.Pos.open);
  const companyacounts = useSelector(
    (state) => state.Configuration.companyacounts
  );
  const amount = useSelector((state) => state.Checkout.amount);
  const cheques = useSelector((state) => state.Checkout.amount.cheques);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation(["payement", "common", "checkout"]);
 
  const [chequeData, setChequeData] = useState({
    type: "cheque",
    ref: "",
    bankName: "",
    issueDate: formatDate(new Date(), "YYYY-MM-DD"),
    chqHolder: "",
  });
  const [errchequeData, setErrchequeData] = useState({
    ref: false,
    bankName: false,
    issueDate: false,
    chqHolder: false,
  });
  const handleChangeChequeData = (e) => {
      let value = e.target.value;
      if (e.target.name === "chqHolder") {
          value = value.replace(/[0-9]/g, "");
      }
      setChequeData({ ...chequeData, [e.target.name]: value });
  };
  const handleChangeBankNameData = (e) => {
      let value = e.target.value;
      if (e.target.name === "bankName") {
          value = value.replace(/[0-9]/g, "");
      }
      setChequeData({ ...chequeData, [e.target.name]: value });
  };
  const handleConfirmer = () => {
    const err = {
      chqHolder: !chequeData.chqHolder || chequeData.chqHolder === "",
      ref: !chequeData.ref || chequeData.ref === "",
      bankName: !chequeData.bankName || chequeData.bankName === "",
      issueDate:
        !chequeData.issueDate ||
        chequeData.issueDate < formatDate(new Date(), "YYYY-MM-DD"),
    };
    setErrchequeData(err);
    if (JSON.stringify(err).indexOf("true") < 0) {
      cheques.push({
        ref_cheque: chequeData.ref,
        type_cheque: chequeData.type,
        bank: chequeData.bankName,
        issue_date: chequeData.issueDate,
        cheque_holder: chequeData.chqHolder,
        amount: selectedPayment?.amount,
      });
      dispatch(
        UpdatePayment({
          ...amount,
          cheques,
        })
      );
      handleRedirect();
    }
  };
  return (
    <div className={classes.paper}>
      <Typography
        align={"center"}
        color={"primary"}
        style={{ fontWeight: 700 }}
      >
        {`${t("payement:formchequepayment")}`}
      </Typography>

      <Grid container spacing={2} style={{ padding: "20px 0px" }}>
        <Grid
          item
          xs={12}
          md={6}
          style={{ display: "flex", flexDirection: "column", gap: 15 }}
        >
          <FormControl required variant="outlined">
            <InputLabel required>{t("payement:banqueName")}</InputLabel>
            <OutlinedInput
              label={t("payement:banqueName")}
              id="bankName"
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
              labelWidth={0}
              name="bankName"
              error={errchequeData.bankName}
              value={chequeData.bankName}
              onChange={handleChangeBankNameData}
            />
          </FormControl>
          <FormControl required variant="outlined">
            <InputLabel required>{t("payement:refNo")}</InputLabel>
            <OutlinedInput
              label={t("payement:refNo")}
              id="ref"
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
              labelWidth={0}
              name="ref"
              error={errchequeData.ref}
              value={chequeData.ref}
              onChange={handleChangeChequeData}
            />
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{ display: "flex", flexDirection: "column", gap: 15 }}
        >
          <FormControl variant="outlined">
            <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
              <KeyboardDatePicker
                label={t("payement:issueDate")}
                value={chequeData.issueDate}
                onChange={(newDate) =>
                  handleChangeChequeData({
                    target: {
                      name: "issueDate",
                      value: formatDate(new Date(newDate), "YYYY-MM-DD"),
                    },
                  })
                }
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                inputVariant="outlined"
                id="issueDate"
                name="issueDate"
                error={errchequeData.issueDate}
                keyboardIcon={<span className={"icon-calendar"} />}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
          <FormControl required variant="outlined">
            <InputLabel required>{t("payement:chqHolder")}</InputLabel>
            <OutlinedInput
              label={t("payement:chqHolder")}
              id="chqHolder"
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
              labelWidth={0}
              name="chqHolder"
              error={errchequeData.chqHolder}
              value={chequeData.chqHolder}
              onChange={handleChangeChequeData}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="space-around" >
        <Button
          variant="contained"
          color="secondary"
          onClick={() =>  handleRedirect()}
        >
          {t("common:annuler")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleConfirmer()}
        >
          {t("common:confirmer")}
        </Button>
      </Box>
    </div>
  );
};
