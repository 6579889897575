import React, {useEffect, useState} from "react";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {InvoicesTable} from "./components/invoicestable";
import {OrdersTable} from "./components/orderstable";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import {useSelector, useDispatch} from "react-redux";
import * as localforage from "localforage";
import Pagination from "@material-ui/lab/Pagination";
import {
    GlobalfilterData,
    GetFilterItems,
    FilterBy,
} from "../../helpers/helper";
import {LoadPOSInvoices} from "../../store/pos/pos.action";
import {useTranslation} from "react-i18next";
import {Loader} from "../../components/loader";
import {Empty} from "../../components/empty";
import {LoadFullfilments} from "../../store/fullfilment/fullfilment.action";
import {Filter} from "../../components/filter";
import DataRender from "../../components/tables/DataRender";
import {useHistory} from "react-router-dom";
import { InvoicesTableOrder } from "./components/invoicesordertable";

function TabPanel(props) {
    const {children, value, index, ...other} = props;
    const globalDefaults = useSelector((state) => state.Login.globalDefaults);
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Box>{children}</Box>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
}
const useStyles = makeStyles((theme) => ({
    header: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        margin: "0.75rem 0px 0.8125rem",
        flexWrap: "wrap",
        "& .MuiInputBase-root": {
            background: theme.palette.white.main,
        },
        "& .MuiOutlinedInput-input": {
            padding: "8.9px 14px",
            fontSize: 12,
        },
    },
    formControl: {
        maxWidth: 300,
        [theme.breakpoints.down("xs")]: {
            marginTop: 10,
        },
    },
    button: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.blue.main,
        border: "1px solid",
        borderColor: theme.palette.secondary.main,
        fontSize: "0.875rem",
        borderRadius: 10,
    },
    icon: {
        marginRight: "0.375rem",
    },
    tabs: {
        "& .MuiTabs-scroller": {
            padding: "0px 30px",
        },
        "& .MuiTabs-flexContainer": {
            borderBottom: "2px solid #ECF2F5",
        },
    },
    tab: {
        borderBottom: "none",
    },
}));
export const Orders = () => {
        const classes = useStyles();
        const [value, setValue] = React.useState(0);

        const handleChange = (event, newValue) => {
            setValue(newValue);
        };

        const dispatch = useDispatch();
        const history = useHistory();
        const orders = useSelector((state) => state.Fullfilment.fullfilments);
        const user = useSelector((state) => state.Login.user);
        const selectedData = useSelector((state) => state.Login.selectedData);
        const checkoutOrders = useSelector((state) => state.Checkout.orders);
        const invoices = useSelector((state) => state.Pos.invoices);
        const {t} = useTranslation(["common", "order"]);
        const globalDefaults = useSelector((state) => state.Login.globalDefaults);
        const [filtredOrders, setFiltredOrders] = React.useState([]);
        const [allInvoice, setAllInvoice] = React.useState([]);
        const header = {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user"))?.token,
        };
        const [filtredTickets, setFiltredTickets] = React.useState([]);
        const [filters, setFilters] = React.useState({
            status: [],
        });
        const [orderPage, setOrderPage] = React.useState(1);
        const [invoicepage, setInvoicepage] = React.useState(1);

        const [searchOrder, setSearchOrder] = React.useState("");
        const [open, setOpen] = React.useState({});
        const [loading, setLoading] = React.useState(true);
        const [selectionModel, setSelectionModel] = useState([]);

        const HandleSearchOrder = (event) => {
            setSearchOrder(event.target.value);
            if (value == 0) {
                setFiltredTickets(
                    GlobalfilterData(
                        invoices?.slice(0)?.reverse(), event.target.value, [
                        "name",
                        "customer_name",
                        "custom_ref",
                        "grand_total",
                    ])
                );
                setInvoicepage(1);
            } else {
                setFiltredOrders(
                    GlobalfilterData(orders, event.target.value, [
                        "order_id",
                        "customer_name",
                    ])
                );
                setOrderPage(1);
            }
        };
        const HandleFilter = (key, item) => {
            setInvoicepage(1);
            const FilterDataBy = {...filters, [key]: item};
            setFilters(FilterDataBy);
            setFiltredOrders(
                invoices?.slice(0)?.reverse().filter(FilterBy(FilterDataBy))
            );
        };
        const FilterDeliveryStatus = (orders) => {
            var filtered = [];
            orders = JSON.parse(orders)
                .fullfilments?.slice(0)
                ?.reverse()
                .filter((f) => f.status == "packed");
            orders.forEach((o) => {
                var data = checkoutOrders
                    ? checkoutOrders.find(
                        (co) =>
                            o.order_id == co.name /*&& co.delivery_status == "Not Delivered"*/
                    )
                    : "";
                if (data) filtered.push(o);
            });

            return filtered;
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
        useEffect(async () => {
            Promise.all([localforage.getItem("reduxPersist:Pos")]).then(async function (
                results
            ) {
                setFiltredTickets(
                    results[0] ? JSON.parse(results[0]).invoices?.slice(0)?.reverse() : []
                );
                setOpen(JSON.parse(results[0]).open);
                setLoading(true);
                user &&
                (await dispatch(
                    LoadPOSInvoices(JSON.parse(results[0]).open, user.email)
                ));
                setLoading(false);
            });
        }, []);

        useEffect(() => {
            Promise.all([localforage.getItem("reduxPersist:Fullfilment")]).then(
                function (results) {
                    setFiltredOrders(results[0] ? FilterDeliveryStatus(results[0]) : []);
                    selectedData?.store &&
                    dispatch(
                        LoadFullfilments(
                            selectedData.store,
                            globalDefaults?.default_company
                        )
                    );
                }
            );
        }, []);

        useEffect(() => {
            setFiltredTickets(invoices?.slice(0)?.reverse());
        }, [invoices]);


        useEffect(() => {
            setFiltredOrders(
                orders
                    ? FilterDeliveryStatus(JSON.stringify({fullfilments: orders}))
                    : []
            );
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [orders]);

        const columns = [
            {
                field: 'created_at',
                headerName: 'Date',
                flex: 1
            },
            {
                field: 'customer_name',
                headerName: t("common:client"),
                flex: 1
            },
            {
                field: 'customer_name',
                headerName: t("common:client"),
                flex: 1
            },
            {
                field: 'status',
                headerName: t('common:statut'),
                // type: 'number',
                flex: 1
            },
            {
                field: 'total_amount',
                headerName: t('common:totalPrice'),
                flex: 1
            },
        ];

        const columnVisibilityModel = {
            updated_a: false,
            total_amount: false,
            sales_date: false,
            recipient: false,
            products: false,
            point_of_sales: false,
            packs: false,
            notes: false,
            id: false,
            grand_total: false,
            discount_amount: false,
            delivery_date: false,
            delivery_address: false,
            customer_email: false,
            currency: false,
            company: false,
            age: false,
            additional_discount_percentage: false,
            status: false,
            customer_name: false,
            created_at: false,
        };

        const handleRedirection = (order_id) => {
            return history.push(`/orders/${order_id}`);
        }
        const getInvoice = () => {
            setLoading(true)
            fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
                method: "POST",
                headers: header,
                body: JSON.stringify({
                    doctype: "POS Invoice",
                    fields: [
                        "`tabPOS Invoice`.`posting_date`",
                        "`tabPOS Invoice`.`name`",
                        "`tabPOS Invoice`.`custom_ref`",
                        "`tabPOS Invoice`.`customer_name`",
                        "`tabPOS Invoice`.`status`",
                        "`tabPOS Invoice`.`grand_total`",
                        "`tabPOS Invoice`.`currency`",
                        "`tabPOS Invoice`.`pos_profile`"
                    ],
                    filters: [
                        ["POS Invoice", "pos_profile", "=", selectedData?.cashier],
                        ["POS Invoice", "company", "=", globalDefaults?.default_company],
                    ],
                }),
            })
                .then((res) => {
                    return res.json();
                })
                .then((data) => {
                    if (Array.isArray(data)) {
                        setAllInvoice(data);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching invoice data:", error);
                })
                .finally(() => {
                    setLoading(false);
                });
        };
     
        useEffect(() => {
            getInvoice();
        }, []);

        return (
            <Box>
                <Box className={classes.header}>
                    <FormControl className={classes.formControl} variant="outlined">
                        <OutlinedInput
                            id="outlined-adornment-weight"
                            value={searchOrder}
                            placeholder={t("order:orderSearch")}
                            onChange={HandleSearchOrder}
                            endAdornment={
                                <InputAdornment position="end">
                                    <span className="icon-search"/>
                                </InputAdornment>
                            }
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                                "aria-label": "weight",
                            }}
                            labelWidth={0}
                        />
                    </FormControl>
                </Box>
                <Box mt={-2}>
                    <Tabs
                        value={value}
                        className={classes.tabs}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        <Tab
                            className={classes.tab}
                            label={t("order:tickets")}
                            {...a11yProps(0)}
                        />
                        <Tab
                            className={classes.tab}
                            label={t("common:commande")}
                            {...a11yProps(1)}
                        />
                        <Tab
                            className={classes.tab}
                            label={t("Ticket Total")}
                            {...a11yProps(2)}
                        />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        {loading ? (
                            <Loader/>
                        ) : (
                            <React.Fragment>
                                <Filter
                                    data={GetFilterItems(
                                        [...new Set(filtredTickets.map((item) => item.status))],
                                        {Paid: "Paid", 0: "-*-***-**-*-**-*-"}
                                    )}
                                    title={"Statut"}
                                    id={"status"}
                                    HandleFilter={HandleFilter}
                                    value={filters.status}
                                    number={filters.status.length}
                                />
                                <InvoicesTable
                                    orders={filtredTickets.slice(
                                        (invoicepage - 1) * 20,
                                        invoicepage * 20
                                    )}
                                />
                                {Math.ceil(filtredTickets.length / 20) > 0 && (
                                    <Box display="flex" justifyContent="center" m={4}>
                                        <Pagination
                                            onChange={(event, newPage) => setInvoicepage(newPage)}
                                            page={invoicepage}
                                            count={Math.ceil(filtredTickets.length / 20)}
                                            color="primary"
                                        />
                                    </Box>
                                )}
                                {filtredTickets && filtredTickets.length === 0 && (
                                    <Empty text={t("order:emptyMsgTicket")}/>
                                )}
                            </React.Fragment>
                        )}
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <React.Fragment>
                            <Box>
                                <DataRender
                                    data={filtredOrders}
                                    loading={false}
                                    selectionModel={selectionModel}
                                    setSelectionModel={setSelectionModel}
                                    handleEdit={handleRedirection}
                                    tag={'order_id'}
                                    noActions={false}
                                    isViewAction={true}
                                    columnsDef={columns}
                                    columnVisibilityModel={columnVisibilityModel}
                                />
                            </Box>
                        </React.Fragment>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        {loading ? (
                            <Loader/>
                        ) : (
                            <React.Fragment>
                                <Filter
                                    data={GetFilterItems(
                                        [...new Set(allInvoice.map((item) => item.status))],
                                        {Paid: "Paid", Return:"Retour", Consolidated:"Consolidé"}
                                    )}
                                    title={"Statut"}
                                    id={"status"}
                                    HandleFilter={HandleFilter}
                                    value={filters.status}
                                    number={filters.status.length}
                                />
                                <InvoicesTableOrder
                                    orders={allInvoice.slice(
                                        (invoicepage - 1) * 10,
                                        invoicepage * 10
                                    )}
                                />
                                {Math.ceil(allInvoice.length / 10) > 0 && (
                                    <Box display="flex" justifyContent="center" m={4}>
                                        <Pagination
                                            onChange={(event, newPage) => setInvoicepage(newPage)}
                                            page={invoicepage}
                                            count={Math.ceil(allInvoice.length / 10)}
                                            color="primary"
                                        />
                                    </Box>
                                )}
                                {allInvoice && allInvoice.length === 0 && (
                                    <Empty text={t("order:emptyMsgTicket")}/>
                                )}
                            </React.Fragment>
                        )}
                    </TabPanel>
                </Box>
            </Box>
        );
    }
;
