import "./QuotationComponent.css";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../../../components/loader";
import { useTranslation } from "react-i18next";
import i18n from "./../../../../i18n";

import Divider from "@mui/material/Divider";

const RecapQuotation = ({ setCloseModalPrint, id }) => {
  console.log("id      :xx", id);
  const { t } = useTranslation();

  const unitsFr = ["", "un", "deux", "trois", "quatre", "cinq", "six", "sept", "huit", "neuf"];
  const teensFr = ["", "onze", "douze", "treize", "quatorze", "quinze", "seize", "dix-sept", "dix-huit", "dix-neuf"];
  const tensFr = ["", "dix", "vingt", "trente", "quarante", "cinquante", "soixante", "soixante-dix", "quatre-vingt", "quatre-vingt-dix"];
  const hundredsFr = ["", "cent", "deux cents", "trois cents", "quatre cents", "cinq cents", "six cents", "sept cents", "huit cents", "neuf cents"];
  
  const unitsEn = ["", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine"];
  const teensEn = ["", "eleven", "twelve", "thirteen", "fourteen", "fifteen", "sixteen", "seventeen", "eighteen", "nineteen"];
  const tensEn = ["", "ten", "twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"];
  const hundredsEn = ["", "one hundred", "two hundred", "three hundred", "four hundred", "five hundred", "six hundred", "seven hundred", "eight hundred", "nine hundred"];
  
  const unitsAr = ["", "واحد", "اثنان", "ثلاثة", "أربعة", "خمسة", "ستة", "سبعة", "ثمانية", "تسعة"];
  const teensAr = ["", "أحد عشر", "اثنا عشر", "ثلاثة عشر", "أربعة عشر", "خمسة عشر", "ستة عشر", "سبعة عشر", "ثمانية عشر", "تسعة عشر"];
  const tensAr = ["", "عشرة", "عشرون", "ثلاثون", "أربعون", "خمسون", "ستون", "سبعون", "ثمانون", "تسعون"];
  const hundredsAr = ["", "مائة", "مائتان", "ثلاثمائة", "أربعمائة", "خمسمائة", "ستمائة", "سبعمائة", "ثمانمائة", "تسعمائة"];
  
  function numberToFrenchWords(number) {
      if (number === 0) return "zéro";
      let words = "";
  
      if (number >= 1000) {
          if (Math.floor(number / 1000) === 1) {
              words += "mille ";
          } else {
              words += numberToFrenchWords(Math.floor(number / 1000)) + " mille ";
          }
          number %= 1000;
      }
  
      if (number > 99) {
          words += hundredsFr[Math.floor(number / 100)] + " ";
          number %= 100;
      }
      if (number > 10 && number < 20) {
          words += teensFr[number - 10] + " ";
      } else {
          words += tensFr[Math.floor(number / 10)] + " ";
          number %= 10;
          words += unitsFr[number] + " ";
      }
      return words.trim();
  }
  
  function numberToEnglishWords(number) {
      if (number === 0) return "zero";
      let words = "";
  
      if (number >= 1000) {
          words += numberToEnglishWords(Math.floor(number / 1000)) + " thousand ";
          number %= 1000;
      }
  
      if (number > 99) {
          words += hundredsEn[Math.floor(number / 100)] + " ";
          number %= 100;
      }
      if (number > 10 && number < 20) {
          words += teensEn[number - 10] + " ";
      } else {
          words += tensEn[Math.floor(number / 10)] + " ";
          number %= 10;
          words += unitsEn[number] + " ";
      }
      return words.trim();
  }
  
  function numberToArabicWords(number) {
      if (number === 0) return "صفر";
      let words = "";
  
      if (number >= 1000) {
          const thousands = Math.floor(number / 1000);
          if (thousands === 1) {
              words += "ألف ";
          } else if (thousands === 2) {
              words += "ألفان ";
          } else if (thousands >= 3 && thousands <= 10) {
              words += unitsAr[thousands] + " آلاف ";
          } else {
              words += numberToArabicWords(thousands) + " ألف ";
          }
          number %= 1000;
      }
  
      if (number > 99) {
          words += hundredsAr[Math.floor(number / 100)] + " ";
          number %= 100;
      }
      if (number > 10 && number < 20) {
          words += teensAr[number - 10] + " ";
      } else {
          if (Math.floor(number / 10) > 0) {
              words += tensAr[Math.floor(number / 10)] + " ";
          }
          if (number % 10 > 0) {
              words += unitsAr[number % 10] + " ";
          }
      }
      return words.trim();
  }
  
  function convertNumberToWords(amount) {
      if (amount < -999999.999 || amount > 999999.999) {
          throw new Error('Le montant doit être compris entre -999999.999 et 999999.999');
      }
  
      amount = Math.abs(amount); // Rendre le montant positif
  
      const integerPart = Math.floor(amount);
      const decimalPart = Math.round((amount - integerPart) * 1000);
  
      let amountInWords = "";
      let lang = i18n.language;
  
      switch (lang) {
          case 'fr':
              amountInWords = `${numberToFrenchWords(integerPart)} dinars et ${numberToFrenchWords(decimalPart)} millimes`;
              break;
          case 'en':
              amountInWords = `${numberToEnglishWords(integerPart)} dinars and ${numberToEnglishWords(decimalPart)} millimes`;
              break;
          case 'ar':
              amountInWords = `${numberToArabicWords(integerPart)} دينار و ${numberToArabicWords(decimalPart)} مليم`;
              break;
          default:
              throw new Error('Langue non supportée. Utilisez "fr", "en" ou "ar".');
      }
  
      return amountInWords;
  }

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const [supplierList, setSupplierList] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [CurrencyList, setCurrencyList] = useState([]);
  const [taxList, setTaxList] = useState([]);
  const [supplierSelected, setSupplierSelected] = useState("");
  const [companySelected, setCompanySelected] = useState("");
  const [quotationNo, setQuotationNo] = useState("");
  const [addressList, setAddressList] = useState([]);
  const [addressSelected, setAddressSelected] = useState("");
  const [contactList, setContactList] = useState([]);
  const [contactSelected, setContactSelected] = useState("");
  const [CurrencySelected, setCurrencySelected] = useState("");
  const [taxCategoryList, setTaxCategoryList] = useState([]);
  const [priceList, setPriceList] = useState([]);
  const [priceSelected, setPriceSelected] = useState("");
  const [taxCategorySelected, setTaxCategorySelected] = useState("");
  const [purchaseTaxTemplateList, setPurchaseTaxTemplateList] = useState([]);
  const [purchaseTaxTemplateSelected, setPurchaseTaxTemplateSelected] =
    useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [transactionDate, setTransactionDate] = useState("");
  // const [dataQuotationList, setDataQuotationList] = useState([]);

  const [error, setError] = useState({
    supplier: false,
    scheduleDate: false,
    scheduleDateTill: false,
    items: false,
    taxes: false,
  });

  const [state, setState] = useState({
    items: [],
    taxes: [],
  });
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading to false whether data fetching succeeds or fails

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URI}/api/details`,
          {
            params: {
              doctype: "Supplier Quotation",
              name: id.name,
            },
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
              Authorization: JSON.parse(localStorage.getItem("user")).token,
            },
          }
        );

        // Operations to be executed after data fetching is completed successfully
        response.data["docs"].forEach((item) => {
          setContactNumber(item.contact_mobile);
          setQuotationNo(item.name);
          setSupplierSelected(item.supplier);
          setCompanySelected(item.company);
          setCurrencySelected(item.currency);
          setPriceSelected(item.price_list);
          setPurchaseTaxTemplateSelected(item.purchase_tax_template);
          setTaxCategorySelected(item.tax_category);
          setPurchaseTaxTemplateSelected(item.taxes_and_charges);
          setPriceSelected(item.buying_price_list);
          setAddressSelected(item.supplier_address);
          setContactSelected(item.contact_person);
          setTransactionDate(item.transaction_date);
          item.taxes.map((item) => {
            state.taxes.push(item);
          });
          //setTaxCategorySelected(item.tax_category);
          item.items.map((item) => {
            state.items.push(item);
            console.log("item      :xxx", item);
          });
        });
        setLoading(false); // Set loading to false whether data fetching succeeds or fails
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error as needed
      } finally {
        setLoading(false); // Set loading to false whether data fetching succeeds or fails
      }
    };

    fetchData();
  }, []);
  if (loading) {
    return <Loader />;
  }
  return (
    <div
      className="supplier-quotation"
      style={{
        width: "200mm",
        height: "297mm",
        margin: "auto",
      }}
    >
      <div style={{ margin: "15px" }}>
        <div
          // className="header"
          style={{
            fontSize: "24px",
            fontWeight: 500,
            textAlign: "right",
            color: "rgb(102, 102, 102)",
            lineHeight: "31.2px",
            fontFamily: "Helvetica Neue",
            textRendering: "optimizeLegibility",
            textSizeAdjust: "100%",
            textTransform: "uppercase",
            marginTop: "85px",
            marginRight: "20px",
          }}
        >
          {t("common:SupplierQuotation")}
        </div>
      </div>
      <Divider orientation="horizontal" fullWidth />

      <div style={{ marginLeft: "80%", marginTop: "30px" }}>
        <span style={{ fontSize: "14px", fontWeight: "bold" }}>
          {t("common:date")} :
        </span>
        &nbsp;
        <span>{transactionDate}</span>
      </div>
      <div style={{ width: "100%" }}>
        <div>
          <span style={{ fontSize: "15px", fontWeight: "bold" }}>
            {t("common:name")} :
          </span>
          <span style={{ marginLeft: 130 }}> {supplierSelected}</span>
        </div>
        <br />
        <br />
        <div>
          <span style={{ fontSize: "14px", fontWeight: "bold" }}>
            {t("common:addr")} :
          </span>{" "}
          <span style={{ marginLeft: 130 }}> {addressSelected}</span>
        </div>
        <br />
        <br />
        <div>
          <span style={{ fontSize: "14px", fontWeight: "bold" }}>
            {t("common:contact")} :
          </span>{" "}
          <span style={{ marginLeft: 130 }}> {contactSelected}</span>
        </div>
        <br />
        <br />

        <div>
          <span style={{ fontSize: "14px", fontWeight: "bold" }}>
            {t("common:phone")} :
          </span>
          <span style={{ marginLeft: 130 }}> {contactNumber}</span>
        </div>
        <br />
        <br />
      </div>

      <div
        className="items"
        style={{
          marginTop: "40px",
          marginLeft: "20px",
          marginRight: "40px",
          width: "95%",
        }}
      >
        <table style={{ borderCollapse: "collapse" }}>
          <thead style={{ backgroundColor: "#eeeeee", color: "black" }}>
            <tr>
              <th style={{ border: "1px solid black" }}>{t("common:sr")}</th>
              <th style={{ border: "1px solid black" }}>
                {t("product:itemcode")}
              </th>
              <th style={{ border: "1px solid black" }}>
                {t("common:Description")}
              </th>
              <th style={{ border: "1px solid black" }}>{t("common:qty")}</th>
              <th style={{ border: "1px solid black" }}>
                {t("common:remise")}
              </th>
              <th style={{ border: "1px solid black" }}>{t("common:taux")}</th>
              <th style={{ border: "1px solid black" }}>
                {t("common:montant")}
              </th>
              <th style={{ border: "1px solid black" }}>
                {t("common:weightUnit")}
              </th>
              <th style={{ border: "1px solid black" }}>
                {t("common:totWeight")}
              </th>
            </tr>
          </thead>
          <tbody>
            {state.items.map((item, index) => (
              <tr key={index}>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  {index + 1}
                </td>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  {item.item_code}
                </td>
                <td style={{ border: "1px solid black" }}>
                  {item.description}
                </td>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  {item.qty}
                </td>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  {item.discount}
                </td>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  {item.rate}
                </td>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  {item.amount}
                </td>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  {item.weightPerUnit}
                </td>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  {item.totalWeight}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td
                colSpan="3"
                style={{ border: "1px solid black", textAlign: "center" }}
              >
                {t("common:qtyTotal")}
              </td>
              <td style={{ border: "1px solid black", textAlign: "center" }}>
                {state.items.reduce((total, item) => total + item.qty, 0)}
              </td>
              <td
                colSpan="4"
                style={{ border: "1px solid black", textAlign: "center" }}
              >
                {t("common:total")}
              </td>
              <td style={{ border: "1px solid black", textAlign: "center" }}>
                {id.grand_total.toFixed(3)}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>

      <div
        className="grand-total"
        style={{
          fontSize: "14px",
          fontWeight: "bold",
        }}
      >
        <div style={{ textDecoration: "underline" }}>
          {t("common:grandTotal")} :
        </div>
        <div>
          {id.grand_total.toFixed(3)}
          &nbsp;{" "}
          {i18n.language === "fr" || i18n.language === "en" ? "TND" : "دينار"}
          {/* {console.log(state.items
            .reduce((total, item) => total + parseFloat(item.amount), 0)
            .toFixed(3))} */}
        </div>
      </div>

      <div className="grand-total">
        <div
          style={{
            fontSize: "14px",
            fontWeight: "bold",
            textDecoration: "underline",
          }}
        >
          {t("common:priceLetter")} :
        </div>
        <div style={{ fontSize: "13px", fontWeight: "bold" }}>
          {id.grand_total !== 0
            ? convertNumberToWords(id.grand_total.toFixed(3))
            : i18n.language === "fr"
            ? "Zéro Dinars"
            : i18n.language === "en"
            ? "Zero Dinars"
            : "صفر دينار"}
        </div>
      </div>
    </div>
  );
};

export default RecapQuotation;
