import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { symbol, removeHTML, formatDate } from "../../../../helpers/utils";
import Barcode from "react-barcode";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  main: {
    padding: "0px 50px",
    fontSize: 12,
  },
  header: {
    padding: "60px 0",
    marginBottom: 60,
  },
  logo: {
    textAlign: "center",
    marginBottom: 10,
  },
  title: {
    borderTop: "1px solid  #5D6975",
    borderBottom: "1px solid  #5D6975",
    color: "#5D6975",
    fontSize: "30px",
    lineHeight: "1.4em",
    fontWeight: "normal",
    textAlign: "center",
    margin: "0 0 20px 0",
  },
  company: {
    float: "right",
    textAlign: "right",
    marginTop: 30,
  },
  project: {
    float: "left",
    marginTop: 30,
    marginBottom: 60,
  },
  projectitem: {
    color: "#5D6975",
    textAlign: "left",
    width: "52px",
    marginRight: 10,
    display: "inline-block",
    fontSize: "0.8em",
  },
  table: {
    width: "100%",
    marginTop: 20,
  },
  tableheader: {
    padding: "10px 10px",
    background: "#E5E5E5",
    border: "1px solid #C1CED9",
    fontWeight: "normal",
  },
  tableColumn: {
    padding: 10,
    textAlign: "right",
    border: "1px solid #C1CED9",
  },
  ref: {
    width: "60%",
    marginTop: 5,
  },
  headtable: {
    border: " 1px solid #C1CED9 ",
    background: "#E5E5E5",
    fontWeight: "normal",
  },
  columntable: {
    padding: 10,
    textAlign: "center",
    border: "1px solid #C1CED9",
  },
  pay: {
    width: "60%",
    display: "inline-table",
    tableLayout: "fixed",
  },
  comp: {
    width: "80%",
  },
  address: {
    paddingTop: "40px",
  },
  item: {
    color: "#5D6975",
    textAlign: "left",
    width: "52px",
    fontSize: "0.8em",
  },
  taxStamp: {
    marginTop: 20,
    textAlign: "left",
    fontSize: "0.8em",
  },
}));

export const PrintReservedCommand = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const { t } = useTranslation([
    "common",
    "checkout",
    "payement",
    "hold",
    "clients",
  ]);

  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const activeImageTheme = useSelector((state) => state.Theme.image);
  //const taxStamp = useSelector((state) => state.Checkout.tax);

  return (
    <div className={classes.main} ref={ref}>
      <header className={classes.header}>
        <div className={classes.logo}>
          <img src={activeImageTheme} />
        </div>
        <h1 className={classes.title}>{props?.title} </h1>
        <div className={classes.company}>
          <div>
            {globalDefaults?.default_company}:{" "}
            {props?.contactCompany && props?.contactCompany[0]?.warehouse_name}{" "}
            <br /> {props?.contactCompany && props?.contactCompany[0]?.city}{" "}
          </div>
          <div>
            {props?.contactCompany && props?.contactCompany[0]?.address_line_1}
          </div>
          <div>
            {props?.contactCompany && props?.contactCompany[0]?.mobile_no} |{" "}
            {props?.contactCompany && props?.contactCompany[0]?.phone_no}
          </div>
          <div>
            <a href="mailto:company@example.com">
              {props?.contactCompany && props?.contactCompany[0]?.email}{" "}
            </a>
          </div>
        </div>

        <div className={classes.project}>
          <div>
            <span className={classes.projectitem}>
              {t("checkout:customer")}
            </span>{" "}
            <a href="mailto:john@example.com">{props?.order?.client}</a>
          </div>
          <div>
            <span className={classes.projectitem}>DATE</span>
            {formatDate(props?.order?.created_at, "YYYY-MM-DD")}
          </div>
        </div>
      </header>

      <table className={classes.table}>
        <thead>
          <tr>
            <th className={classes.tableheader}>{t("common:ref")}</th>
            <th className={classes.tableheader}>{t("common:produit")}</th>
            <th className={classes.tableheader}>{t("common:Description")}</th>
            <th className={classes.tableheader}>P.U</th>
            <th className={classes.tableheader}>{t("common:remise")} (%)</th>
            <th className={classes.tableheader}>{t("common:qty")}</th>
            <th className={classes.tableheader}>{t("common:total")}</th>
          </tr>
        </thead>
        <tbody>
          {props?.order &&
            props?.order?.products?.map((p, index) => (
              <tr key={index}>
                <td
                  className={classes.tableColumn}
                  style={{ textAlign: "left" }}
                >
                  {p.item_code}
                </td>
                <td
                  className={classes.tableColumn}
                  style={{ textAlign: "left" }}
                >
                  {p.item_name}
                </td>
                <td
                  className={classes.tableColumn}
                  style={{ textAlign: "left" }}
                >
                  {removeHTML(p.description)}
                </td>
                <td
                  className={classes.tableColumn}
                  style={{ textAlign: "left" }}
                >
                  {p?.price_list_rate?.toFixed(3)}
                  {globalDefaults?.default_currency}
                </td>

                <td
                  className={classes.tableColumn}
                  style={{ textAlign: "left" }}
                >
                  {p.discount_percentage}% ({p?.discount_amount?.toFixed(3)}){" "}
                  {globalDefaults?.default_currency}
                </td>

                <td
                  className={classes.tableColumn}
                  style={{ textAlign: "left" }}
                >
                  {p.qty}
                </td>
                <td
                  className={classes.tableColumn}
                  style={{ textAlign: "left" }}
                >
                  {p?.net_amount?.toFixed(3)}
                  {globalDefaults?.default_currency}
                </td>
              </tr>
            ))}

          <tr>
            <td className={classes.tableColumn} colSpan="6">
              {t("common:total")} H.T
            </td>
            <td className={classes.tableColumn} style={{ textAlign: "left" }}>
              {(
                props?.order?.amount?.totalAmount -
                props?.order?.amount?.taxAmount
              )?.toFixed(3)}{" "}
              {globalDefaults?.default_currency}
            </td>
          </tr>
          <tr>
            <td className={classes.tableColumn} colSpan="6">
              {t("common:totalTaxe")}
            </td>
            <td className={classes.tableColumn} style={{ textAlign: "left" }}>
              {props?.order?.amount &&
                props?.order?.amount?.taxAmount?.toFixed(3)}{" "}
              {globalDefaults?.default_currency}
            </td>
          </tr>
          {props?.order?.discount !== 0 && (
            <tr>
              <td className={classes.tableColumn} colSpan="6">
                {t("hold:remiseTtl")}
              </td>
              <td className={classes.tableColumn} style={{ textAlign: "left" }}>
                {props?.order && props?.order?.discount?.toFixed(3)}{" "}
                {globalDefaults?.default_currency}
              </td>
            </tr>
          )}
          <tr>
            <td className={classes.tableColumn} colSpan="6">
              {t("common:total")} TTC
            </td>
            <td className={classes.tableColumn} style={{ textAlign: "left" }}>
              {props?.order?.amount &&
                props?.order?.amount?.totalAmount?.toFixed(3)}{" "}
              {globalDefaults?.default_currency}
            </td>
          </tr>

          {/* <tr>
            <td className={classes.tableColumn} colSpan="6">
              {t("common:timbreFiscal")}
            </td>
            <td className={classes.tableColumn} style={{ textAlign: "left" }}>
              {taxStamp?.taxes?.[0]?.tax_amount?.toFixed(3) ?? 0}{" "}
              {globalDefaults?.default_currency}
            </td>
          </tr>*/}
          <tr>
            <td className={classes.tableColumn} colSpan="6">
              {t("payement:totalToPay")}
            </td>
            <td className={classes.tableColumn} style={{ textAlign: "left" }}>
              {props?.order?.amount &&
                props?.order?.amount?.totalAmount?.toFixed(3)}{" "}
              {globalDefaults?.default_currency}
            </td>
          </tr>
          <tr>
            <td className={classes.tableColumn} colSpan="6">
              {t("common:restToPay")}
            </td>
            <td className={classes.tableColumn} style={{ textAlign: "left" }}>
              {props?.order?.payment_status?.toLowerCase() === "paid"
                ? "0.000"
                : props?.order?.amount &&
                  (
                    props?.order?.amount?.totalAmount -
                    (props?.order?.amount?.payments?.reduce(
                      (a, v) => a + parseFloat(v.amount),
                      0
                    ) || 0)
                  )?.toFixed(3)}{" "}
              {globalDefaults?.default_currency}
            </td>
          </tr>
        </tbody>
      </table>

      <div style={{ paddingTop: 30, display: "flex" }}>
        <div className={classes.pay}>
          <table>
            <thead>
              <tr>
                <th
                  className={classes.tableheader}
                  style={{ textAlign: "left" }}
                >
                  {t("checkout:reglement")}
                </th>
                <th
                  className={classes.tableheader}
                  style={{ textAlign: "left" }}
                >
                  {t("clients:paidAmount")}
                </th>
              </tr>
            </thead>
            <tbody>
              {props?.order &&
                props?.order?.amount?.payments?.map((p, index) => (
                  <tr key={index}>
                    <td
                      className={classes.tableColumn}
                      style={{ textAlign: "left" }}
                    >
                      {p.mode_of_payment}
                    </td>
                    <td
                      className={classes.tableColumn}
                      style={{ textAlign: "left" }}
                    >
                      {p.amount.toFixed(3)} {globalDefaults?.default_currency}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});
