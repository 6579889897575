import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, OutlinedInput, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  LoadDocuments,
  LoadTransferRequestById,
  updateTransferRequestStatus,
} from "../../../../store/transferRequest/transferRequest.action";
import { useParams } from "react-router-dom";
import { TransferRequestDetails } from "../transfertRequestDetails";
import { StockEntry } from "../../../order/components/stockEntry";
import { CreateStockEntry } from "../../../../store/stockEntries/stockEntries.action";
import { BasicTable } from "../../../checkout/components/basictable";
import { GlobalfilterData } from "../../../../helpers/helper";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Transfert } from "../../../../components/transfert";
import { LoadWarehouseCibleByName } from "../../../../store/transferRequest/transferRequest.action";
import { Loader } from "../../../../components/loader";
import { useTranslation } from "react-i18next";
import { Header } from "../../../stockEntry/components/header";
import { SimpleTable } from "../../../../components/simpleTable";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 27,
  },
  addButton: {
    width: 28,
    height: 25,
    background: theme.palette.secondary.main,
    borderRadius: 5,
    fontSize: 21,
    paddingTop: 2,
    marginLeft: 8,
  },
  paper: {
    position: "absolute",
    width: 600,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
  title: {
    fontWeight: 700,
    fontSize: 20,
  },
  number: {
    color: theme.palette.blue.main,
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
  },
  formControl: {
    marginBottom: 15,
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
  },
}));

export const TransferRequestDetailPage = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const selectedData = useSelector((state) => state.Login.selectedData);
  const companyacounts = useSelector(
    (state) => state.Configuration.companyacounts
  );
  const transferRequest = useSelector(
    (state) => state.Transfert.selectedTransfertRequest
  );
  const receivedMaterial = useSelector(
    (state) => state.Transfert.receivedMaterial
  );
  const products = useSelector((state) => state.Product.productsPos);
  const warehouseCible = useSelector(
    (state) => state.Transfert.selectedWarehouseCible
  );
  const user = useSelector((state) => state.Login.selectedData);

  const [productCodes, setProductCodes] = React.useState([]);
  const [transferProductsDetails, setTransferProductsDetails] = React.useState(
    []
  );
  const { id } = useParams();
  const header = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
    Authorization: JSON.parse(localStorage.getItem("user"))?.token,
  };

  const { t } = useTranslation(["transfert", "common"]);

  const [suggestion, setsuggestion] = React.useState([]);
  const [searchProduct, setSearchProduct] = React.useState("");
  const [initialLines, setInitialLines] = React.useState([0, 1, 2, 3]);
  const [loading, setLoading] = React.useState(true);
  const [alert, setAlert] = React.useState({
    open: false,
    message: "",
    severity: "",
  });
  const handleSearchProduct = (e) => {
    setSearchProduct(e.target.value);
    if (e.target.value == "") {
      setsuggestion([]);
      return;
    }
    const data = GlobalfilterData(
      products?.filter((p) => p?.is_stock_item == 1),
      e.target.value,
      ["item_code", "item_name", "description", "price_list_rate"]
    );
    setsuggestion(data);
  };

  const handleAddproduct = (id) => {
    var data = suggestion.find((s) => s.item_name == id.item_name);
    data.material_request = transferRequest.name;
    data.s_warehouse = transferRequest.set_from_warehouse;
    data.t_warehouse = transferRequest.set_warehouse;
    data.warehouse = transferRequest.set_warehouse;
    data.doctype = "Stock Entry Detail";
    const ItemIndex = mappedDoc.items.findIndex((s) => s.item_name == id.item_name);
    if (ItemIndex != -1) {
      mappedDoc.items[ItemIndex].qty = mappedDoc.items[ItemIndex].qty + 1;
      mappedDoc.items[ItemIndex].stock_qty =
        mappedDoc.items[ItemIndex].stock_qty + 1;
    } else {
      data.qty = 1;
      data.stock_qty = 1;
      mappedDoc.items.push(data);
      setMappedDoc({
        ...mappedDoc,
        items: mappedDoc.items,
      });
      setInitialLines(mappedDoc?.items?.length);
    }
    setsuggestion([]);
    setSearchProduct("");
  };

  const HandleQuantityChange = (onAdd, id) => {
    const ItemIndex = mappedDoc.items?.findIndex((s) => s.item_code == id);
    const data = [...mappedDoc.items];
    if (ItemIndex != -1) {
      data[ItemIndex].qty = onAdd
        ? parseInt(data[ItemIndex].qty) + 1
        : Math.sign(data[ItemIndex].qty - 1) == 1
        ? data[ItemIndex].qty - 1
        : 0;
      data[ItemIndex].stock_qty = data[ItemIndex].qty;
      setMappedDoc({
        ...mappedDoc,
        items: [...data],
      });
    }
  };

  const HandleDelete = (id) => {
    const data = mappedDoc?.items?.filter((s) => s.item_code !== id);
    setMappedDoc({
      ...mappedDoc,
      items: [...data],
    });
    setInitialLines(mappedDoc?.items?.length);
  };
  const HandleUpdateQuantity = (e, id) => {
    const ItemIndex = mappedDoc.items.findIndex((s) => s.item_code == id);

    const data = [...mappedDoc.items];
    if (ItemIndex != -1 && !isNaN(e.target.value)) {
      data[ItemIndex].qty = parseInt(e.target.value);
      data[ItemIndex].stock_qty = parseInt(e.target.value);
      setMappedDoc({
        ...mappedDoc,
        items: [...data],
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const documentNumber = useSelector((state) => state.Transfert.documentNumber);

  const dispatch = useDispatch();

  const [mappedDoc, setMappedDoc] = useState(null);

  const [stockEntryState, setStockEntryState] = React.useState({
    doctype: "Stock Entry",
    stock_entry_type: "Material Transfer",
    purpose: "Material Transfer",
    company: `${globalDefaults?.default_company}`,
    schedule_date: "",
    posting_date: new Date().toISOString().slice(0, 10),
    to_warehouse: "",
    from_warehouse: "",
    items: [],
    additional_costs: [
      {
        docstatus: 0,
        doctype: "Landed Cost Taxes and Charges",
        account_currency: `${companyacounts?.default_currency}`,
        parentfield: "additional_costs",
        parenttype: "Stock Entry",
        idx: 1,
        __unedited: false,
        expense_account: `${companyacounts?.cost_center}`,
        exchange_rate: null,
        amount: 0,
        base_amount: 0,
        description: "Truck",
      },
    ],
  });

  const handleSubmit = (event) => {
    var isvalid = true;
    mappedDoc?.items?.forEach((element) => {
      if (element?.qty == 0) {
        isvalid = false;
      }
    });
    if (isvalid) {
      dispatch(CreateStockEntry(mappedDoc));
    } else {
      setAlert({
        open: true,
        message: t("checkout:quantitymin"),
        severity: "warning",
      });
    }
  };

  const updateAddCosts = (e) => {
    setMappedDoc({
      ...mappedDoc,
      additional_costs: [
        {
          docstatus: 0,
          doctype: "Landed Cost Taxes and Charges",
          account_currency: `${companyacounts?.default_currency}`,
          parentfield: "additional_costs",
          parenttype: "Stock Entry",
          idx: 1,
          __unedited: false,
          expense_account: `${companyacounts?.expenses_included_in_asset_valuation}`,
          exchange_rate: null,
          amount: parseFloat(e.target.value),
          base_amount: parseFloat(e.target.value),
          description: "Truck",
        },
      ],
    });
  };

  const transReqDetailsColumns = [
    { key: "item_code", title: "common:refArticle" },
    { key: "item_name", title: "common:nom" },
    { key: "qty", title: "transfert:qtyDemandé" },
    { key: "ordered_qty", title: "transfert:qtyRecu" },
    { key: "warehouse", title: "common:mgsCible" },
  ];

  const makeMappedDoc = () => {
    fetch(`${process.env.REACT_APP_API_URI}/api/make-mapped-doc`, {
      method: "POST",
      headers: header,
      body: JSON.stringify({
        method:
          "erpnext.stock.doctype.material_request.material_request.make_stock_entry",
        source_name: id,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setMappedDoc({
          ...data?.message,
          from_warehouse: transferRequest?.set_from_warehouse,
        });
        setOpen(true);
      });
  };

  useEffect(() => {
    const receivedMaterialCp =
      receivedMaterial && JSON.parse(JSON.stringify(receivedMaterial));
    const transferRequestCp =
      receivedMaterial && Object.assign({}, receivedMaterial);
    var productCodes = [];
    if (receivedMaterialCp) {
      receivedMaterialCp.map((item, index) => {
        item.material_request = transferRequestCp.name;
        item.material_request_item = item.name;
        item.s_warehouse = transferRequestCp.set_from_warehouse;
        item.t_warehouse = transferRequestCp.set_warehouse;
        item.warehouse = transferRequestCp.set_warehouse;
        item.doctype = "Stock Entry Detail";
        productCodes.push(item.item_code);
      });
    }
    setProductCodes(productCodes);
    setInitialLines(mappedDoc?.items?.length);
  }, [transferRequest]);

  useEffect(() => {
    var transferProductsDetails = productCodes?.map((i) => {
      return products?.find((p) => p?.item_code == i);
    });
    setTransferProductsDetails(transferProductsDetails);
  }, [productCodes]);

  const initialisation = async () => {
    await dispatch(LoadTransferRequestById(id));
    if (transferRequest) {
      await dispatch(LoadWarehouseCibleByName(transferRequest.set_warehouse));
    }
    await dispatch(LoadDocuments("Material Request", id));
    setLoading(false);
  };

  const handleChangeStatus = async (name, status) => {
    let payload = {
      doctype: "Material Request",
      action: status,
      docs: [name],
    };
    await dispatch(updateTransferRequestStatus(payload));
  };

  useEffect(() => {
    initialisation();
  }, []);

  return (
    <Box>
      <Header />

      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        flexWrap="wrap"
      >
        <Box display="flex" flexWrap="wrap" alignItems="center">
          {transferRequest && (
            <Transfert
              count={
                documentNumber?.[0]?.count - documentNumber?.[0]?.open_count ??
                0
              }
            />
          )}
          {transferRequest && (
            <Box ml={1}>
              {(transferRequest?.status === "Partially Ordered" ||
                transferRequest?.status === "Pending") && (
                <Button
                  variant="contained"
                  color={"primary"}
                  disabled={transferRequest?.set_warehouse !== user?.store}
                  onClick={() => makeMappedDoc()}
                >
                  {t("transfert:recepStock")}
                </Button>
              )}
            </Box>
          )}
        </Box>
      </Box>
      {loading ? (
        <Loader />
      ) : (
        <Grid
          container
          direction="row"
          spacing={2}
          className={classes.root}
          display="flex"
          style={{ marginTop: 5 }}
        >
          <Grid item xs={12} md={4}>
            <TransferRequestDetails
              details={transferRequest}
              schedule_date={transferRequest?.schedule_date}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <SimpleTable
              title={"transfertReqDetails"}
              data={JSON.parse(JSON.stringify(receivedMaterial))}
              columns={transReqDetailsColumns}
              hasPaper={true}
            />
            <Box display="flex" justifyContent="flex-end" mt={2}>
              <Button
                color="primary"
                variant="contained"
                disabled={
                  transferRequest?.status == "Transferred" ||
                  transferRequest?.status == "Pending" ||
                  transferRequest?.status == "Cancelled" ||
                  selectedData?.store !== transferRequest?.set_from_warehouse
                }
                onClick={() =>
                  handleChangeStatus(transferRequest?.name, "submit")
                }
              >
                {t("common:Soumise")}
              </Button>
            </Box>
          </Grid>
        </Grid>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle align={"center"}>
          <Typography
            className={classes.title}
            align={"center"}
            color={"primary"}
          >
            {t("transfert:recepStock")}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="scroll-dialog-description">
            <Box align={"left"} mt={4}>
              <StockEntry
                products={transferProductsDetails}
                details={receivedMaterial}
              />
            </Box>
          </DialogContentText>
          {receivedMaterial && (
            <BasicTable
              suggestion={suggestion}
              initialLines={initialLines}
              rows={mappedDoc?.items}
              HandleQuantityChange={HandleQuantityChange}
              HandleDelete={HandleDelete}
              handleSearchProduct={handleSearchProduct}
              handleAddproduct={handleAddproduct}
              searchProduct={searchProduct}
              transfer={true}
              stockEntry={true}
              HandleUpdateQuantity={HandleUpdateQuantity}
            />
          )}
          <Box
            mt={3}
            justifyContent="center"
            display="flex"
            flexDirection="column"
          >
            <Typography
              className={classes.title}
              align={"center"}
              color={"primary"}
            >
              {t("transfert:Coûtsadditionnels")}
            </Typography>
            <OutlinedInput
              className={classes.formControl}
              placeholder={t("transfert:Coûtsadditionnels")}
              value={mappedDoc?.additional_costs?.[0]?.amount ?? 0}
              onChange={(e) => updateAddCosts(e)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("common:annuler")}
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            {t("common:confirmer")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
