import React, { useRef } from "react";
import {
  Button,
  Modal,
  Typography,
  Paper,
  ClickAwayListener,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
} from "@material-ui/core";
import { useStyles } from "../../style";
import { useTableStyles } from "../../../../theme/table.style";
import { useDispatch, useSelector } from "react-redux";
import {
  CreatePurchaseReceiptRequest,
  createPurchaseReceiptDoc,
  SetPurchaseReceiptMappedDoc,
  SetStockistSignature,
  LoadPurchaseOrderById,
  UpdateSignaturePurchaseOrder,
} from "../../../../store/buying/buying.action";
import { Loader } from "../../../../components/loader";
import SignaturePad from "react-signature-pad-wrapper";
import { useReactToPrint } from "react-to-print";
import { PurchaseOrderPrint } from "../purchaseOrderDoc";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { statusFormat } from "../../../../helpers/utils";
import VisibilityIcon from "@material-ui/icons/Visibility";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import PrintIcon from "@material-ui/icons/Print";
import { useState } from "react";
import { SetNotification } from "../../../../store/notification/notification.action";
import { useHistory } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  DialogActions,
  DialogContentText,
  MenuItem,
  Menu,
} from "@material-ui/core";
import DuplicatePurchaseOrder from "../supplierQuotation/duplicatepurchaseorder";
import CancelIcon from '@material-ui/icons/Cancel';
import ContentCopyIcon from '@material-ui/icons/FileCopy';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import LocalPrintshopIcon from '@material-ui/icons/LocalPrintshop';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ValiderPurchaseOrder from "../supplierQuotation/validerpurchaseorder";

export const PurReqTable = (props) => {
  const { module, countPackage } = props;
  const classes = useStyles();
  const tableclasses = useTableStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const signPad = useRef({});
  const componentRef = useRef();
  const { t } = useTranslation(["common", "buying"]);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState(null);
  const [linkedDocuments, setLinkedDocuments] = useState([]);
  const [warningMessage, setWarningMessage] = useState('');
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [ openDuplicateModal,setOpenDuplicateModal] = useState(false);
  const [ openDuplicateQuotModal, setOpenDuplicateQuotModal ] = useState(false)
  const [ openValidateQuotModal, setOpenValidateQuotModal ] = useState(false)
  const [openDetailsModal, setOpenDetailsModal] = React.useState(false);
  const [openPreviewModal, setOpenPreviewModal] = React.useState(false);

  const handleNavigation = (id) => {
    history.push(`/purchase-order/${id}`);
  };

  const purchaseReceiptDoc = useSelector(
    (state) => state.Buying.purchaseReceiptDoc
  );
  const stockistSignature = useSelector(
    (state) => state.Buying.stockistSignature
  );
  const purOrderDetails = useSelector(
    (state) => state.Buying.purchaseOrderDetails
  );

  const handleCloseModal = () => {
    setOpenDetailsModal(false);
    dispatch(SetPurchaseReceiptMappedDoc(null));
  };

  const createPurchaseReceipt = () => {
    let updateMappedDoc = {
      ...purchaseReceiptDoc,
      is_subcontracted: 0,
    };
    dispatch(CreatePurchaseReceiptRequest(updateMappedDoc));
    handleCloseModal();
  };

  const [anchorEl, setAnchorEl] = useState(
    Array(props.purchaseOrderList.length).fill(null)
  );

  const handleClick = (event, index) => {
    const newAnchorEl = [...anchorEl];
    newAnchorEl[index] = event.currentTarget;
    setAnchorEl(newAnchorEl);
  };

  const handleClose = (index) => {
    const newAnchorEl = [...anchorEl];
    newAnchorEl[index] = null;
    setAnchorEl(newAnchorEl);
  };

  const handlePurReceiptDetails = (id) => {
    id && dispatch(createPurchaseReceiptDoc(id));
    setOpenDetailsModal(true);
  };
  const handleAddPackagePurchase = (id) => {
    history.push(`/purchase-package/${id}`);
  };
  const clear = () => {
    dispatch(SetStockistSignature(signPad.current.clear()));
  };
  const trim = () => {
    dispatch(SetStockistSignature(signPad.current.toDataURL("image/png")));
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => setOpenPreviewModal(false),
  });

  const handlePurOrderDetails = (id) => {
    dispatch(LoadPurchaseOrderById(id));
    setOpenPreviewModal(true);
  };

  const AddSignaturePurchaseOrder = () => {
    handlePrint();
    const data = {
      name: purOrderDetails?.name,
      signature: stockistSignature,
    };
    if (
      stockistSignature !=
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAACWCAYAAABkW7XSAAAAAXNSR0IArs4c6QAABGJJREFUeF7t1AEJAAAMAsHZv/RyPNwSyDncOQIECEQEFskpJgECBM5geQICBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAgQdWMQCX4yW9owAAAABJRU5ErkJggg=="
    ) {
      dispatch(UpdateSignaturePurchaseOrder(data));
    }
  };

  const handleDuplcatepurchaseOrder = (row) => {
    setSelectedRow(row);
    setOpenDuplicateQuotModal(true);
  }
  const handleValidatepurchaseOrder = (row) => {
    setSelectedRow(row);
    setOpenValidateQuotModal(true);
  }

  const handleCloseModalDuplicateQuotation = () => {
    setOpenDuplicateQuotModal(false);
  }
  const handleCloseModalValidateQuotation = () => {
    setOpenValidateQuotModal(false);
  }

  const header = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
    Authorization: JSON.parse(localStorage.getItem("user")).token,
  };

  const getPurchaseInvoiceList = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
        method: "POST",
        headers: header,
        body: JSON.stringify({
          doctype: "Purchase Invoice",
          fields: ["*"],
        }),
      });
  
      console.log('Purchase Invoice API Response Status:', response.status);
      const responseText = await response.text();
      console.log('Purchase Invoice API Response Text:', responseText);
  
      if (!response.ok) {
        throw new Error(`Failed to fetch Purchase Invoices: ${response.status} ${response.statusText}`);
      }
  
      const data = JSON.parse(responseText);
      console.log('Parsed Purchase Invoices:', data);
      return data;
    } catch (error) {
      console.error('Error fetching Purchase Invoices:', error);
      return [];
    }
  };
  
  const getPurchaseReceiptList = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
        method: "POST",
        headers: header,
        body: JSON.stringify({
          doctype: "Purchase Receipt",
          fields: ["*"],
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch Purchase Receipts');
      }
  
      const data = await response.json();
      console.log('Purchase Receipts:', data);
      return data;
    } catch (error) {
      console.error('Error fetching Purchase Receipts:', error);
      return [];
    }
  };
  const handleCancelPurchaseOrder = async (row) => {
    try {
      console.log('Attempting to cancel Purchase Order:', row);
  
      const purchaseInvoices = await getPurchaseInvoiceList();
      const purchaseReceipts = await getPurchaseReceiptList();
  
      const linkedInvoices = purchaseInvoices.filter(invoice => {
        console.log(`Checking invoice ${invoice.name}, purchase_order: ${invoice.purchase_order}`);
        return invoice.purchase_order === row.name || 
               (invoice.items && invoice.items.some(item => item.purchase_order === row.name));
      });
  
      const linkedReceipts = purchaseReceipts.filter(receipt => {
        console.log(`Checking receipt ${receipt.name}, purchase_order: ${receipt.purchase_order}`);
        return receipt.purchase_order === row.name || 
               (receipt.items && receipt.items.some(item => item.purchase_order === row.name));
      });
  
      console.log('Linked Invoices:', linkedInvoices);
      console.log('Linked Receipts:', linkedReceipts);
  
      setLinkedDocuments([...linkedInvoices, ...linkedReceipts]);
      setSelectedPurchaseOrder(row);
  
      if (linkedInvoices.length > 0 || linkedReceipts.length > 0) {
        const linkedDoc = linkedInvoices[0] || linkedReceipts[0];
        const warningMsg = `Cannot delete or cancel because Purchase Order ${row.name} is linked with ${linkedDoc.doctype} ${linkedDoc.name}`;
        console.log('Warning Message:', warningMsg);
        setWarningMessage(warningMsg);
        setOpenWarningModal(true);
      } else {
        console.log('Opening Confirmation Modal');
        setOpenConfirmModal(true);
      }
    } catch (error) {
      console.error('Error in handleCancelPurchaseOrder:', error);
      dispatch(SetNotification({ code: "error", message: error.message }));
    }
  };
  
  const handleCancelConfirmedPurchaseOrder = async () => {
    try {
        const cancelPurchaseOrderResponse = await fetch(
            `${process.env.REACT_APP_API_URI}/api/cancel-doc`,
            {
                method: "POST",
                headers: header,
                body: JSON.stringify({
                    doctype: "Purchase Order",
                    name: selectedPurchaseOrder?.name,
                }),
            }
        );

        if (!cancelPurchaseOrderResponse.ok) {
            throw new Error("Failed to cancel Purchase Order");
        }

        const responseData = await cancelPurchaseOrderResponse.json();

        setOpenConfirmModal(false);

        if (responseData.success === false) {
            dispatch(SetNotification({ code: "warning", message: responseData.message }));
        } else {
            dispatch(
                SetNotification({
                    code: "success",
                    message: `${t("Bon de commande annulée avec succès")}`,
                })
            );

            handleReload();
        }
    } catch (warning) {
        let customErrorMessage = `Impossible de supprimer ou d'annuler car Bon de commande ${selectedPurchaseOrder?.name} est lié à d'autres documents.`;

        dispatch(SetNotification({ code: "warning", message: customErrorMessage }));
    }
};
  
  const handleReload = () => {
    window.location.reload();
  };

  const handleDeletepurchaseOrder = (row) => {
    setLoadingButton(true);
    const data = {
      doctype: "Purchase Order",
      name: row?.name,
    };

    fetch(`${process.env.REACT_APP_API_URI}/api/delete-doc`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user"))?.token,
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        setLoadingButton(false);

        if (!res.ok) {
          dispatch(
            SetNotification({ code: "error", message: "Something went wrong" })
          );
        }
        return res.json();
      })
      .then((data) => {
        if (data?.success === false) {
          setLoadingButton(false);
          dispatch(
            SetNotification({ code: "warning", message: data?.message })
          );
        } else {
          setLoadingButton(false);
          dispatch(
            SetNotification({
              code: "success",
              message: `${t("Le bon de commande a été supprimé avec succès")}`,
            })
          );

          handleReload();
        }
      });
  };



  const purchaseOrderDetails = (
    <div className={classes.paper}>
      <Box className={classes.paperr}>
        <Typography align={"center"} color={"primary"}>
          {t("buying:PurchaseReceipt")}
        </Typography>
        <Box mt={3}>
          <Box>
            <Paper className={classes.paperr} style={{ marginBottom: 10 }}>
              <Typography align={"left"} color={"primary"}>
                {t("common:AddBy")}: {purchaseReceiptDoc?.owner}
              </Typography>
              <Typography align={"left"} color={"primary"}>
                {t("buying:SupplierName")}: {purchaseReceiptDoc?.supplier_name}
              </Typography>
              <Typography align={"left"} color={"primary"}>
                {purchaseReceiptDoc?.address_display?.replace(
                  /<br\s*\/?>/gi,
                  ";   "
                )}
              </Typography>
              <Typography align={"left"} color={"primary"}>
                {t("common:createDate")}: {purchaseReceiptDoc?.transaction_date}
              </Typography>
            </Paper>
          </Box>
        </Box>
        <Box mt={2}>
          <Typography
            style={{ marginBottom: -5 }}
            align={"left"}
            color={"primary"}
          >
            {t("common:article")}:
          </Typography>
          <Table className={tableclasses.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  className={tableclasses.tablecellHeader}
                  align="left"
                >
                  {t("common:produit")}
                </TableCell>
                <TableCell
                  className={tableclasses.tablecellHeader}
                  align="left"
                >
                  {t("common:itemCode")}
                </TableCell>
                <TableCell
                  className={tableclasses.tablecellHeader}
                  align="left"
                >
                  {t("common:amnt")}
                </TableCell>
                <TableCell
                  className={tableclasses.tablecellHeader}
                  align="left"
                >
                  {t("common:qtyAchat")}
                </TableCell>
                <TableCell
                  className={tableclasses.tablecellHeader}
                  align="left"
                >
                  {t("common:taux")}
                </TableCell>
                <TableCell
                  className={tableclasses.tablecellHeader}
                  align="left"
                >
                  {t("common:Taxation")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {purchaseReceiptDoc?.items.map((row, i) => (
                <TableRow className={tableclasses.tableRow} key={i}>
                  <TableCell className={tableclasses.tableCell} align="left">
                    {row?.item_name}
                  </TableCell>
                  <TableCell className={tableclasses.tableCell} align="left">
                    {row?.item_code}
                  </TableCell>
                  <TableCell className={tableclasses.tableCell} align="left">
                    {row?.amount.toFixed(3)} {purchaseReceiptDoc?.currency}
                  </TableCell>
                  <TableCell className={tableclasses.tableCell} align="left">
                    {row?.qty}
                  </TableCell>
                  <TableCell className={tableclasses.tableCell} align="left">
                    {row?.rate.toFixed(3)} {purchaseReceiptDoc?.currency}
                  </TableCell>
                  <TableCell className={tableclasses.tableCell} align="left">
                    {row?.item_tax_rate != "" &&
                      Object.keys(JSON.parse(row?.item_tax_rate))}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Paper className={classes.paperr} style={{ marginBottom: 10 }}>
            <Typography align={"left"} color={"primary"}>
              {t("common:qtyTotal")}: {purchaseReceiptDoc?.total_qty}
            </Typography>
            <Typography align={"left"} color={"primary"}>
              {t("common:totalHT")}: {purchaseReceiptDoc?.total?.toFixed(3)}{" "}
              {purchaseReceiptDoc?.currency}
            </Typography>
            <Typography align={"left"} color={"primary"}>
              {t("common:totalTaxe")}:{" "}
              {purchaseReceiptDoc?.base_total_taxes_and_charges?.toFixed(3)}{" "}
              {purchaseReceiptDoc?.currency}
            </Typography>
            <Typography align={"left"} color={"primary"}>
              {t("common:grandTotal")}:{" "}
              {purchaseReceiptDoc?.grand_total?.toFixed(3)}{" "}
              {purchaseReceiptDoc?.currency}
            </Typography>
          </Paper>
        </Box>
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <Button
            disabled={purchaseReceiptDoc?.items?.length == 0}
            style={{ marginTop: 20, alignContent: "center" }}
            variant="contained"
            color="primary"
            onClick={createPurchaseReceipt}
          >
            {t("common:valider")}
          </Button>
          <Button
            style={{ marginTop: 20, alignContent: "center", marginLeft: 10 }}
            variant="outlined"
            color="primary"
            onClick={handleCloseModal}
          >
            {t("common:fermer")}
          </Button>
        </Box>
      </Box>
    </div>
  );

  const previewOrderDetails = (
    <ClickAwayListener
      onClickAway={() => {
        setOpenPreviewModal(false);
        clear();
      }}
    >
      <div className={classes.paperSign} width="auto">
        <Typography align={"center"} color={"primary"}>
          {t("common:msgImpression")}
        </Typography>
        <Box
          mt={3}
          justifyContent="center"
          display="flex"
          className={classes.signaturePad}
        >
          <SignaturePad
            ref={signPad}
            redrawOnResize
            options={{ penColor: "#2F4B7C" }}
          />
        </Box>
        <Box mt={3} justifyContent="end" display="flex">
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              clear();
              setOpenPreviewModal(false);
            }}
          >
            {t("common:annuler")}
          </Button>
          <Button
            color="primary"
            variant="contained"
            style={{ marginLeft: 10 }}
            onClickCapture={() => trim()}
            onClick={AddSignaturePurchaseOrder}
          >
            {t("common:imprimer")}
          </Button>
        </Box>
      </div>
    </ClickAwayListener>
  );

  return (
    <Box className={tableclasses.paper}>
      <Table className={tableclasses.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={tableclasses.tablecellHeader}>
              {t("common:ref")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader}>
              {t("common:createDate")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader}>
              {t("buying:SupplierName")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader}>
              {module == "wms" ? t("buying:NoOfpackages") : t("common:total")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader}>
              {module == "wms"
                ? t("buying:NoOfproducts")
                : t("common:Taxation")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader}>
              {t("common:grandTotal")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader}>
              {t("common:statut")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.purchaseOrderList &&
            Array.isArray(props.purchaseOrderList) &&
            props.purchaseOrderList.map((row, index) => (
              <TableRow className={tableclasses.tableRow} key={index}>
                <TableCell className={tableclasses.tableCell} align="left">
                  {row?.name}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="left">
                  {row?.transaction_date}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="left">
                  {row?.supplier}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="left">
                  {module == "wms"
                    ? countPackage?.find(
                        (e) => e?.purchaseorder_id === row?.name
                      )?.total ?? 0
                    : row?.total.toFixed(3) + " " + row?.currency}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="left">
                  {module == "wms"
                    ? row?.total_qty
                    : row?.total_taxes_and_charges.toFixed(3) +
                      " " +
                      row?.currency}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="left">
                  {row?.grand_total.toFixed(3)} {row?.currency}
                </TableCell>
                <TableCell
                  className={clsx(
                    tableclasses.tableCell,
                    tableclasses[statusFormat(row?.status).color]
                  )}
                  align="left"
                >
                  {statusFormat(row?.status).value}
                </TableCell>
                <TableCell
                  className={tableclasses.tableCell}
                  align="right"
                  justifyContent="space-between"
                  display="flex"
                >
                  {module && module === "wms" ? (
                    <>
                      <IconButton
                        disabled={row?.status != "To Receive and Bill"}
                        color="primary"
                        aria-label="add"
                        component="span"
                        className={classes.button}
                        onClick={() =>
                          row?.name && handleAddPackagePurchase(row?.name)
                        }
                      >
                        <AddCircleIcon />
                      </IconButton>
                      <IconButton
                        color="primary"
                        aria-label="details"
                        component="span"
                        className={classes.button}
                        onClick={() => handleNavigation(row?.name)}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </>
                  ) : (
                    <>  
                    <Button
                        disabled={row?.status != "Draft"}
                        size={"small"}
                        variant="outlined"
                        color="primary"
                        style={{ marginLeft: 10 }}
                        onClick={() => handleValidatepurchaseOrder(row?.name)}
                      >
                        {t("common:valider")}
                      </Button>        
                      <Button
                        size={"small"}
                        variant="outlined"
                        color="primary"
                        style={{ marginLeft: 10 }}
                        onClick={() => handlePurOrderDetails(row?.name)}
                      >
                        {t("common:Aperçu-du-commande")}
                      </Button>
                      <Button
                        disabled={row?.status === "To Bill"}
                        size={"small"}
                        color="primary"
                        variant="contained"
                        style={{ marginLeft: 10 }}
                        onClick={() =>
                          handlePurReceiptDetails(row?.name ? row?.name : null)
                        }
                      >
                        {t("common:Créer-un-Reçu-Achat")}
                      </Button>
                      <Button
  className={classes.menuButton}
  size="small"
  style={{ marginLeft: 10 }}
  variant="outlined"
  color="primary"
  aria-controls={`basic-menu-${index}`}
  aria-haspopup="true"
  onClick={(event) => handleClick(event, index)}
>
  <MoreVertIcon />
</Button>
<Menu
  id={`basic-menu-${index}`}
  anchorEl={anchorEl[index]}
  open={Boolean(anchorEl[index])}
  onClose={() => handleClose(index)}
  MenuListProps={{
    "aria-labelledby": `basic-menu-${index}`,
  }}
>
  <MenuItem
    onClick={() => handleDuplcatepurchaseOrder(row?.name)}
  >
    <ContentCopyIcon style={{ marginRight: 8 }} />
    {t("Duplicate")}
  </MenuItem>
  <MenuItem
    disabled={row.status === "Cancelled"}
    onClick={() => handleCancelPurchaseOrder(row)}
  >
    <CancelIcon style={{ marginRight: 8, color: "red" }} />
    {t("common:annuler")}
  </MenuItem>
  <MenuItem
    disabled={row?.status === "Completed"}
    onClick={() => handleDeletepurchaseOrder(row)}
  >
    <DeleteIcon style={{ marginRight: 8, color: "red" }} />
    {t("common:delete")}
  </MenuItem>
</Menu>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <Modal
        open={openDetailsModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {purchaseReceiptDoc ? (
          purchaseOrderDetails
        ) : (
          <div className={classes.paper}>
            <Loader />
          </div>
        )}
      </Modal>
      <Dialog
  open={openConfirmModal}
  onClose={() => setOpenConfirmModal(false)}
  aria-labelledby="confirm-cancel-dialog-title"
  aria-describedby="confirm-cancel-dialog-description"
>
  <DialogTitle id="confirm-cancel-dialog-title">
    {t("Confirmer l'annulation")}
  </DialogTitle>
  <DialogContent>
    <DialogContentText id="confirm-cancel-dialog-description">
      {t("Procéder à l'annulation")} {selectedPurchaseOrder?.name}?
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setOpenConfirmModal(false)} color="primary">
      {t("common:Cancel")}
    </Button>
    <Button 
      onClick={handleCancelConfirmedPurchaseOrder} 
      color="primary" 
      variant="contained"
    >
      {t("common:Confirm")}
    </Button>
  </DialogActions>
</Dialog>

<Dialog
  open={openWarningModal}
  onClose={() => setOpenWarningModal(false)}
  aria-labelledby="warning-dialog-title"
  aria-describedby="warning-dialog-description"
>
  <DialogTitle id="warning-dialog-title">
    {t("common:CannotCancel")}
  </DialogTitle>
  <DialogContent>
    <DialogContentText id="warning-dialog-description">
      {warningMessage}
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setOpenWarningModal(false)} color="primary" autoFocus>
      {t("common:Close")}
    </Button>
  </DialogActions>
</Dialog>
      <Modal
        open={openPreviewModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {previewOrderDetails}
      </Modal>
      <div className={classes.hidden}>
        <PurchaseOrderPrint
          details={purOrderDetails}
          stockistSignature={stockistSignature}
          ref={componentRef}
          title="Bon de Commande"
        />
      </div>
      <Modal
        open={openDuplicateQuotModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {/* <ClickAwayListener
          onClickAway={() =>
            setOpenRecapQuotModal ? setOpenRecapQuotModal(false) : null
          }
        > */}
          <div
            className={classes.paper}
            style={{
              overflowY: "auto",
              maxHeight: "90vh",
              width: "auto",
              height: "100%",
              aspectRatio: "1.414",
              
            }}
          >
            <DuplicatePurchaseOrder 
              setCloseModal={handleCloseModalDuplicateQuotation}
              id={selectedRow}
            />
          </div>
        {/* </ClickAwayListener> */}
      </Modal>
      <Modal
        open={openValidateQuotModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
          <div
            className={classes.paper}
            style={{
              overflowY: "auto",
              maxHeight: "90vh",
              width: "auto",
              height: "100%",
              aspectRatio: "1.414",
              
            }}
          >
            <ValiderPurchaseOrder 
              setCloseModal={handleCloseModalValidateQuotation}
              id={selectedRow}
            />
          </div>
      </Modal>
    </Box>
  );
};
