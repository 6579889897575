import moment from "moment";
import * as localforage from "localforage";
import axiosInstance from "../../history/axiosInstance";

export const loadClientsData = () => {
  const data = {
    doctype: "Customer",
    fields: [
      "`tabCustomer`.`name`",
      "`tabCustomer`.`customer_name`",
      "`tabCustomer`.`code_client`",
      "`tabCustomer`.`customer_type`",
      "`tabCustomer`.`mobile_no`",
      "`tabCustomer`.`email_id`",
      "`tabCustomer`.`territory`",
      "`tabCustomer`.`customer_group`",
      "`tabCustomer`.`cin`",
      "`tabCustomer`.`newsletter`",
      "`tabCustomer`.`default_currency`",
    ],
    filters: [["Customer", "disabled", "=", 0]],
  };
  const url = `${process.env.REACT_APP_API_URI}/api/customers`;

  return GetData(url, data);
};

export const loadProductsData = (company) => {
  const data = {
    doctype: "Item",
    fields: [
      "`tabItem`.`name`",
      "`tabItem`.`item_code`",
      "`tabItem`.`item_group`",
      "`tabItem`.`item_collection`",
      "`tabItem`.`item_name`",
      "`tabItem`.`standard_rate`",
      "`tabItem`.`opening_stock`",
      "`tabItem`.`is_stock_item`",
      "`tabItem`.`image`",
      "`tabItem`.`discount`",
      "`tabItem`.`net_amount`",
      "`tabItem`.`net_rate`",
      // "`tabItem`.`price_list_rate`",
      "`tabItem`.`discount_amount`",
      "`tabItem Tax`.`item_tax_template`",
      "`tabItem`.`description`",
      "`tabItem`.`default_material_request_type`",
      "`tabItem`.`weight_per_unit`",
      "`tabItem`.`weight_uom`",
      "`tabItem`.`country_of_origin`",
      "`tabItem`.`valuation_rate`",
      "`tabItem`.`has_variants`",
      "`tabItem`.`variant_of`",
      "`tabItem`.`is_purchase_item`",
      "`tabItem`.`barcode`",
      "`tabItem`.`external_id`",
    ],
    filters: [
      ["Item Default", "company", "=", `${company}`],
      ["Item", "disabled", "=", 0],
    ],
    // start: start,
    // page_length: 500,
  };
  const url = `${process.env.REACT_APP_API_URI}/api/products`;

  return GetData(url, data);
};

export const fetchItemFinancierList = (company) => {
  const data = {
    doctype: "Item Financier",
        fields: [
          "name",
          "owner",
          "creation",
          "modified",
          "modified_by",
          "idx",
          "docstatus",
          "disabled",
          "type",
          "code",
          "libelle",
          "division_commerciale",
          "rattachement_à_un_client",
          "type_article_financier",
          "type_d_utilisation",
          "type_de_demande_de_crédit",
          "utilisation_avec_d_autre_article",
          "utilisable_pour_la_fidélité",
          "compté_dans_les_indices",
          "compté_dans_le_cumul_des_quantités",
          "envoi_du_montant_au_tpe",
          "remisable_en_ligne",
          "ignoré_par_mimprimante_fiscale",
          "pré_enregistré",
          "prix",
          "journal_comptable",
          "attribution_du_numéro_de_bon",
          "date_de_début",
          "jusquau",
          
        ],
     
     
    // start: start,
    // page_length: 500,
  };
  const url = `${process.env.REACT_APP_API_URI}/api/products`;

  return GetData(url, data);
}; 

export const loadProductsBundleData = () => {
  const data = {
    doctype: "Product Bundle",
    fields: [
      "`tabProduct Bundle`.`name`",
      "`tabProduct Bundle`.`new_item_code`",
      "`tabProduct Bundle`.`description`",
    ],
  };
  const url = `${process.env.REACT_APP_API_URI}/api/products-bundle`;

  return GetData(url, data);
};

export const loadItemCollectionData = () => {
  const data = {
    doctype: "Item Collection",
    fields: [
      "`tabItem Collection`.`name`",
      "`tabItem Collection`.`collection_name`",
    ],
  };
  const url = `${process.env.REACT_APP_API_URI}/api/item-collection`;

  return GetData(url, data);
};

export const loadProductsByPos = async (page, pricelist, itemgroup) => {
  const url = `${process.env.REACT_APP_API_URI}/api/pos/products`;

  return localforage
    .getItem("reduxPersist:Login")
    .then(async function (results) {
      const POSList = JSON.parse(results)?.pos;
      const selectedWarehouse = JSON.parse(results)?.selectedData.store;
      const selectedPOSProfile = JSON.parse(results)?.selectedData.cashier;
      const user = JSON.parse(results).user;

      //  >>> GET PRODUCTS BASED ON LOGIN INFO
      if (user.role == "Admin") {
        const anyPOSProfile = POSList?.filter(
          (el, i) => el.warehouse == selectedWarehouse
        );
        const data = {
          pos_profile: anyPOSProfile[0]?.name,
          price_list: pricelist,
          item_group: itemgroup,
          start: page,
          page_length: 500,
        };
        return GetData(url, data);
      } else {
        const data = {
          pos_profile: selectedPOSProfile,
          price_list: pricelist,
          item_group: itemgroup,
          start: page,
          page_length: 500,
        };
        return GetData(url, data);
      }
    })
    .catch((err) => {
      console.log("error login");
    });
};

export const loadCountryData = () => {
  const data = {
    doctype: "Country",
    fields: [
      "`tabCountry`.`name`",
      "`tabCountry`.`code`",
      "`tabCountry`.`country_name`",
    ],
  };
  const url = `${process.env.REACT_APP_API_URI}/api/country`;

  return GetData(url, data);
};

export const loadTerritoryData = (parentterritory) => {
  const data = {
    doctype: "Territory",
    parent: parentterritory,
    is_root: true,
  };
  const url = `${process.env.REACT_APP_API_URI}/api/territory`;
  return GetData(url, data);
};

export const guestloadTerritoryData = (parentterritory) => {
  const data = {
    doctype: "Territory",
    parent: parentterritory,
    is_root: true,
  };
  const url = `${process.env.REACT_APP_API_URI}/api/guest/territory`;
  return GuestGetData(url, data);
};

export const loadCustomersGroupData = (customergroupparent) => {
  const data = {
    doctype: "Customer Group",
    parent: customergroupparent,
    is_root: true,
  };

  const url = `${process.env.REACT_APP_API_URI}/api/customers-groups`;

  return GetData(url, data);
};

export const loadAddressData = (country) => {
  const data = {
    doctype: "Address",
    fields: [
      "`tabAddress`.`name`",
      "`tabAddress`.`owner`",
      "`tabAddress`.`creation`",
      "`tabAddress`.`modified`",
      "`tabAddress`.`modified_by`",
      "`tabAddress`.`_user_tags`",
      "`tabAddress`.`_comments`",
      "`tabAddress`.`_assign`",
      "`tabAddress`.`_liked_by`",
      "`tabAddress`.`docstatus`",
      "`tabAddress`.`idx`",
      "`tabAddress`.`address_type`",
      "`tabAddress`.`city`",
      "`tabAddress`.`disabled`",
    ],
    filters: [["Address", "country", "=", country]],
  };

  const url = `${process.env.REACT_APP_API_URI}/api/address`;

  return GetData(url, data);
};

export const loadOrdersData = (company) => {
  const data = {
    doctype: "Sales Order",
    fields: [
      "`tabSales Order`.`name`",
      "`tabSales Order`.`creation`",
      "`tabSales Order`.`delivery_date`",
      "`tabSales Order`.`grand_total`",
      "`tabSales Order`.`customer_name`",
      "`tabSales Order`.`delivery_status`",
      "`tabSales Order`.`status`",
    ],
    filters: [["Sales Order", "company", "=", `${company}`]],
  };
  const url = `${process.env.REACT_APP_API_URI}/api/orders`;

  return GetData(url, data);
};

export const loadReturnReasons = () => {
  const data = {
    doctype: "Opportunity Lost Reason",
    fields: ["`tabOpportunity Lost Reason`.`name`"],
    start: 0,
    page_length: 50,
    order_by: "`tabOpportunity Lost Reason`.`modified` desc",
  };
  const url = `${process.env.REACT_APP_API_URI}/api/get-list`;

  return GetData(url, data);
};
export const loadMotifInventory = () => {
  const data = {
    doctype: "Quotation Lost Reason",
    fields: ["`tabQuotation Lost Reason`.`name`"],
    start: 0,
    page_length: 50,
    order_by: "`tabQuotation Lost Reason`.`modified` desc",
  };
  const url = `${process.env.REACT_APP_API_URI}/api/get-list`;

  return GetData(url, data);
};

export const loadStockReportData = (company) => {
  const data = {
    report_name: "Stock Projected Qty",
    filters: {
      company: company,
      warehouse: "",
      item_group: "",
    },
  };
  const url = `${process.env.REACT_APP_API_URI}/api/stock/projected-qty`;

  return GetData(url, data);
};

export const loadPayementMethodsData = (company) => {
  const data = {
    doctype: "Mode of Payment",
    fields: [
      "`tabMode of Payment`.`name`",
      "`tabMode of Payment`.`mode_of_payment`",
      "`tabMode of Payment`.`enabled`",
      "`tabMode of Payment`.`type`",
      "`tabMode of Payment Account`.`default_account`",
    ],
    filters: [
      ["Mode of Payment Account", "company", "=", `${company}`],
      ["Mode of Payment", "enabled", "=", 1],
      ["Mode of Payment Account", "default_account", "is", "set"],
    ],
  };
  const url = `${process.env.REACT_APP_API_URI}/api/payment-methods`;
  return GetData(url, data);
};

export const loadTaxData = (company) => {
  const data = {
    doctype: "Item Tax Template",
    fields: [
      "`tabItem Tax Template`.`name`",
      "`tabItem Tax Template`.`title`",
      "`tabItem Tax Template`.`disabled`",
      "`tabItem Tax Template Detail`.`tax_rate`",
      "`tabItem Tax Template Detail`.`tax_type`",
    ],
    filters: [["Item Tax Template", "company", "=", `${company}`]],
    /* start: start,
    page_length: 500, */
  };
  const url = `${process.env.REACT_APP_API_URI}/api/payment-methods`;
  return GetData(url, data);
};

/* export const LoadCategories = (company,itemgroup) => {
  const data = {
    doctype: "Item Group",
    company: `${company}`,
    parent: itemgroup,
       start: start,
       page_length: 500, 
  };
  const url = `${process.env.REACT_APP_API_URI}/api/products/categories`;
  return GetData(url, data);
}; */

// export const LoadCategories = (itemgroup) => {
//   const data = {
//     doctype: "Item Group",
//     fields: [
//       "`tabItem Group`.`name`",
//       "`tabItem Group`.`parent`",
//       "`tabItem Group`.`parenttype`",
//       "`tabItem Group`.`parentfield`",
//       "`tabItem Group`.`item_group_name`",
//       "`tabItem Group`.`parent_item_group`",
//       "`tabItem Group`.`is_group`",
//       "`tabItem Group`.`route`",
//     ],
//     filters: [
//       [
//         "Item Group",
//         "route",
//         "like",
//         itemgroup?.replaceAll(" ", "-")?.toLowerCase() + "%",
//       ],
//       ["Item Group", "is_group", "=", 0],
//     ],
//   };
//   const url = `${process.env.REACT_APP_API_URI}/api/get-list`;
//   return GetData(url, data);
// };
export const loadInventoryData = (company) => {
  const data = {
    report_name: "Stock Ledger",
    filters: {
      company: company,
      from_date: moment().startOf("year").format("YYYY-MM-DD"),
      to_date: moment().format("YYYY-MM-DD"),
    },
  };
  const url = `${process.env.REACT_APP_API_URI}/api/inventory`;
  return GetData(url, data);
};

export const loadDefaultTax = (company) => {
  // TIMBRE FISCAL
  const data = {
    doctype: "Sales Taxes and Charges Template",
    fields: ["`tabSales Taxes and Charges Template`.`name`"],
    filters: [
      ["Sales Taxes and Charges Template", "title", "=", "Tax Stamp"],
      ["Sales Taxes and Charges Template", "company", "=", `${company}`],
    ],
  };
  const url = `${process.env.REACT_APP_API_URI}/api/default-tax`;

  return GetData(url, data);
};

export const LoadTotalItems = () => {
  const url = `${process.env.REACT_APP_API_URI}/api/total`;
  return axiosInstance.get(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
      Authorization: JSON.parse(localStorage.getItem("user")).token,
    },
  });
};

export const LoadItemsImages = () => {
  const url = `${process.env.REACT_APP_API_URI}/api/item-images`;
  return axiosInstance.get(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
      Authorization: JSON.parse(localStorage.getItem("user")).token,
    },
  });
};

export const loadNotificationsData = () => {
  const url = `${process.env.REACT_APP_API_URI}/api/notifications`;

  return GetData(url);
};

export const loadLoyaltyProgramsData = () => {
  const data = {
    doctype: "Loyalty Program",
    fields: [
      "`tabLoyalty Program`.`name`",
      "`tabLoyalty Program Collection`.`tier_name`",
    ],
    order_by: "`tabLoyalty Program`.`modified` desc",
  };
  const url = `${process.env.REACT_APP_API_URI}/api/get-list`;

  return GetData(url, data);
};

export const loadSalesPersonData = (start) => {
  const url = `${process.env.REACT_APP_API_URI}/api/sales-person-list`;
  return localforage
    .getItem("reduxPersist:Login")
    .then(async function (results) {
      const selectedWarehouse = JSON.parse(results)?.selectedData.store;
      const data = {
        doctype: "Sales Person",
        fields: ["`tabSales Person`.`sales_person_name`"],
        filters: [
          ["Sales Person", "is_group", "=", 0],
          ["Sales Person", "warehouse", "=", selectedWarehouse],
        ],
        start: 0,
        page_length: 500,
      };
      return GetData(url, data);
    })
    .catch((err) => {
      const data = {
        doctype: "Sales Person",
        fields: ["`tabSales Person`.`sales_person_name`"],
        filters: [["Sales Person", "is_group", "=", 0]],
        start: 0,
        page_length: 500,
      };
      return GetData(url, data);
    });
};

export const loadCurrenciesData = (start) => {
  const data = {
    doctype: "Currency",
    fields: [
      "`tabCurrency`.`name`",
      "`tabCurrency`.`fraction`",
      "`tabCurrency`.`fraction_units`",
      "`tabCurrency`.`symbol`",
      "`tabCurrency`.`number_format`",
      "`tabCurrency`.`bank_notes`",
    ],
    filters: [["Currency", "enabled", "=", 1]],
    start: start,
    page_length: 500,
  };
  const url = `${process.env.REACT_APP_API_URI}/api/currencies`;

  return GetData(url, data);
};

export const GetData = (Url, data) => {
  return axiosInstance.post(Url, data, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
      Authorization: JSON.parse(localStorage.getItem("user"))?.token,
    },
  });
};

export const GuestGetData = (Url, data) => {
  return axiosInstance.post(Url, data, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
      Authorization: "token 665e7014b6be6ce:86de0a52a04f612",
    },
  });
};

export const loadCouponCodes = () => {
  const data = {
    doctype: "Coupon Code",
    fields: [
      "`tabCoupon Code`.`name`",
      "`tabCoupon Code`.`docstatus`",
      "`tabCoupon Code`.`idx`",
      "`tabCoupon Code`.`coupon_type`",
      "`tabCoupon Code`.`valid_from`",
      "`tabCoupon Code`.`coupon_name`",
      "`tabCoupon Code`.`coupon_code`",
      "`tabCoupon Code`.`pricing_rule`",
      "`tabCoupon Code`.`customer`",
      "`tabCoupon Code`.`description`",
      "`tabCoupon Code`.`pos_invoice`",
      "`tabCoupon Code`.`validity_period`",
      "`tabCoupon Code`.`used`",
      "`tabCoupon Code`.`valid_upto`",
    ],
  };
  const url = `${process.env.REACT_APP_API_URI}/api/coupon-codes-list`;

  return GetData(url, data);
};

export const loadAllCategories = () => {
  const data = {
    doctype: "Item Group",
    fields: [
      "`tabItem Group`.`name`",
      "`tabItem Group`.`owner`",
      "`tabItem Group`.`creation`",
      "`tabItem Group`.`modified`",
      "`tabItem Group`.`modified_by`",
      "`tabItem Group`.`_user_tags`",
      "`tabItem Group`.`_comments`",
      "`tabItem Group`.`_assign`",
      "`tabItem Group`.`_liked_by`",
      "`tabItem Group`.`docstatus`",
      "`tabItem Group`.`idx`",
      "`tabItem Group`.`item_group_name`",
      "`tabItem Group`.`parent_item_group`",
      "`tabItem Group`.`is_group`",
      "`tabItem Group`.`route`",
      "`tabItem Group`.`image`",
    ],
    filters: [["Item Group", "is_group", "=", 0]],
    /* start: start,
    page_length: 500, */
  };
  const url = `${process.env.REACT_APP_API_URI}/api/get-list`;
  return GetData(url, data);
};
