import React, { useEffect, useState,useRef } from "react";
import { Button,Checkbox } from "@material-ui/core";
import axios from 'axios';
import readXlsxFile from 'read-excel-file'
import { SetNotification } from "../../../../store/notification/notification.action";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
const ImportPurchaseInvoiceFile = ({setCloseModalImport}) => {
    const dispatch = useDispatch();
    const fileInputRef = useRef(null);
    const [checked, setChecked] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const { t } = useTranslation(["buying", "common"]);
    const handleIconClick = () => {
      fileInputRef.current.click();
    };
 

  
    const handleFileChange = (e) => {
      const file = e.target.files[0];
      setLoadingButton(true);
      // Handle the selected file here, e.g., upload it to the server
     if(file){
    //  uploadFile(file);
      readExcel(file);
      
     }
    };
    const readExcel = (file) => {
        readXlsxFile(file).then((rows) => {
            // Traverse through each row
            for (let i = 1; i < rows.length; i++) {
                const row = rows[i];
                if (row.length > 0) {
                    verificationSupplierQuotation(row[0]);
                }
            }
        }).catch(error => console.error(error));
    };
    const verificationSupplierQuotation = (name) => {
       
        fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
            method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
                    Authorization: JSON.parse(localStorage.getItem("user")).token,
                },
            body: JSON.stringify({

                "doctype": "Purchase Invoice",
"fields": ["`tabPurchase Invoice`.`name`","`tabPurchase Invoice`.`owner`","`tabPurchase Invoice`.`creation`","`tabPurchase Invoice`.`modified`","`tabPurchase Invoice`.`modified_by`","`tabPurchase Invoice`.`_user_tags`","`tabPurchase Invoice`.`_comments`","`tabPurchase Invoice`.`_assign`","`tabPurchase Invoice`.`_liked_by`","`tabPurchase Invoice`.`docstatus`","`tabPurchase Invoice`.`parent`","`tabPurchase Invoice`.`parenttype`","`tabPurchase Invoice`.`parentfield`","`tabPurchase Invoice`.`idx`","`tabPurchase Invoice`.`title`","`tabPurchase Invoice`.`total`","`tabPurchase Invoice`.`net_total`","`tabPurchase Invoice`.`taxes_and_charges_added`","`tabPurchase Invoice`.`taxes_and_charges_deducted`","`tabPurchase Invoice`.`total_taxes_and_charges`","`tabPurchase Invoice`.`discount_amount`","`tabPurchase Invoice`.`grand_total`","`tabPurchase Invoice`.`rounding_adjustment`","`tabPurchase Invoice`.`rounded_total`","`tabPurchase Invoice`.`total_advance`","`tabPurchase Invoice`.`outstanding_amount`","`tabPurchase Invoice`.`paid_amount`","`tabPurchase Invoice`.`write_off_amount`","`tabPurchase Invoice`.`status`","`tabPurchase Invoice`.`supplier`","`tabPurchase Invoice`.`supplier_name`","`tabPurchase Invoice`.`base_grand_total`","`tabPurchase Invoice`.`due_date`","`tabPurchase Invoice`.`company`","`tabPurchase Invoice`.`currency`","`tabPurchase Invoice`.`is_return`","`tabPurchase Invoice`.`release_date`","`tabPurchase Invoice`.`on_hold`","`tabPurchase Invoice`.`represents_company`","`tabPurchase Invoice`.`is_internal_supplier`","`tabPurchase Invoice`.`party_account_currency`"],
"filters": [["Purchase Invoice","name","=",name]] 
            }),
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                if (data[0] && data[0].name !== undefined && data[0].name !== null) {
                    dispatch(
                        SetNotification({
                          code: "error",
                          message: `Purchase Receipt ${name} already exists`,
                        })
                      );
                      setLoadingButton(false);
                } else {
                    uploadFile(fileInputRef.current.files[0]);
                    setLoadingButton(false);
                }
                
            }).catch((error) => {
                console.error('Error fetching data:', error);
                // Handle error as needed
            });
        
    };
    const uploadFile = async (file) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('docname', 'Purchase Invoice');
    
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URI}/api/upload-purchase-invoice-file`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        dispatch(
            SetNotification({
              code: "success",
              message: `Purchase invoice imported successfully`,
            })
          );
        setCloseModalImport(true);
      } catch (error) {
        dispatch(
            SetNotification({ code: "error", message: "Something went wrong" })
          );
          setCloseModalImport(true);
        console.error('Error uploading file:', error);
      }
    };
    const handleDownloadClick = () => {
        // Simulating download from a source
        const fileUrl = `${process.env.PUBLIC_URL}/assets/file/Purchase_Invoice.xlsx`;
        window.open(fileUrl);
      };
     
return( 
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Button
        size="large"
        color="primary"
        variant="outlined"
        onClick={handleDownloadClick}
        startIcon={<CloudDownloadIcon />}
        style={{ marginBottom: '10px' }}
      >
        {t("common:DownloadTemplateForImportPurchaseInvoice")}
      </Button>
    </div>
    <div style={{display: 'flex', flexDirection: 'row', textAlign: 'center', fontSize: '12px', marginBottom: '10px', marginTop: '5px', color: 'red' }}>
    <Checkbox
        checked={checked}
        onChange={(e) => setChecked(e.target.checked)}
        color="primary"
        inputProps={{ 'aria-label': 'secondary checkbox' }}
      />
      {t("common:respectFileRegulations")}
    </div>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Button
       disabled={loadingButton}
        size="large"
        color="primary"
        variant="outlined"
        onClick={handleIconClick}
        startIcon={<CloudUploadIcon />}
        style={{ marginBottom: '10px', visibility: checked ? 'visible' : 'hidden' }}
      >
        {t("common:importFile")}
      </Button>
     
    </div>
    <input
      type="file"
      ref={fileInputRef}
      style={{ display: 'none' }}
      accept=".xlsx, .xls"
      onChange={handleFileChange}
    />
  </div>
)
    
}

export default ImportPurchaseInvoiceFile;