import React, { useEffect, useRef } from "react";
import Box from "@material-ui/core/Box";
import { useStyles } from "../../style";
import { Button, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { useTableStyles } from "../../../../styles/table.style";
import { useDispatch, useSelector } from "react-redux";
import {
  GetOrderdProducts,
  ProductsAreEquals,
} from "../../../../helpers/helper";
import {
  UpdateManyOrderStatus,
  CreateStatusEmail,
} from "../../../../store/fullfilment/fullfilment.action";
import { OrdersToPick } from "./components/ordersTopick";
import { BasicTable } from "../../../checkout/components/basictable";
import { GlobalfilterData } from "../../../../helpers/helper";
import { Invoice } from "../../../../components/invoice";
import validator from "validator";
import { useTranslation } from "react-i18next";

export const FulfillementPicking = () => {
  const classes = useStyles();
  const history = useHistory();
  const componentRef = useRef();
  const { t } = useTranslation(["order", "common", "fulfillment"]);
  const tableclasses = useTableStyles();
  const products = useSelector((state) => state.Product.productsPos);
  const order = useSelector((state) => state.Fullfilment.order);
  const fullfilments = useSelector((state) => state.Fullfilment.fullfilments);
  const ordersTopick = useSelector((state) => state.Fullfilment.ordersToPick);
  const itemLocation = useSelector((state) => state.Fullfilment.itemLocation);
  const [orderdProducts, setOrderdProducts] = React.useState([]);
  const [initialLines, setInitialLines] = React.useState([0, 1, 2, 3]);
  const [searchProduct, setSearchProduct] = React.useState("");
  const [suggestion, setsuggestion] = React.useState([]);
  const [state, setState] = React.useState({
    items: [],
  });
  const dispatch = useDispatch();

  const HandleGroupByProducts = () => {
    const allProductsTopick = [];
    ordersTopick.orders.forEach((element) => {
      const orderToPick = fullfilments.find((f) => f.order_id == element);
      const ProductsTopick =
        orderToPick && orderToPick.products
          ? GetOrderdProducts([...products], orderToPick.products)
          : [];
      ProductsTopick.forEach((product) => {
        const index = allProductsTopick.findIndex(
          (p) => p.item_code == product.item_code
        );
        const itemPlacement =
          Array.isArray(itemLocation) && itemLocation.lenght != 0
            ? itemLocation.find((i) => i.item_code == product.item_code)
            : [];
        if (index !== -1) {
          allProductsTopick[index].item_location =
            itemPlacement && itemPlacement.item_location
              ? itemPlacement.item_location
              : "-";
          allProductsTopick[index].order_id =
            allProductsTopick[index].order_id + " / " + element;
          allProductsTopick[index].quantity =
            allProductsTopick[index].quantity + product.quantity;
          if (
            allProductsTopick[index].actual_qty <
            allProductsTopick[index].quantity
          ) {
            allProductsTopick[index].unavailable_qty =
              allProductsTopick[index].quantity -
              allProductsTopick[index].actual_qty;
          } else {
            allProductsTopick[index].unavailable_qty = 0;
          }
        } else {
          if (product.actual_qty < product.quantity) {
            product.unavailable_qty = product.quantity - product.actual_qty;
          } else {
            product.unavailable_qty = 0;
          }
          product.item_location =
            itemPlacement && itemPlacement.item_location
              ? itemPlacement.item_location
              : "-";
          product.order_id = element;
          allProductsTopick.push(product);
        }
      });
      setOrderdProducts(allProductsTopick);
    });
  };

  const FormatOrders = (orders) => {
    const allProductsTopick = [];
    orders.forEach((element) => {
      const orderToPick = fullfilments.find(
        (f) => f.order_id == element.order_id
      );
      const ProductsTopick =
        orderToPick && orderToPick.products
          ? GetOrderdProducts([...products], orderToPick.products)
          : [];
      ProductsTopick.forEach((product) => {
        const index = allProductsTopick.findIndex(
          (p) => p.item_code == product.item_code
        );
        const itemPlacement =
          Array.isArray(itemLocation) && itemLocation.lenght != 0
            ? itemLocation.find((i) => i.item_code == product.item_code)
            : [];
        if (index !== -1) {
          allProductsTopick[index].item_location =
            itemPlacement && itemPlacement.item_location
              ? itemPlacement.item_location
              : "-";
          allProductsTopick[index].quantity =
            allProductsTopick[index].quantity + product.quantity;
          if (
            allProductsTopick[index].actual_qty <
            allProductsTopick[index].quantity
          ) {
            allProductsTopick[index].unavailable_qty =
              allProductsTopick[index].quantity -
              allProductsTopick[index].actual_qty;
          } else {
            allProductsTopick[index].unavailable_qty = 0;
          }
        } else {
          if (product.actual_qty < product.quantity) {
            product.unavailable_qty = product.quantity - product.actual_qty;
          } else {
            product.unavailable_qty = 0;
          }
          product.item_location =
            itemPlacement && itemPlacement.item_location
              ? itemPlacement.item_location
              : "-";
          allProductsTopick.push(product);
        }
      });
    });
    return allProductsTopick;
  };

  const HandleGroupByOrder = () => {
    const allOrdersTopick = [];
    ordersTopick.orders.forEach((element) => {
      const orderToPick = fullfilments.find((f) => f.order_id == element);
      const ProductsTopick =
        orderToPick && orderToPick.products
          ? GetOrderdProducts([...products], orderToPick.products)
          : [];
      ProductsTopick.forEach((product) => {
        if (product.actual_qty < product.quantity) {
          product.unavailable_qty = product.quantity - product.actual_qty;
        } else {
          product.unavailable_qty = 0;
        }
        const itemPlacement =
          Array.isArray(itemLocation) && itemLocation.lenght != 0
            ? itemLocation.find((i) => i.item_code == product.item_code)
            : [];
        product.item_location =
          itemPlacement && itemPlacement.item_location
            ? itemPlacement.item_location
            : "-";
      });
      const data = {
        order_id: element,
        products: ProductsTopick,
      };
      allOrdersTopick.push(data);
      setOrderdProducts(allOrdersTopick);
    });
  };

  const HandleQuantityChange = (onAdd, id) => {
    const ItemIndex = state.items.findIndex((s) => s.item_code == id);
    const data = [...state.items];
    if (ItemIndex != -1) {
      data[ItemIndex].quantity = onAdd
        ? data[ItemIndex].quantity + 1
        : Math.sign(data[ItemIndex].quantity - 1) == 1
          ? data[ItemIndex].quantity - 1
          : 0;
      data[ItemIndex].qty = data[ItemIndex].quantity;
      data[ItemIndex].stock_qty = data[ItemIndex].quantity;
      setState({ ...state, items: data });
    }
  };

  const HandleDelete = (id) => {
    const data = state.items.filter((s) => s.item_code !== id);
    setState({ ...state, items: data });
  };

  const handleSearchProduct = (e) => {
    setSearchProduct(e.target.value);
    if (e.target.value == "") {
      setsuggestion([]);
      return;
    }
    const data = GlobalfilterData(
      products?.filter((p) => p?.is_stock_item == 1), e.target.value, [
      "item_code",
      "item_name",
      "description",
      "price_list_rate",
    ]);

    setsuggestion(data);
  };
  const handleDisableQuantityButton = (id) => {
    const pickedItem = state.items.find((p) => p.item_code == id);
    if (ordersTopick.groupBy == "groupByProduct") {
      var productTotal = 0;
      const orderItem = orderdProducts.find((p) => p.item_code == id);
      if (
        orderItem &&
        orderItem.actual_qty &&
        orderItem.actual_qty > orderItem.quantity
      ) {
        productTotal += orderItem.quantity;
      } else if (orderItem && orderItem.actual_qty) {
        productTotal += orderItem.actual_qty;
      }
      return productTotal === pickedItem.quantity;
    } else {
      var productTotal = 0;
      orderdProducts.forEach((order) => {
        var data = order.products.find((p) => p.item_code == id);
        if (data && data.actual_qty && data.actual_qty > data.quantity) {
          productTotal += data.quantity;
        } else if (data && data.actual_qty) {
          productTotal += data.actual_qty;
        }
      });
      return productTotal === pickedItem.quantity;
    }
  };

  const handleAddproduct = (id) => {
    var data = suggestion.find((s) => s.item_name == id.item_name);
    const ItemIndex = state.items.findIndex((s) => s.item_name == id.item_name);

    if (ItemIndex != -1) {
      if (ordersTopick.groupBy == "groupByProduct") {
        const orderItemIndx = orderdProducts.findIndex(
          (p) => p.item_name == id.item_name
        );
        if (orderItemIndx != -1) {
          const orderItem = orderdProducts.find((p) => p.item_name == id.item_name);
          if (
            orderItem &&
            orderItem.actual_qty > state.items[ItemIndex].quantity
          ) {
            state.items[ItemIndex].quantity =
              state.items[ItemIndex].quantity + 1;
            state.items[ItemIndex].qty = state.items[ItemIndex].qty + 1;
            state.items[ItemIndex].stock_qty =
              state.items[ItemIndex].stock_qty + 1;
          }
        }
      } else {
        orderdProducts.forEach((order) => {
          const prdctIndex = order.products.findIndex((p) => p.item_name == id.item_name);
          if (prdctIndex != -1) {
            var prdct = order.products.find((p) => p.item_name == id.item_name);
            if (prdct && prdct.actual_qty > state.items[ItemIndex].quantity) {
              state.items[ItemIndex].quantity =
                state.items[ItemIndex].quantity + 1;
              state.items[ItemIndex].qty = state.items[ItemIndex].qty + 1;
              state.items[ItemIndex].stock_qty =
                state.items[ItemIndex].stock_qty + 1;
            }
          }
        });
      }
    } else {
      if (ordersTopick.groupBy == "groupByProduct") {
        const orderItemIndx = orderdProducts.findIndex(
          (p) => p.item_name == id.item_name
        );
        if (orderItemIndx != -1) {
          data.qty = 1;
          data.quantity = 1;
          data.stock_qty = 1;
          state.items.push(data);
          setState({ ...state, items: state.items });
          initialLines.splice(-1);
        }
      } else {
        orderdProducts.forEach((order) => {
          const prdctIndex = order.products.findIndex((p) => p.item_name == id.item_name);
          if (prdctIndex != -1) {
            data.qty = 1;
            data.quantity = 1;
            data.stock_qty = 1;
            state.items.push(data);
            setState({ ...state, items: state.items });
            initialLines.splice(-1);
          }
        });
      }
    }
    setsuggestion([]);
    setSearchProduct("");
  };

  const HandleUpdateQuantity = (e, id) => {
    const ItemIndex = state.items.findIndex((s) => s.item_code == id);
    const data = [...state.items];
    const value = parseInt(e.target.value);
    if (ItemIndex != -1) {
      if (ordersTopick.groupBy == "groupByProduct") {
        const orderItem = orderdProducts.find((p) => p.item_code == id);
        if (orderItem && orderItem.actual_qty >= value) {
          data[ItemIndex].quantity = value;
          data[ItemIndex].stock_qty = value;
          data[ItemIndex].qty = value;
        }
      } else {
        orderdProducts.forEach((order) => {
          var prdct = order.products.find((p) => p.item_code == id);
          if (prdct && prdct.actual_qty >= value) {
            data[ItemIndex].quantity = value;
            data[ItemIndex].stock_qty = value;
            data[ItemIndex].qty = value;
          }
        });
      }
      setState({ ...state, items: data });
    }
  };

  useEffect(() => {
    if (ordersTopick.groupBy == "groupByProduct") {
      HandleGroupByProducts();
    } else {
      HandleGroupByOrder();
    }
  }, [ordersTopick]);

  const handleUpdateOrders = (status) => {
    dispatch(
      UpdateManyOrderStatus({
        status,
        orders: ordersTopick.orders,
        redirectToTab: 3,
      })
    );
  };
  return (
    <Box className={classes.block}>
      <Box className={classes.titleblock}>
        <span
          onClick={() => history.push(`/fulfillement?tab=2`)}
          className={clsx(classes.closeicon, "icon-close")}
        />
        <Typography
          align={"center"}
          className={classes.title}
          color={"primary"}
          variant="h5"
        >
          Picking
        </Typography>
      </Box>

      <Box mb={-10} className={clsx(classes.productPaper, tableclasses.paper)}>
        <Box mt={3} display={"flex"} justifyContent={"right"}>
          <Invoice
            title={t("fulfillment:pickTitle")}
            header={ordersTopick.orders}
            order={FormatOrders(orderdProducts)}
            document={null}
          />
        </Box>
        <Box ref={componentRef}>
          {ordersTopick &&
            ordersTopick.groupBy &&
            (ordersTopick.groupBy == "groupByProduct" ? (
              <Box>
                <Typography
                  align={"center"}
                  color={"primary"}
                  variant="h6"
                  style={{ marginTop: 40 }}
                >
                  {t("fulfillment:itemTopick")}
                </Typography>
                <OrdersToPick
                  byProduct={true}
                  orderdProducts={orderdProducts}
                />
              </Box>
            ) : (
              orderdProducts.map((row, index) => (
                <Box key={index}>
                  <Typography
                    align={"center"}
                    color={"primary"}
                    variant="h6"
                    style={{ marginTop: 40 }}
                  >
                    {row.order_id}
                  </Typography>
                  <OrdersToPick
                    byProduct={false}
                    orderdProducts={row.products}
                  />
                </Box>
              ))
            ))}
        </Box>
        <BasicTable
          suggestion={suggestion}
          initialLines={initialLines}
          rows={state.items}
          HandleQuantityChange={HandleQuantityChange}
          HandleDelete={HandleDelete}
          handleSearchProduct={handleSearchProduct}
          handleAddproduct={handleAddproduct}
          handleDisableQuantityButton={handleDisableQuantityButton}
          searchProduct={searchProduct}
          transfer={true}
          HandleUpdateQuantity={HandleUpdateQuantity}
          stockEntry={true}
        />
        <Box mt={4} mb={3} display="flex" justifyContent="flex-end">
          <Button
            onClick={() => handleUpdateOrders("verified")}
            variant="outlined"
            color="primary"
          >
            {t("fulfillment:rePrepare")}
          </Button>
          <Button
            style={{ marginLeft: 5 }}
            onClick={() => handleUpdateOrders("picked")}
            variant="contained"
            disabled={
              !ProductsAreEquals(
                ordersTopick.groupBy == "groupByProduct"
                  ? orderdProducts
                  : FormatOrders(orderdProducts),
                state.items
              )
            }
            color="primary"
          >
            {t("fulfillment:markPicked")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
