import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Loader } from "../../../../components/loader";
import { makeStyles } from "@material-ui/core/styles";
import { Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import i18n from "./../../../../i18n";

const useStyles = makeStyles((theme) => ({
  greyTableHeadCell: {
    backgroundColor: "#f0f0f0",
  },
  printButton: {
    textAlign: "right",
  },
  tableRow: {
    pageBreakInside: "auto",
    breakAfter: "auto",
    breakBefore: "auto",
    breakInside: "auto",
    border: "1px solid black",
    borderCollapse: "collapse",
    padding: "5px",
    width: "50%",
    display: "table",
    tableLayout: "fixed",
  },
  tableCell: {
    pageBreakInside: "auto",
  },
  table: {
    maxWidth: "100%",
    width: "50%",
    "@media print": {
      maxWidth: "50%",
      width: "50%",
    },
  },
}));

const RecapPurchaseReceipt = ({ id }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const [supplierList, setSupplierList] = useState([]);
  const [addressSupplier, setAddressSupplier] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [contactNumber, setContactNumber] = useState("");
  const [quotationNo, setQuotationNo] = useState("");
  const [supplierSelected, setSupplierSelected] = useState("");
  const [companySelected, setCompanySelected] = useState("");
  const [currencySelected, setCurrencySelected] = useState("");
  const [priceSelected, setPriceSelected] = useState("");
  const [purchaseTaxTemplateSelected, setPurchaseTaxTemplateSelected] =
    useState("");
  const [taxCategorySelected, setTaxCategorySelected] = useState("");
  const [addressSelected, setAddressSelected] = useState("");
  const [contactSelected, setContactSelected] = useState("");
  const [transactionDate, setTransactionDate] = useState("");
  const [state, setState] = useState({
    items: [],
    taxes: [],
  });

  const componentRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URI}/api/details`,
          {
            params: {
              doctype: "Purchase Receipt",
              name: id.name,
            },
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
              Authorization: JSON.parse(localStorage.getItem("user")).token,
            },
          }
        );

        const fetchedData = response.data["docs"];
        if (fetchedData.length > 0) {
          const item = fetchedData[0];
          setState({
            items: item.items || [],
            taxes: item.taxes || [],
          });
          setContactNumber(item.contact_mobile);
          setQuotationNo(item.name);
          setSupplierSelected(item.supplier);
          setCompanySelected(item.company);
          setCurrencySelected(item.currency);
          setPriceSelected(item.price_list);
          setPurchaseTaxTemplateSelected(item.purchase_tax_template);
          setTaxCategorySelected(item.tax_category);
          setAddressSelected(item.supplier_address);
          setContactSelected(item.contact_person);
          setTransactionDate(item.transaction_date);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const getSupplierList = () => {
    fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        doctype: "Supplier",
        fields: [
          "`tabSupplier`.`name`",
          "`tabSupplier`.`supplier_name`",
          "`tabSupplier`.`mobile_no`",
        ],
        filters: [["Supplier", "supplier_name", "like", id.supplier]],
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setSupplierList(data);
        }
      });
  };

  const getAddressSupplierList = () => {
    fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        doctype: "Address",
        fields: [
          "`tabAddress`.`name`",
          "`tabAddress`.`city`",
          "`tabAddress`.`address_line1`",
          "`tabAddress`.`address_line2`",
        ],
        filters: [
          [
            "Address",
            "name",
            "like",
            supplierList[0]?.supplier_primary_address,
          ],
        ],
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setAddressSupplier(data);
        }
      });
  };

  useEffect(() => {
    getSupplierList();
  }, []);

  useEffect(() => {
    if (supplierList.length > 0) {
      getAddressSupplierList();
    }
  }, [supplierList]);

  // const convertirMontantEnLettres = (montant) => {
  //   let unites = [];
  //   let dizaines = [];
  //   if (i18n.language === "fr") {
  //     unites = [
  //       "",
  //       "un",
  //       "deux",
  //       "trois",
  //       "quatre",
  //       "cinq",
  //       "six",
  //       "sept",
  //       "huit",
  //       "neuf",
  //     ];
  //     dizaines = [
  //       "",
  //       "dix",
  //       "vingt",
  //       "trente",
  //       "quarante",
  //       "cinquante",
  //       "soixante",
  //       "soixante-dix",
  //       "quatre-vingts",
  //       "quatre-vingt-dix",
  //     ];
  //   } else if (i18n.language === "en") {
  //     unites = [
  //       "",
  //       "one",
  //       "two",
  //       "three",
  //       "four",
  //       "five",
  //       "six",
  //       "seven",
  //       "eight",
  //       "nine",
  //     ];
  //     dizaines = [
  //       "",
  //       "ten",
  //       "twenty",
  //       "thirty",
  //       "forty",
  //       "fifty",
  //       "sixty",
  //       "seventy",
  //       "eighty",
  //       "ninety",
  //     ];
  //   } else {
  //     unites = [
  //       "",
  //       "واحد",
  //       "اثنين",
  //       "ثلاثة",
  //       "اربعة",
  //       "خمسة",
  //       "ستة",
  //       "سبعة",
  //       "ثمانية",
  //       "تسعة",
  //     ];
  //     dizaines = [
  //       "",
  //       "عشرة",
  //       "عشرون",
  //       "ثلاثون",
  //       "اربعون",
  //       "خمسون",
  //       "ستون",
  //       "سبعون",
  //       "ثمانون",
  //       "تسعون",
  //     ];
  //   }

  //   const convertirGroupeEnLettres = (nombre) => {
  //     let resultat = "";

  //     const centaines = Math.floor(nombre / 100);
  //     const dizainesEtUnites = nombre % 100;

  //     if (i18n.language === "fr") {
  //       if (centaines > 0) {
  //         resultat += unites[centaines] + " cent ";
  //       }

  //       if (dizainesEtUnites >= 10 && dizainesEtUnites <= 19) {
  //         resultat += "et " + unites[dizainesEtUnites - 10] + " ";
  //       } else if (dizainesEtUnites >= 20) {
  //         resultat += dizaines[Math.floor(dizainesEtUnites / 10)] + " ";
  //         if (dizainesEtUnites % 10 !== 0) {
  //           resultat += unites[dizainesEtUnites % 10] + " ";
  //         }
  //       } else if (dizainesEtUnites > 0) {
  //         resultat += unites[dizainesEtUnites] + " ";
  //       }

  //       return resultat.trim();
  //     } else if (i18n.language === "en") {
  //       if (centaines > 0) {
  //         resultat += unites[centaines] + " hundred ";
  //       }

  //       if (dizainesEtUnites >= 10 && dizainesEtUnites <= 19) {
  //         resultat += "and " + unites[dizainesEtUnites - 10] + " ";
  //       } else if (dizainesEtUnites >= 20) {
  //         resultat += dizaines[Math.floor(dizainesEtUnites / 10)] + " ";
  //         if (dizainesEtUnites % 10 !== 0) {
  //           resultat += unites[dizainesEtUnites % 10] + " ";
  //         }
  //         return resultat.trim();
  //       } else if (dizainesEtUnites > 0) {
  //         resultat += unites[dizainesEtUnites] + " ";
  //       }

  //       return resultat.trim();
  //     } else {
  //       if (centaines > 0) {
  //         resultat += unites[centaines] + " مائة ";
  //       }

  //       if (dizainesEtUnites >= 10 && dizainesEtUnites <= 19) {
  //         resultat += "و " + unites[dizainesEtUnites - 10] + " ";
  //       } else if (dizainesEtUnites >= 20) {
  //         resultat += dizaines[Math.floor(dizainesEtUnites / 10)] + " ";
  //         if (dizainesEtUnites % 10 !== 0) {
  //           resultat += unites[dizainesEtUnites % 10] + " ";
  //         }
  //       } else if (dizainesEtUnites > 0) {
  //         resultat += unites[dizainesEtUnites] + " ";
  //       }

  //       return resultat.trim();
  //     }
  //   };

  //   const montantEntier = Math.floor(montant);
  //   const montantDecimal = Math.round((montant - montantEntier) * 1000);

  //   const milliards = Math.floor(montantEntier / 1000000000);
  //   const millions = Math.floor(
  //     (montantEntier - milliards * 1000000000) / 1000000
  //   );
  //   const milliers = Math.floor(
  //     (montantEntier - milliards * 1000000000 - millions * 1000000) / 1000
  //   );
  //   const restant = montantEntier % 1000;

  //   let resultat = "";

  //   if (i18n.language === "fr") {
  //     if (milliards > 0) {
  //       resultat += convertirGroupeEnLettres(milliards) + " milliard ";
  //     }

  //     if (millions > 0) {
  //       resultat += convertirGroupeEnLettres(millions) + " million ";
  //     }

  //     if (milliers > 0) {
  //       resultat += convertirGroupeEnLettres(milliers) + " mille ";
  //     }

  //     if (restant > 0) {
  //       resultat += convertirGroupeEnLettres(restant);
  //     }

  //     resultat += " dinars";

  //     if (montantDecimal > 0) {
  //       resultat +=
  //         " et " + convertirGroupeEnLettres(montantDecimal) + " millimes";
  //     }

  //     return resultat;
  //   } else if (i18n.language === "en") {
  //     if (milliards > 0) {
  //       resultat += convertirGroupeEnLettres(milliards) + " billion ";
  //     }

  //     if (millions > 0) {
  //       resultat += convertirGroupeEnLettres(millions) + " million ";
  //     }

  //     if (milliers > 0) {
  //       resultat += convertirGroupeEnLettres(milliers) + " thousand ";
  //     }

  //     if (restant > 0) {
  //       resultat += convertirGroupeEnLettres(restant);
  //     }

  //     resultat += " dinars";

  //     if (montantDecimal > 0) {
  //       resultat +=
  //         " and " + convertirGroupeEnLettres(montantDecimal) + " millims";
  //     }

  //     return resultat;
  //   } else {
  //     if (milliards > 0) {
  //       resultat += convertirGroupeEnLettres(milliards) + " مليار ";
  //     }

  //     if (millions > 0) {
  //       resultat += convertirGroupeEnLettres(millions) + " مليون ";
  //     }

  //     if (milliers > 0) {
  //       resultat += convertirGroupeEnLettres(milliers) + " ألف ";
  //     }

  //     if (restant > 0) {
  //       resultat += convertirGroupeEnLettres(restant);
  //     }

  //     resultat += " دينار";

  //     if (montantDecimal > 0) {
  //       resultat += " و " + convertirGroupeEnLettres(montantDecimal) + " مليم";
  //     }

  //     return resultat;
  //   }
  // };

  const unitsFr = [
    "",
    "un",
    "deux",
    "trois",
    "quatre",
    "cinq",
    "six",
    "sept",
    "huit",
    "neuf",
  ];
  const teensFr = [
    "",
    "onze",
    "douze",
    "treize",
    "quatorze",
    "quinze",
    "seize",
    "dix-sept",
    "dix-huit",
    "dix-neuf",
  ];
  const tensFr = [
    "",
    "dix",
    "vingt",
    "trente",
    "quarante",
    "cinquante",
    "soixante",
    "soixante-dix",
    "quatre-vingt",
    "quatre-vingt-dix",
  ];
  const hundredsFr = [
    "",
    "cent",
    "deux cents",
    "trois cents",
    "quatre cents",
    "cinq cents",
    "six cents",
    "sept cents",
    "huit cents",
    "neuf cents",
  ];

  const unitsEn = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
  ];
  const teensEn = [
    "",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];
  const tensEn = [
    "",
    "ten",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];
  const hundredsEn = [
    "",
    "one hundred",
    "two hundred",
    "three hundred",
    "four hundred",
    "five hundred",
    "six hundred",
    "seven hundred",
    "eight hundred",
    "nine hundred",
  ];

  const unitsAr = [
    "",
    "واحد",
    "اثنان",
    "ثلاثة",
    "أربعة",
    "خمسة",
    "ستة",
    "سبعة",
    "ثمانية",
    "تسعة",
  ];
  const teensAr = [
    "",
    "أحد عشر",
    "اثنا عشر",
    "ثلاثة عشر",
    "أربعة عشر",
    "خمسة عشر",
    "ستة عشر",
    "سبعة عشر",
    "ثمانية عشر",
    "تسعة عشر",
  ];
  const tensAr = [
    "",
    "عشرة",
    "عشرون",
    "ثلاثون",
    "أربعون",
    "خمسون",
    "ستون",
    "سبعون",
    "ثمانون",
    "تسعون",
  ];
  const hundredsAr = [
    "",
    "مائة",
    "مائتان",
    "ثلاثمائة",
    "أربعمائة",
    "خمسمائة",
    "ستمائة",
    "سبعمائة",
    "ثمانمائة",
    "تسعمائة",
  ];

  function numberToFrenchWords(number) {
    if (number === 0) return "zéro";
    let words = "";

    if (number >= 1000) {
      if (Math.floor(number / 1000) === 1) {
        words += "mille ";
      } else {
        words += numberToFrenchWords(Math.floor(number / 1000)) + " mille ";
      }
      number %= 1000;
    }

    if (number > 99) {
      words += hundredsFr[Math.floor(number / 100)] + " ";
      number %= 100;
    }
    if (number > 10 && number < 20) {
      words += teensFr[number - 10] + " ";
    } else {
      words += tensFr[Math.floor(number / 10)] + " ";
      number %= 10;
      words += unitsFr[number] + " ";
    }
    return words.trim();
  }

  function numberToEnglishWords(number) {
    if (number === 0) return "zero";
    let words = "";

    if (number >= 1000) {
      words += numberToEnglishWords(Math.floor(number / 1000)) + " thousand ";
      number %= 1000;
    }

    if (number > 99) {
      words += hundredsEn[Math.floor(number / 100)] + " ";
      number %= 100;
    }
    if (number > 10 && number < 20) {
      words += teensEn[number - 10] + " ";
    } else {
      words += tensEn[Math.floor(number / 10)] + " ";
      number %= 10;
      words += unitsEn[number] + " ";
    }
    return words.trim();
  }

  function numberToArabicWords(number) {
    if (number === 0) return "صفر";
    let words = "";

    if (number >= 1000) {
      const thousands = Math.floor(number / 1000);
      if (thousands === 1) {
        words += "ألف ";
      } else if (thousands === 2) {
        words += "ألفان ";
      } else if (thousands >= 3 && thousands <= 10) {
        words += unitsAr[thousands] + " آلاف ";
      } else {
        words += numberToArabicWords(thousands) + " ألف ";
      }
      number %= 1000;
    }

    if (number > 99) {
      words += hundredsAr[Math.floor(number / 100)] + " ";
      number %= 100;
    }
    if (number > 10 && number < 20) {
      words += teensAr[number - 10] + " ";
    } else {
      if (Math.floor(number / 10) > 0) {
        words += tensAr[Math.floor(number / 10)] + " ";
      }
      if (number % 10 > 0) {
        words += unitsAr[number % 10] + " ";
      }
    }
    return words.trim();
  }

  function convertNumberToWords(amount) {
    if (amount < -999999.999 || amount > 999999.999) {
      throw new Error(
        "Le montant doit être compris entre -999999.999 et 999999.999"
      );
    }

    amount = Math.abs(amount); // Rendre le montant positif

    const integerPart = Math.floor(amount);
    const decimalPart = Math.round((amount - integerPart) * 1000);

    let amountInWords = "";
    let lang = i18n.language;

    switch (lang) {
      case "fr":
        amountInWords = `${numberToFrenchWords(
          integerPart
        )} dinars et ${numberToFrenchWords(decimalPart)} millimes`;
        break;
      case "en":
        amountInWords = `${numberToEnglishWords(
          integerPart
        )} dinars and ${numberToEnglishWords(decimalPart)} millimes`;
        break;
      case "ar":
        amountInWords = `${numberToArabicWords(
          integerPart
        )} دينار و ${numberToArabicWords(decimalPart)} مليم`;
        break;
      default:
        throw new Error('Langue non supportée. Utilisez "fr", "en" ou "ar".');
    }

    return amountInWords;
  }

  const handlePrint = () => {
    if (componentRef.current) {
      window.print();
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div
      className="supplier-quotation"
      style={{
        width: "200mm",
        height: "297mm",
        margin: "auto",
      }}
    >
      <div style={{ margin: "15px" }}>
        <div
          style={{
            fontSize: "24px",
            fontWeight: 500,
            textAlign: "right",
            color: "rgb(102, 102, 102)",
            lineHeight: "31.2px",
            fontFamily: "Helvetica Neue",
            textRendering: "optimizeLegibility",
            textSizeAdjust: "100%",
            textTransform: "uppercase",
            marginTop: "85px",
            marginRight: "20px",
          }}
        >
          {t("buying:PurchaseReceipt")}
        </div>
      </div>
      <Divider orientation="horizontal" fullWidth />

      <div style={{ marginTop: "25px", marginLeft: "20px", width: "100%" }}>
        <div>
          <span style={{ fontSize: "15px", fontWeight: "bold" }}>
            {t("common:name")} :
          </span>
          <span style={{ marginLeft: 50 }}>{supplierSelected}</span>
        </div>
        <br />
        <br />
        <div>
          <span style={{ fontSize: "15px", fontWeight: "bold" }}>
            {t("common:date")} :
          </span>
          <span style={{ marginLeft: 50 }}>{id.posting_date}</span>
        </div>
        <br />
        <br />
        <div>
          <span style={{ fontSize: "14px", fontWeight: "bold" }}>
            {t("common:addr")} :
          </span>{" "}
          <span style={{ marginLeft: 30 }}> {addressSelected}</span>
        </div>
        <br />
        <br />
        <div>
          <span style={{ fontSize: "14px", fontWeight: "bold" }}>
            {t("common:contact")} :
          </span>{" "}
          <span style={{ marginLeft: 30 }}>{contactSelected}</span>
        </div>
        <br />
        <br />
        <div>
          <span style={{ fontSize: "14px", fontWeight: "bold" }}>
            {t("common:phone")} :
          </span>
          <span style={{ marginLeft: 30 }}>{supplierList[0]?.mobile_no}</span>
        </div>
        <br />
        <br />
      </div>
      <div
        className="items"
        style={{
          marginTop: "40px",
          marginLeft: "20px",
          marginRight: "40px",
          width: "95%",
        }}
      >
        <table style={{ borderCollapse: "collapse" }}>
          <thead style={{ backgroundColor: "#eeeeee", color: "black" }}>
            <tr>
              <th style={{ border: "1px solid black" }}>Sr</th>
              <th style={{ border: "1px solid black" }}>
                {t("common:barcode")}
              </th>
              <th style={{ border: "1px solid black" }}>
                {t("product:itemcode")}
              </th>
              <th style={{ border: "1px solid black" }}>
                {t("common:Description")}
              </th>
              <th style={{ border: "1px solid black" }}>Quantité Acceptée</th>
              <th style={{ border: "1px solid black" }}>
                {t("common:remise")}
              </th>
              <th style={{ border: "1px solid black" }}>
                Taux avec marge (devise de l'entreprise)
              </th>
              <th style={{ border: "1px solid black" }}>{t("common:taux")}</th>
              <th style={{ border: "1px solid black" }}>
                {t("common:montant")}
              </th>
              <th style={{ border: "1px solid black" }}>Rate of Stock UOM</th>
              <th style={{ border: "1px solid black" }}>Compte de dépense</th>
            </tr>
          </thead>
          <tbody>
            {state.items.map(
              (item, index) => (
                (
                  <tr key={index}>
                    <td
                      style={{ border: "1px solid black", textAlign: "center" }}
                    >
                      {index + 1}
                    </td>
                    <td
                      style={{ border: "1px solid black", textAlign: "center" }}
                    >
                      {item.item_code}
                    </td>
                    <td
                      style={{ border: "1px solid black", textAlign: "center" }}
                    >
                      {item.item_code}
                    </td>
                    <td
                      style={{ border: "1px solid black", textAlign: "center" }}
                    >
                      {item.item_name}
                    </td>
                    <td
                      style={{ border: "1px solid black", textAlign: "center" }}
                    >
                      {item.qty}
                    </td>
                    <td
                      style={{ border: "1px solid black", textAlign: "center" }}
                    >
                      {item.amount - item.base_net_amount}
                    </td>
                    <td
                      style={{ border: "1px solid black", textAlign: "center" }}
                    >
                      {item.rate + index}
                    </td>
                    <td
                      style={{ border: "1px solid black", textAlign: "center" }}
                    >
                      {item.rate}
                    </td>
                    <td
                      style={{ border: "1px solid black", textAlign: "center" }}
                    >
                      {item.amount}
                    </td>
                    <td
                      style={{ border: "1px solid black", textAlign: "center" }}
                    >
                      {item.stock_uom}
                    </td>
                    <td
                      style={{ border: "1px solid black", textAlign: "center" }}
                    >
                      {item.expense_account}
                    </td>
                  </tr>
                )
              )
            )}
          </tbody>
        </table>
      </div>
      <div style={{ marginLeft: "10px" }}>
        <div
          className="grand-total"
          style={{ fontSize: "14px", fontWeight: "bold" }}
        >
          <div style={{ textDecoration: "underline" }}>
            {t("common:qtyTotal")} :
          </div>
          <div>{state.items.reduce((total, item) => total + item.qty, 0)}</div>
        </div>
        <div
          className="grand-total"
          style={{ fontSize: "14px", fontWeight: "bold" }}
        >
          <div style={{ textDecoration: "underline" }}>
            {t("common:grandTotal")} :
          </div>
          <div>
            {id.grand_total.toFixed(3)}
            &nbsp;
            {i18n.language === "fr" || i18n.language === "en" ? "TND" : "دينار"}
          </div>
        </div>
        <div className="grand-total">
          <div
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            Montant en Lettres :
          </div>
          <div style={{ fontSize: "13px", fontWeight: "bold" }}>
            {id.grand_total !== 0
              ? convertNumberToWords(id.grand_total.toFixed(3))
              : i18n.language === "fr"
              ? "Zéro Dinars"
              : i18n.language === "en"
              ? "Zero Dinars"
              : "صفر دينار"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecapPurchaseReceipt;
