import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import {
  Typography,
  Grid,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Button,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useSelector } from "react-redux";
import { useStyles } from "./style";
import { useTranslation } from "react-i18next";
import { GlobalfilterData } from "../../../../helpers/helper";
import Pagination from "@material-ui/lab/Pagination";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Box display="flex" flexWrap="wrap">
            {children}
          </Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

export const ItemCollection = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation(["common"]);
  const [value, setValue] = React.useState(0);
  const [searchProduct, setSearchProduct] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [products, setProducts] = React.useState([]);
  const max_elemnts = 20;
  const filtredProducts = useSelector((state) => state.Product.filtredProducts);
  const itemCollection = useSelector((state) => state.Product.itemCollection);
  const activeImageTheme = useSelector((state) => state.Theme.image);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const imageList = useSelector((state) => state.Product.imagesData);

  const handleChange = (event, newValue) => {
    setPage(1);
    setValue(newValue);
  };

  const handleDetailPage = (product) => {
    history.push(`/stock-product/${product.item_code}`);
  };

  function getImgUrl(itemCode) {
    if (imageList && imageList?.itemImages) {
      const Index = imageList?.itemImages?.findIndex(
        (e) => e.itemCode === itemCode
      );
      if (Index !== -1) {
        return (
          console.log(`${process.env.REACT_APP_API_URI}` +
            imageList?.itemImages?.[Index]?.imgUrl),
          `${process.env.REACT_APP_API_URI}` +
          imageList?.itemImages?.[Index]?.imgUrl
        );
      } else {
        return activeImageTheme;
      }
    } else {
      return activeImageTheme;
    }
  }

  const SearchProduct = (e) => {
    setSearchProduct(e.target.value);
    const data = GlobalfilterData(filtredProducts, e.target.value, [
      "name",
      "item_code",
      "item_group",
      "item_name",
      "standard_rate",
      "description",
    ]);
    setProducts(data);
  };
  useEffect(() => {
    setProducts(filtredProducts);
  }, [filtredProducts]);
  return (
    <div className={classes.roott}>
      <Box className={classes.header}>
        <Box className={classes.backBox}>
          <Typography
            align={"center"}
            color={"primary"}
            variant="caption"
            className={classes.backLink}
            onClick={() => history.goBack()}
          >
            <ArrowBackIcon />
            {t("common:back")}
          </Typography>
        </Box>
        <Box>
          <Grid container spacing={2}>
            <Grid item></Grid>
            <Grid item>
              <FormControl className={classes.formControl} variant="outlined">
                <OutlinedInput
                  id="outlined-adornment-weight"
                  value={searchProduct}
                  placeholder={t("common:rechercher")}
                  onChange={SearchProduct}
                  endAdornment={
                    <InputAdornment position="end">
                      <span className="icon-search" />
                    </InputAdornment>
                  }
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    "aria-label": "weight",
                  }}
                  labelWidth={0}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box mt={3}>
        <Box position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            {itemCollection.map((c, index) => (
              <Tab
                key={index + 1}
                label={c.collection_name}
                {...a11yProps(0)}
              />
            ))}
          </Tabs>
        </Box>
        {itemCollection.map((c, index) => (
          <>
            <TabPanel value={value} index={index}>
              {products
                .filter((p) => p.item_collection == c.name)
                .slice((page - 1) * max_elemnts, page * max_elemnts)
                .map((prod) => (
                  <Box
                    className={classes.content}
                    onClick={() => handleDetailPage(prod)}
                  >
                    <Box>
                      <img
                        className={classes.defaultImg}
                        src={getImgUrl(prod?.item_code)}
                        alt={"logo"}
                      />
                    </Box>
                    <Box>
                      <Typography
                        className={classes.title}
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        {prod ? prod.item_name : ""}
                      </Typography>
                      <Typography
                        className={classes.price}
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        {prod ? prod.standard_rate.toFixed(3) : ""}{" "}
                        {globalDefaults?.default_currency}
                      </Typography>
                      <Typography
                        style={{ fontWeight: 50 }}
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        {prod ? prod.item_group : ""}
                      </Typography>
                      <Typography
                        className={classes.isstock}
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        {prod
                          ? prod.is_stock_item == 1
                            ? t("common:isStock")
                            : t("common:isNotStock")
                          : ""}
                      </Typography>
                    </Box>
                  </Box>
                ))}
            </TabPanel>
            {index === value &&
              Math.ceil(
                products?.filter((p) => p.item_collection == c.name)?.length /
                  max_elemnts
              ) > 0 && (
                <Box display="flex" justifyContent="center" m={4}>
                  <Pagination
                    onChange={(event, newPage) => setPage(newPage)}
                    page={page}
                    count={Math.ceil(
                      products?.filter((p) => p.item_collection == c.name)
                        ?.length / max_elemnts
                    )}
                    color="primary"
                  />
                </Box>
              )}
          </>
        ))}
      </Box>
    </div>
  );
};
