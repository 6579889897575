import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  FormControl,
  OutlinedInput,
  FormHelperText,
  Typography,
  Button,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { fr } from "date-fns/esm/locale";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import { GlobalfilterData } from "../../../../helpers/helper";
import { formatDate, addDaystoDate } from "../../../../helpers/utils";
import { BasicTable } from "../../../checkout/components/basictable";
import {
  CreateTransferRequest,
  SetTransfertData,
  LoadProductsStock,
  SetProductsStock,
} from "../../../../store/transferRequest/transferRequest.action";
import { RedirectTo } from "./../../../../store/page/page.action";
/* import { LoadProductStockById } from "./../../../../store/product/product.action"; */
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { TransitionAlerts } from "../../../../components/TransitionAlerts";

const useStyles = makeStyles((theme) => ({
  blockTitle: {
    fontSize: 14,
    marginBottom: 8,
    paddingLeft: 10,
  },
  formControl: {
    marginBottom: 15,
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
  },
  paper: {
    paddingLeft: 10,
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
    "& .MuiSelect-root": {
      padding: "0.4938rem 0.875rem",
    },
  },
  label: {
    fontSize: 12,
    color: theme.palette.gray.main,
    paddingLeft: 9,
    marginBottom: 6,
  },
  date: {
    marginBottom: 15,
  },
  formBlock: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
    paddingTop: "20px",
  },
  customButton: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.blue.main,
    border: "1px solid",
    borderColor: theme.palette.blue.main,
    fontSize: "0.875rem",
    borderRadius: 10,
    margin: "0rem 1.375rem",
    padding: "0.1875rem 1.4375rem",
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      borderColor: theme.palette.blue.main,
    },
  },
  cancelButton: {
    color: "#909BAB",
  },
  basictable: {
    height: "255px",
  },
  save: {
    marginTop: "10px",
    marginLeft: 0,
    marginRight: -20,
  },
}));

export const Form = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation(["common", "buying", "checkout"]);
  const stores = useSelector((state) => state.Login.stores);
  const products = useSelector((state) => state.Product.products);
  const productsStock = useSelector((state) => state.Transfert.ProductsStock);
  const Transferts = useSelector((state) => state.Transfert.Transferts);

  const [initialLines, setInitialLines] = useState([0, 1, 2, 3]);
  const [suggestion, setsuggestion] = useState([]);
  const [selectitem, setSelectitem] = useState(false);

  const [searchProduct, setSearchProduct] = useState("");
  const [titlTransfert, setTitlTransfert] = useState("");
  const [alert, setAlert] = React.useState({
    open: false,
    message: "",
    severity: "",
  });
  const dispatch = useDispatch();
  const handleSearchProduct = async (e) => {
    setSearchProduct(e.target.value);
    if (e.target.value == "") {
      setsuggestion([]);
      return;
    }

    const itemstock = products?.filter((s) => s.is_stock_item !== 0);
    const data = await GlobalfilterData(itemstock, e.target.value, [
      "item_code",
      "item_name",
      "item_group",
      "description",
    ]);
    setsuggestion(data);
  };

  const handleAddproduct = async (id) => {
    setSelectitem(true);
    var data = suggestion.find((s) => s.item_name == id.item_name);

    data.doctype = "Material Request Item";
    data.warehouse = Transferts.set_warehouse;
    const ItemIndex = Transferts.items.findIndex((s) => s.item_name == id.item_name);
    if (ItemIndex != -1) {
      Transferts.items[ItemIndex].quantity =
        Transferts.items[ItemIndex].quantity + 1;
      Transferts.items[ItemIndex].qty = Transferts.items[ItemIndex].qty + 1;
      Transferts.items[ItemIndex].stock_qty =
        Transferts.items[ItemIndex].stock_qty + 1;
    } else {
      const res = await LoadProductsStock(data?.item_code);
      const indexstock = res?.data?.message?.findIndex(
        (s) => s.warehouse == Transferts.set_from_warehouse
      );
      if (indexstock != -1) {
        if (res?.data?.message[indexstock]?.actual_qty > 0) {
          data.qty = 1;
          data.quantity = 1;
          data.stock_qty = 1;
          Transferts.items.push(data);
          dispatch(
            SetTransfertData({ ...Transferts, items: Transferts.items })
          );
          dispatch(SetProductsStock([...productsStock, res?.data?.message]));
          initialLines.splice(-1);
        } else {
          setAlert({
            open: true,
            message: t("checkout:stockunavailable"),
            severity: "warning",
          });
        }
      } else {
        setAlert({
          open: true,
          message: t("checkout:itemunavailable"),
          severity: "error",
        });
      }
    }

    setsuggestion([]);
    setSelectitem(false);
    setSearchProduct("");
  };

  const HandleQuantityChange = (onAdd, id) => {
    const ItemIndex = Transferts.items.findIndex((s) => s.item_code == id);
    const data = [...Transferts.items];
    if (ItemIndex != -1) {
      data[ItemIndex].quantity = onAdd
        ? parseInt(data[ItemIndex].quantity) + 1
        : Math.sign(data[ItemIndex].quantity - 1) == 1
        ? data[ItemIndex].quantity - 1
        : 0;
      data[ItemIndex].qty = data[ItemIndex].quantity;
      data[ItemIndex].stock_qty = data[ItemIndex].quantity;
      dispatch(SetTransfertData({ ...Transferts, items: data }));
    }
  };

  const HandleDelete = (id) => {
    const data = Transferts.items.filter((s) => s.item_code !== id);
    dispatch(SetTransfertData({ ...Transferts, items: data }));
    deleteproductstock(id);
  };
  const deleteproductstock = (id) => {
    var tabProdectStock = [];
    productsStock?.forEach((element) => {
      const elementProdectStock = element.filter((s) => s.item_code !== id);
      if (elementProdectStock?.length > 0)
        tabProdectStock.push(elementProdectStock);
    });
    dispatch(SetProductsStock(tabProdectStock));
  };
  const [error, setError] = React.useState({
    set_warehouse: false,
    items: false,
    schedule_date: false,
    set_from_warehouse: false,
  });
  const HandleUpdateQuantity = (e, id) => {
    const ItemIndex = Transferts.items.findIndex((s) => s.item_code == id);
    const data = [...Transferts.items];
    if (ItemIndex != -1) {
      data[ItemIndex].quantity = e.target.value;
      data[ItemIndex].qty = e.target.value;
      data[ItemIndex].stock_qty = e.target.value;
      dispatch(SetTransfertData({ ...Transferts, items: data }));
    }
  };

  const handleDateChange = (date, event) => {
    isNaN(Date.parse(date))
      ? setError({ ...error, schedule_date: false })
      : dispatch(
          SetTransfertData({
            ...Transferts,
            schedule_date: date.toISOString().slice(0, 10),
          })
        );
  };

  const handleChange = async (event) => {
    if (event.target.name == "set_from_warehouse") {
      var tabitem = Transferts.items;
      productsStock?.forEach((element) => {
        const item = element?.filter(
          (s) => s?.warehouse == event.target.value && s?.actual_qty > 0
        );
        if (!item?.length > 0) {
          deleteproductstock(element[0]?.item_code);
          tabitem = tabitem.filter(
            (s) => s.item_code !== element[0]?.item_code
          );
        }
      });
      dispatch(
        SetTransfertData({
          ...Transferts,
          [event.target.name]: event.target.value,
          items: tabitem,
        })
      );
    } else {
      await dispatch(
        SetTransfertData({
          ...Transferts,
          [event.target.name]: event.target.value,
        })
      );
      setError({ ...error, [event.target.name]: false });
      if (event.target.name == "set_warehouse") {
        updateWarehouse(event.target.value);
      }
    }
  };

  const updateWarehouse = (value) => {
    Transferts.items.map((item) => {
      item.warehouse = value;
    });
  };

  const handleCancel = () => {
    dispatch(
      SetTransfertData({
        set_warehouse: "",
        set_from_warehouse: "",
        items: [],
        schedule_date: formatDate(new Date(), "YYYY-MM-DD"),
      })
    );
    dispatch(SetProductsStock([]));
  };

  const handleSubmit = async (event) => {
    const var_error = {
      set_warehouse:
        Transferts.set_warehouse == "" ||
        Transferts.set_warehouse == Transferts.set_from_warehouse,
      set_from_warehouse:
        Transferts.set_from_warehouse == "" ||
        Transferts.set_warehouse == Transferts.set_from_warehouse,
      schedule_date: Transferts.schedule_date == "",
      items: Transferts.items.length < 1,
    };
    setError(var_error);
    if (JSON.stringify(var_error).indexOf("true") < 0) {
      // Transferts.title = (titlTransfert && titlTransfert.trim() !== '') && titlTransfert
      await dispatch(CreateTransferRequest(Transferts));
      handleCancel();
    }
  };
  useEffect(() => {
    dispatch(RedirectTo(null));
  }, []);

  useEffect(() => {
    var initLines = [];
    if (Transferts?.items.length >= 4) {
      setInitialLines([]);
    } else {
      for (let i = 0; i < 4 - Transferts?.items.length; i++) {
        initLines.push(i);
      }
      setInitialLines(initLines);
    }
  }, [Transferts?.items]);
  return (
    <Box className={classes.paper}>
      <Box className={classes.formBlock}>
        <Grid container spacing={3}>
          <Grid item xs>
            <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
              <InputLabel required className={classes.label}>
                {t("buying:RequiredOn")}
              </InputLabel>
              <KeyboardDatePicker
                className={classes.date}
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                name="schedule_date"
                inputVariant="outlined"
                id="date-picker-inline"
                value={Transferts.schedule_date}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                minDate={formatDate(new Date(), "YYYY-MM-DD")}
                minDateMessage={t("common:minDateMessage")}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          {/* <Grid item xs={6}>
            <InputLabel className={classes.label} >{"titre"} :</InputLabel>
            <FormControl className={classes.packControl} variant="outlined">
              <OutlinedInput
                id="outlined-adornment-weight"
                placeholder={"titre"}
                name='titre'
                value={titlTransfert}
                onChange={(e) => {
                    handleChange(e); 
                  setTitlTransfert(e?.target?.value);
                }}
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
                labelWidth={0}
              />
              <FormHelperText style={{ fontSize: '9px' }} variant='outlined' id="accountId-error">
                <InfoOutlinedIcon style={{ color: '#9EC9CF' }}/>{"messagehelpe"}
              </FormHelperText>

            </FormControl>

          </Grid> */}
          <Grid item xs>
            <InputLabel
              error={error.set_from_warehouse}
              required
              className={classes.label}
            >
              {t("common:mgsSource")}
            </InputLabel>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                className={classes.select}
                value={Transferts.set_from_warehouse}
                error={error.set_from_warehouse}
                IconComponent={ExpandMore}
                onChange={handleChange}
                name="set_from_warehouse"
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {Array.isArray(stores) &&
                  stores.map((row, index) => (
                    <MenuItem value={row.name} key={row.name}>
                      {row.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs>
            <InputLabel
              error={error.set_warehouse}
              required
              className={classes.label}
            >
              {t("common:mgsCible")}
            </InputLabel>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                className={classes.select}
                value={Transferts.set_warehouse}
                error={error.set_warehouse}
                IconComponent={ExpandMore}
                onChange={handleChange}
                name="set_warehouse"
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {Array.isArray(stores) &&
                  stores.map((row, index) => (
                    <MenuItem value={row.name} key={row.name}>
                      {row.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Typography
        className={classes.blockTitle}
        color={error.items ? "error" : "primary"}
        variant="caption"
        display="block"
      >
        {t("common:article")} *
      </Typography>
      <div data-grid={{ w: 9, h: 3, x: 0, y: 0, minW: 4, minH: 3 }}>
        <Box justifyContent="center" display="flex">
          <TransitionAlerts
            severity={alert.severity}
            setAlert={setAlert}
            open={alert.open}
            message={alert.message}
            timeout={3000}
          />
        </Box>
        <BasicTable
          selectitem={selectitem}
          suggestion={suggestion}
          initialLines={initialLines}
          rows={Transferts.items}
          HandleQuantityChange={HandleQuantityChange}
          HandleDelete={HandleDelete}
          handleSearchProduct={handleSearchProduct}
          handleAddproduct={handleAddproduct}
          searchProduct={searchProduct}
          transfer={true}
          stockEntry={true}
          HandleUpdateQuantity={HandleUpdateQuantity}
          setInitialLines={setInitialLines}
          isMessageStock={true}
        />
      </div>
      <Box display="flex" justifyContent="flex-end" className={classes.save}>
        <Button
          className={classes.cancelButton}
          /*component={Link} to={"/transfer-requests"}*/ onClick={handleCancel}
        >
          {" "}
          {t("common:annuler")}
        </Button>
        <Button
          onClick={handleSubmit}
          className={classes.customButton}
          size={"small"}
          variant="outlined"
          color="primary"
        >
          {t("common:save")}
        </Button>
      </Box>
    </Box>
  );
};
