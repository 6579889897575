import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { removeHTML } from "../../../../helpers/utils";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  main: {
    padding: "0px 50px",
    fontSize: 12,
  },
  header: {
    padding: "60px 0",
    marginBottom: 60,
  },
  logo: {
    textAlign: "center",
    marginBottom: 10,
  },
  title: {
    borderTop: "1px solid  #5D6975",
    borderBottom: "1px solid  #5D6975",
    color: "#5D6975",
    fontSize: "30px",
    lineHeight: "1.4em",
    fontWeight: "normal",
    textAlign: "center",
    margin: "0 0 20px 0",
  },
  project: {
    float: "left",
    marginTop: 30,
    marginBottom: 60,
  },
  table: {
    width: "100%",
    marginTop: -60,
  },
  tableheader: {
    padding: "5px 5px",
    background: "#E5E5E5",
    border: "1px solid #C1CED9",
    fontWeight: "normal",
  },
  tableColumn: {
    padding: 10,
    textAlign: "right",
    border: "1px solid #C1CED9",
  },
  ref: {
    width: "60%",
    marginTop: 5,
  },
  headtable: {
    border: " 1px solid #C1CED9 ",
    background: "#E5E5E5",
    fontWeight: "normal",
  },
  columntable: {
    padding: 10,
    textAlign: "center",
    border: "1px solid #C1CED9",
  },
  pay: {
    width: "60%",
    display: "inline-table",
    tableLayout: "fixed",
  },
  comp: {
    width: "80%",
  },
  address: {
    paddingTop: "40px",
  },
  item: {
    color: "#5D6975",
    textAlign: "left",
    width: "52px",
    fontSize: "0.8em",
  },
  company: {
    float: "left",
    marginTop: 30,
    marginBottom: 60,
  },
  float: {
    width: "40%",
    height: "50%",
    marginRight: -60,
    textAlign: "left",
  },
}));

export const PurchaseOrderPrint = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const { t } = useTranslation(["common", "buying"]);
  const activeImageTheme = useSelector((state) => state.Theme.image);

  return (
    <div className={classes.main} ref={ref}>
      <header className={classes.header}>
        <div className={classes.logo}>
          <img src={activeImageTheme} />
        </div>
        <h1 className={classes.title}>
          {props?.title + " " + props?.details?.name}{" "}
        </h1>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <div>
              {t("buying:SupplierName")}: {props?.details?.supplier_name}
            </div>
            <div>
              {t("buying:contactName")}: {props?.details?.supplier}
            </div>
          </Grid>
          <Grid item xs={6}>
            <div>
              {t("common:createDate")}:{props?.details?.transaction_date}
            </div>
            <div>
              {t("buying:RequiredOn")}:{props?.details?.schedule_date}
            </div>
          </Grid>
        </Grid>
      </header>
      <table className={classes.table}>
        <thead>
          <tr>
            <th className={classes.tableheader}>{t("common:code")}</th>
            <th className={classes.tableheader}>{t("Libellé du produit")}</th>
            <th className={classes.tableheader}>{t("common:Description")}</th>
            <th className={classes.tableheader}>Prix Unitaire</th>
            <th className={classes.tableheader}>{t("common:remise")}</th>
            <th className={classes.tableheader}>{t("common:qty")}</th>
            <th className={classes.tableheader}>{t("common:total")}</th>
          </tr>
        </thead>
        <tbody>
          {props?.details &&
            props?.details?.items?.map((p, index) => (
              <tr key={index}>
                <td
                  className={classes.tableColumn}
                  style={{ textAlign: "left" }}
                >
                  {p.item_code}
                </td>
                <td
                  className={classes.tableColumn}
                  style={{ textAlign: "left" }}
                >
                  {p.item_name}
                </td>
                <td
                  className={classes.tableColumn}
                  style={{ textAlign: "left" }}
                >
                  {removeHTML(p.description)}
                </td>
                <td
                  className={classes.tableColumn}
                  style={{ textAlign: "left" }}
                >
                  {p?.price_list_rate?.toFixed(3)} {props?.details?.currency}
                </td>
                <td
                  className={classes.tableColumn}
                  style={{ textAlign: "left" }}
                >
                  {(p.amount * props?.details?.additional_discount_percentage / 100).toFixed(3)}{" "}
                  {props?.details?.currency}
                </td>
                <td
                  className={classes.tableColumn}
                  style={{ textAlign: "left" }}
                >
                  {p?.qty}
                </td>
                <td
                  className={classes.tableColumn}
                  style={{ textAlign: "left" }}
                >
                  {p?.amount?.toFixed(3)} {props?.details?.currency}
                </td>
              
              </tr>
            ))}
          <tr>
            <td className={classes.tableColumn} colSpan="7">
              {t("common:totalHT")}
            </td>
            <td
              className={classes.tableColumn}
              style={{ textAlign: "left" }}
              colSpan="2"
            >
              {props?.details?.net_total.toFixed(3)} {props?.details?.currency}
            </td>
          </tr>
          <tr>
            <td className={classes.tableColumn} colSpan="7">
              {t("common:totalTaxe")}
            </td>
            <td
              className={classes.tableColumn}
              style={{ textAlign: "left" }}
              colSpan="2"
            >
              {props?.details?.total_taxes_and_charges.toFixed(3)}{" "}
              {props?.details?.currency}
            </td>
          </tr>
          <tr>
            <td className={classes.tableColumn} colSpan="7">
              {t("common:remise")} {t("common:total")}
            </td>
            <td
              className={classes.tableColumn}
              style={{ textAlign: "left" }}
              colSpan="2"
            >
              {props?.details?.discount_amount.toFixed(3)}{" "}
              {props?.details?.currency}
            </td>
          </tr>
          <tr>
            <td className={classes.tableColumn} colSpan="7">
              {t("common:grandTotal")}
            </td>
            <td
              className={classes.tableColumn}
              style={{ textAlign: "left" }}
              colSpan="2"
            >
              {props?.details?.grand_total.toFixed(3)}{" "}
              {props?.details?.currency}
            </td>
          </tr>
          <tr>
            <td className={classes.tableColumn} colSpan="7">
              {t("common:qtyTotal")}
            </td>
            <td
              className={classes.tableColumn}
              style={{ textAlign: "left" }}
              colSpan="2"
            >
              {props?.details?.total_qty}
            </td>
          </tr>
        </tbody>
      </table>

      <div className={classes.company}>
        <div textAlign={"left"} fontWeight={"bold"} fontSize={"12px"}>
          {t("common:Signature")}:
        </div>
        <div>
          {props?.stockistSignature !=
          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAASwAAACWCAYAAABkW7XSAAAAAXNSR0IArs4c6QAABGJJREFUeF7t1AEJAAAMAsHZv/RyPNwSyDncOQIECEQEFskpJgECBM5geQICBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAAYPlBwgQyAgYrExVghIgYLD8AAECGQGDlalKUAIEDJYfIEAgI2CwMlUJSoCAwfIDBAhkBAxWpipBCRAwWH6AAIGMgMHKVCUoAQIGyw8QIJARMFiZqgQlQMBg+QECBDICBitTlaAECBgsP0CAQEbAYGWqEpQAgQdWMQCX4yW9owAAAABJRU5ErkJggg==" ? (
            <img
              className={classes.float}
              alt="signature"
              src={props?.stockistSignature}
            />
          ) : (
            <img
              className={classes.float}
              alt="signature"
              src={props?.details?.signature}
            />
          )}
        </div>
      </div>
    </div>
  );
});
