import moment from "moment";
import {
  PAYMENTS_METHODS_LOADED,
  UPDATE_PAYMENT,
  UPDATE_PRODUCTS,
  CREATE_ORDER,
  CREATE_ORDER_SUCCESS,
  UPDATE_SELECTED_CLIENT,
  CREATE_DELIVERY_DOCUMENT,
  CREATE_DOCUMENT_SUCCESS,
  ORDERS_LOADED,
  SET_DOCUMENT_PAYMENT,
  SET_DOCUMENT_DELIVERY,
  SET_DOCUMENT_INVOICE,
  Order_LODED,
  CREATE_INVOICE_DOCUMENT,
  CREATE_PAYMENT_DOCUMENT,
  DOCUMENT_NUMBER_LODED,
  SET_DISCOUNT_LODED,
  CANCEL_CHECKOUT,
  SET_HOLD_DATA,
  SET_COUPON_CODE,
  SET_PRICING_RULES,
  SET_DEFAULT_TAX,
  UPDATE_CURRENCY_RATE,
  LOADING_CURRENCY_RATE,
  SALES_PERSON_LOADED,
  UPDATE_SELECTED_SALESPERSON,
  SET_DEFAULT_CLIENT,
  SEND_EMAIL,
  CREATE_EMAIL_SUCCESS,
  SET_DETAIL_CLIENT,
  LOAD_ITEM_DETAILS,
  ITEM_DETAILS_LOADED,
  CALCULATOR_TOGGLED,
  GET_RECENT_ORDERS,
  RECENT_ORDERS_LOADED,
  CREATE_SALES_PERSON,
  CREATE_SALES_PERSON_SUCCESS,
  CUSTOM_REF_LOADED,
  RETURN_LOADED,
  LOAD_RETURN,
  UPDATE_RETURNS,
  CREATE_RETURN_SUCCESS,
  CREATE_RETURN,
  SET_RATE,
  SELECT_CURRENCY,
  SELECT_CLIENT_INFO,
  CREATE_CREDIT_NOTE_SUCCESS,
  CREATE_CREDIT_NOTE,
  CANCEL_CREDIT_NOTE,
  LOADING_CREDIT_NOTES,
  CREDIT_NOTES_LOADED,
  CREATE_PRICING_RULE_SUCCESS,
  CREATE_PRICING_RULE,
  CANCEL_PRICING_RULE,
  CREATE_COUPON_CODE,
  CREATE_COUPON_CODE_SUCCESS,
  SET_GIFT_CARD_COUPON_CODE,
  SET_RETURN,
  SET_POS_2_PRINT,
  CREATE_AVOIR_ENTRY,
  AVOIR_ENTRY_CREATED,
  SET_AVOIR_2_PRINT,
  AVOIR_LIST_LOADED,
  LOADING_AVOIR_LIST,
  SET_TAX_SALES,
  SET_TPE_TICKET_NUMBER,
  SET_SELECTED_ITEMS_LODED,
  IGNORE_PRICING_RULES,
  UPDATE_ITEM_DETAILS,
  SET_DISCOUNT_AMOUNT_PR,
  SAVE_CHEQUE_BULK,
  SAVE_CHEQUE_BULK_SUCCESS,
  SEND_RESERVED_COMMAND_EMAIL,
  CREATE_RESERVED_COMMAND_EMAIL_SUCCESS,
} from "./checkout.type";
import { FAILED_ADD_DATA } from "../rollback/rollback.type";
import { SetNotification } from "../notification/notification.action";
import { LoadPosProducts } from "../product/product.action";
import axiosInstance from "../../history/axiosInstance";
import {
  SecondLoadDetailClient,
  SecondSetDetailClient,
} from "../client/client.action";
import {
  UpdatePaymentStatusHoldById,
  deleteHoldById,
  loadHolds,
} from "../hold/hold.action";

export const LoadPayments = (payements) => {
  return {
    type: PAYMENTS_METHODS_LOADED,
    payements: payements,
  };
};

export const UpdatePayment = (amount) => {
  return {
    type: UPDATE_PAYMENT,
    amount: amount,
  };
};
export const UpdateProducts = (products) => {
  return {
    type: UPDATE_PRODUCTS,
    products: products,
  };
};

export const UpdateClient = (client) => {
  return {
    type: UPDATE_SELECTED_CLIENT,
    client: client,
  };
};

export const LoadOrders = (orders) => {
  return {
    type: ORDERS_LOADED,
    orders: orders,
  };
};
export const SetTPEticketNumber = (ticketNumber) => {
  return {
    type: SET_TPE_TICKET_NUMBER,
    payload: ticketNumber,
  };
};
export const CreateOrderV0 = (content, default_currency, cheque) => {
  const action = {
    type: CREATE_ORDER,
    payload: content,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${process.env.REACT_APP_API_URI}/api/orders/create`,
          method: "POST",
          body: JSON.stringify({
            action: "Submit",
            doc: {
              doctype: "Sales Order",
              docstatus: 0,
              __islocal: 1,
              __unsaved: 1,
             // owner: "bks.hassen@gmail.com",
              naming_series: "SAL-ORD-.YYYY.-",
              order_type: "Sales",
              status: "Closed",
              delivery_status: "Not Delivered",
              billing_status: "Not Billed",
              currency: `${default_currency}`,
              price_list_currency: `${default_currency}`,
              transaction_date: moment().format("YYYY-MM-DD"),
              delivery_date: moment().format("YYYY-MM-DD"),
              qr_code :"test",
              ...content,
            },
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user")).token,
          },
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: CREATE_ORDER_SUCCESS,
          meta: {
            notification: {
              code: "success",
              message: "notification:posInvCreated",
            },
          },
        },
        rollback: { type: FAILED_ADD_DATA },
      },
    },
  };
  action.meta.offline.rollback.meta = action;
  return (dispatch, getState) => {
    dispatch(action);
  };
};

export const CreateOrder = (order, holdData) => {
 
console.log("order",order);
  const action = {
    type: CREATE_ORDER,
    payload: order?.data,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${process.env.REACT_APP_API_URI}/api/orders/create`,
          method: "POST",
          body: JSON.stringify({
            action: "Submit",
            doc: {
              doctype: "POS Invoice",
              docstatus: 0,
              __islocal: 1,
              __unsaved: 1,
              naming_series: "ACC-PSINV-.YYYY.-",
              order_type: "Sales",
              delivery_status: "Not Delivered",
              billing_status: "Not Billed",
              price_list_currency: order?.default_currency,
              is_pos: 1,
              is_return: 0,
              update_billed_amount_in_sales_order: 0,
              company: order?.default_company,
              set_posting_time: 0,
              update_stock: 1,
              apply_discount_on: "Grand Total",
              allocate_advances_automatically: 0,
              write_off_outstanding_amount_automatically: 0,
              group_same_items: 0,
              customer_group: order?.customerGroupParent,
              qr_code :"",
              status :"Paid",
              debit_to: "Debtors - css",
              ...order.data,
            },
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user")).token,
          },
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: CREATE_ORDER_SUCCESS,
          meta: {
            notification: {
              code: "success",
              message: "notification:posInvCreated",
            },
            then: (data) => (dispatch, getState) => {
              if (holdData?.amount?.payments.length > 0) {
                dispatch(
                  UpdatePaymentStatusHoldById(
                    JSON.stringify({
                      id: holdData.id,
                      payment_status: "paid",
                    })
                  )
                );
              }
              const document = JSON.parse(data)?.docs?.[0];

              // handling cheques
              if (
                order?.chequeData?.cheques &&
                order?.chequeData?.cheques?.length > 0
              ) {
                const tabcheque = [];
                order?.chequeData?.cheques?.forEach((element) => {
                  tabcheque.push({
                    ...element,
                    pos_invoice: document?.name,
                  });
                });
                dispatch(
                  storebulkCheque({
                    ...order?.chequeData,
                    cheques: tabcheque,
                  })
                );
              }

              // Handle reload customer details
              if (document?.redeem_loyalty_points === 1) {
                dispatch(SecondLoadDetailClient(document?.customer));
              }

              // Handle refetch items details (stock level)
              const header = {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
                  Authorization: JSON.parse(localStorage.getItem("user")).token,
                },
              };
              const doc = {
                pos_profile: document?.pos_profile,
                price_list: document?.selling_price_list,
                item_group: order?.itemGroup,
                page_length: 500,
                start: 0,
              };

              axiosInstance
                .post(
                  `${process.env.REACT_APP_API_URI}/api/pos/products`,
                  doc,
                  header
                )
                .then((response) => {
                  dispatch(LoadPosProducts(response?.data?.message?.items));
                })
                .catch((error) => {});
            },
          },
        },
        rollback: { type: FAILED_ADD_DATA },
      },
    },
  };
  action.meta.offline.rollback.meta = action;
  return (dispatch, getState) => {
    dispatch(action);
  };
};

export const CreateEmail = (contact_email, message, content, theme_id) => {
  const action = {
    type: SEND_EMAIL,
    payload: content,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${process.env.REACT_APP_API_URI}/api/email/create`,
          method: "POST",
          body: JSON.stringify({
            contact_email: contact_email,
            message: message,
            content: content,
            theme_id: theme_id,
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user")).token,
          },
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: CREATE_EMAIL_SUCCESS,
          meta: {
            notification: {
              code: "success",
              message: "notification:emailSent",
            },
          },
        },
        rollback: { type: FAILED_ADD_DATA },
      },
    },
  };
  action.meta.offline.rollback.meta = action;
  return (dispatch, getState) => {
    dispatch(action);
  };
};

export const sendReservedCommandEmail = (email_id, content, theme_id) => {
  const action = {
    type: SEND_RESERVED_COMMAND_EMAIL,
    payload: content,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${process.env.REACT_APP_API_URI}/api/email/send`,
          method: "POST",
          body: JSON.stringify({
            email_id: email_id,
            content: content,
            theme_id: theme_id,
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user")).token,
          },
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: CREATE_RESERVED_COMMAND_EMAIL_SUCCESS,
          meta: {
            notification: {
              code: "success",
              message: "notification:emailSent",
            },
          },
        },
        rollback: { type: FAILED_ADD_DATA },
      },
    },
  };
  action.meta.offline.rollback.meta = action;
  return (dispatch, getState) => {
    dispatch(action);
  };
};
export const LoadOrderById = (id) => {
  return (dispatch) => {
    const header = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    };
    return axiosInstance
      .get(
        `${process.env.REACT_APP_API_URI}/api/details?doctype=Sales%20Order&name=${id}`,
        header
      )
      .then(({ data }) => {
        dispatch(updateorder(data));
      });
  };
};

export const LoadMappedDocument = (id, doctype) => {
  return (dispatch) => {
    const data = {
      method: doctype,
      source_name: id,
    };
    const header = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    };
    return axiosInstance
      .post(
        `${process.env.REACT_APP_API_URI}/api/make-mapped-doc`,
        data,
        header
      )
      .then(({ data }) => {
        if (doctype.indexOf("make_delivery_note") !== -1) {
          dispatch(SetDocument(SET_DOCUMENT_DELIVERY, data));
        } else {
          dispatch(SetDocument(SET_DOCUMENT_INVOICE, data));
        }
      });
  };
};

export const LoadMappedPaymentDocument = (id, doctype) => {
  return (dispatch) => {
    const data = {
      dt: doctype,
      dn: id,
    };
    const header = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    };
    return axiosInstance
      .post(
        `${process.env.REACT_APP_API_URI}/api/document/make-mapped-payment`,
        data,
        header
      )
      .then(({ data }) => {
        dispatch(SetDocument(SET_DOCUMENT_PAYMENT, data));
      });
  };
};

export function updateorder(order) {
  return (dispatch, getState) => {
    dispatch({
      type: Order_LODED,
      order: order,
    });
  };
}

export function SetDocument(type, doc) {
  return (dispatch, getState) => {
    dispatch({
      type: type,
      document: doc,
    });
  };
}

export const CreateDeliveryDocument = (content) => {
  const action = {
    type: CREATE_DELIVERY_DOCUMENT,
    payload: content,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${process.env.REACT_APP_API_URI}/api/document/delivery`,
          method: "POST",
          body: JSON.stringify({
            action: "Submit",
            doc: content,
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user")).token,
          },
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: CREATE_DOCUMENT_SUCCESS,
          meta: {
            notification: {
              code: "success",
              message: "notification:docCreated",
            },
          },
        },
        rollback: { type: FAILED_ADD_DATA },
      },
    },
  };
  action.meta.offline.rollback.meta = action;
  return (dispatch, getState) => {
    dispatch(action);
  };
};

export const CreateInvoiceDocument = (content) => {
  const action = {
    type: CREATE_INVOICE_DOCUMENT,
    payload: content,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${process.env.REACT_APP_API_URI}/api/document/invoice`,
          method: "POST",
          body: JSON.stringify({
            action: "Submit",
            doc: content,
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user")).token,
          },
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: CREATE_DOCUMENT_SUCCESS,
          meta: {
            notification: {
              code: "success",
              message: "notification:docCreated",
            },
          },
        },
        rollback: { type: FAILED_ADD_DATA },
      },
    },
  };
  action.meta.offline.rollback.meta = action;
  return (dispatch, getState) => {
    dispatch(action);
  };
};

export const CreatePaymentEntryDocument = (
  content,
  default_cash_account,
  payement_method
) => {
  const action = {
    type: CREATE_PAYMENT_DOCUMENT,
    payload: content,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${process.env.REACT_APP_API_URI}/api/document/payment-entry`,
          method: "POST",
          body: JSON.stringify({
            action: "Submit",
            doc: {
              ...content,
              paid_to: default_cash_account,
              mode_of_payment: payement_method,
            },
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user")).token,
          },
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: CREATE_DOCUMENT_SUCCESS,
          meta: {
            notification: {
              code: "success",
              message: "notification:docCreated",
            },
          },
        },
        rollback: { type: FAILED_ADD_DATA },
      },
    },
  };
  action.meta.offline.rollback.meta = action;
  return (dispatch, getState) => {
    dispatch(action);
  };
};

export const LoadDocumentsNumber = (id) => {
  return (dispatch) => {
    const header = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    };
    return axiosInstance
      .get(
        `${process.env.REACT_APP_API_URI}/api/order/doc-numbers?doctype=Sales%20Order&name=${id}&items=%5B%22Sales%20Invoice%22%2C%22Delivery%20Note%22%2C%22Payment%20Entry%22%5D`,
        header
      )
      .then(({ data }) => {
        dispatch(SetDocumentsNumber(data));
      });
  };
};

export function SetDocumentsNumber(data) {
  return (dispatch, getState) => {
    dispatch({
      type: DOCUMENT_NUMBER_LODED,
      documentNumber: data,
    });
  };
}
export function SetTaxSales(tax) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_TAX_SALES,
      payload: tax,
    });
  };
}

export function SetDiscount(discount) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_DISCOUNT_LODED,
      discount: discount,
    });
  };
}

export function CancelCheckout() {
  return (dispatch, getState) => {
    dispatch({
      type: CANCEL_CHECKOUT,
    });
  };
}

export function SetHoldDataInCheckout(holdData) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_HOLD_DATA,
      holdData: holdData,
    });
  };
}

export const SetCouponCode = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_COUPON_CODE,
      selectedCoupon: data,
    });
  };
};

export const SetPricingRules = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_PRICING_RULES,
      pricingRules: data,
    });
  };
};

export const LoadCouponCodeById = (id) => {
  return (dispatch) => {
    const header = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    };
    return axiosInstance
      .get(
        `${process.env.REACT_APP_API_URI}/api/details?doctype=Coupon%20Code&name=${id}`,
        header
      )
      .then(({ data }) => {
        if (data && data.docs && data.docs[0]) {
          if (
            Date.parse(data.docs[0].valid_upto) >= Date.parse(new Date()) &&
            data.docs[0].maximum_use > data.docs[0].used
          ) {
            dispatch(SetCouponCode(data.docs[0]));
            /*   dispatch(
                SetNotification({
                  code: "success",
                  message: "notification:couponApplied",
                })
              ); */
          } else {
            dispatch(
              SetNotification({
                code: "error",
                message: "notification:couponExpired",
              })
            );
          }
        } else {
          dispatch(
            SetNotification({
              code: "error",
              message: "notification:couponNotExist",
            })
          );
        }
      });
  };
};

export const LoadPricingRuleById = (id) => {
  return (dispatch) => {
    const header = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    };
    return axiosInstance
      .get(
        `${process.env.REACT_APP_API_URI}/api/details?doctype=Pricing%20Rule&name=${id}`,
        header
      )
      .then(({ data }) => {
        dispatch(SetPricingRules(data.docs[0]));
      });
  };
};

export const LoadDefaultTaxValue = (id) => {
  return (dispatch) => {
    const header = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    };
    return axiosInstance
      .get(
        `${process.env.REACT_APP_API_URI}/api/details?doctype=Sales%20Taxes%20and%20Charges%20Template&name=${id}`,
        header
      )
      .then(({ data }) => {
        dispatch(SetDefaultTax(data.docs[0]));
      });
  };
};

export const SetDefaultTax = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_DEFAULT_TAX,
      tax: data,
    });
  };
};

export const LoadSalesPerson = (salesPersonList) => {
  return {
    type: SALES_PERSON_LOADED,
    salesPersonList: salesPersonList,
  };
};

export const UpdateSalesPerson = (selectedSalesPerson) => {
  return {
    type: UPDATE_SELECTED_SALESPERSON,
    selectedSalesPerson: selectedSalesPerson,
  };
};

export const loadCurrencyRate = (currency) => ({
  type: LOADING_CURRENCY_RATE,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/currency-rate`,
        method: "post",
        body: JSON.stringify({
          doctype: "Currency Exchange",
          fields: ["`tabCurrency Exchange`.`exchange_rate`"],
          filters: [["Currency Exchange", "from_currency", "=", currency]],
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("user")).token,
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        },
      },
      // action to dispatch when effect succeeds:
      commit: { type: UPDATE_CURRENCY_RATE, meta: "" },
      // action to dispatch if network action fails permanently:
    },
  },
});

export const setDefaultClient = (client) => {
  return {
    type: SET_DEFAULT_CLIENT,
    client: client,
  };
};

export const LoadDetailClient = (name) => {
  return (dispatch) => {
    const header = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    };
    return axiosInstance
      .get(
        `${process.env.REACT_APP_API_URI}/api/details?doctype=Customer&name=${name}`,
        header
      )
      .then(({ data }) => {
        dispatch(SetDetailClient(data?.docs?.[0]));
        dispatch(SecondSetDetailClient(data?.docs?.[0]));
      });
  };
};

export const SetDetailClient = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_DETAIL_CLIENT,
      detailClient: data,
    });
  };
};

export const UpdateEmailClient = (content) => {
  return (dispatch) => {
    const data = {
      fieldname: "email_id",
      customer: content.customer,
      value: content.value,
    };

    const header = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    };
    return axiosInstance
      .post(`${process.env.REACT_APP_API_URI}/api/update/email`, data, header)
      .then(({ data }) => {
        dispatch(LoadDetailClient(content.customer));
        dispatch(
          SetNotification({
            code: "success",
            message: "notification:emailSent",
          })
        );
      });
  };
};

export const loadItemDetail = (content, doc) => ({
  type: LOAD_ITEM_DETAILS,
  payload: content,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/item/item-details`,
        method: "POST",
        body: JSON.stringify({
          args: content,
          doc: doc,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: ITEM_DETAILS_LOADED,
        meta: "",
      },
      rollback: { type: FAILED_ADD_DATA },
    },
  },
});

export const updateItemDetails = (value) => {
  return {
    type: UPDATE_ITEM_DETAILS,
    itemDetails: value,
  };
};

export const toggleCalculator = (value) => {
  return {
    type: CALCULATOR_TOGGLED,
    showCalculator: value,
  };
};

export const SetCustomRef = (value) => {
  return {
    type: CUSTOM_REF_LOADED,
    customRef: value,
  };
};

export const getRecentOrders = (content) => ({
  type: GET_RECENT_ORDERS,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/pos/recent-orders`,
        method: "post",
        body: content,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("user")).token,
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        },
      },
      // action to dispatch when effect succeeds:
      commit: { type: RECENT_ORDERS_LOADED, meta: "" },
      // action to dispatch if network action fails permanently:
    },
  },
});

export const CreateSalesPerson = (content) => {
  const action = {
    type: CREATE_SALES_PERSON,
    payload: content,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${process.env.REACT_APP_API_URI}/api/create-sales-person`,
          method: "POST",
          body: JSON.stringify({
            doc: content,
            action: "Save",
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user")).token,
          },
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: CREATE_SALES_PERSON_SUCCESS,
          meta: {
            notification: {
              code: "success",
              message: "notification:salesPerAdded",
            },
          },
        },
        rollback: { type: FAILED_ADD_DATA },
      },
    },
  };
  action.meta.offline.rollback.meta = action;
  return (dispatch, getState) => {
    dispatch(action);
  };
};

export const manageReturn = (id) => ({
  type: LOAD_RETURN,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/make-mapped-doc`,
        method: "post",
        body: JSON.stringify({
          method:
            "erpnext.accounts.doctype.pos_invoice.pos_invoice.make_sales_return",
          source_name: id,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("user")).token,
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        },
      },
      // action to dispatch when effect succeeds:
      commit: { type: RETURN_LOADED, meta: "" },
      // action to dispatch if network action fails permanently:
    },
  },
});

export const UpdateReturnInvoice = (returnedInvoice) => {
  return {
    type: UPDATE_RETURNS,
    returnedInvoice: returnedInvoice,
  };
};

export const makeReturn = (content, company) => {
  const action = {
    type: CREATE_RETURN,
    payload: content,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${process.env.REACT_APP_API_URI}/api/pos/create-return`,
          method: "POST",
          body: JSON.stringify({
            action: "Submit",
            doc: content,
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user")).token,
          },
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: CREATE_RETURN_SUCCESS,
          meta: {
            notification: {
              code: "success",
              message: "notification:salesReturnAdded",
            },
            then: (data) => (dispatch, getState) => {
              const document = JSON.parse(data)?.docs[0];
              const doc = {
                doctype: "Avoir Account",
                company: `${company}`,
                customer: document.customer,
                credit_amount: document.grand_total * -1,
                naming_series: "CUS-AVR-.YYYY.-",
                creation_date: document.posting_date,
                return_against: document.return_against,
                return_reason: document?.motif,
              };
              dispatch(CreateAvoirEntry(doc));
            },
          },
        },
        rollback: { type: FAILED_ADD_DATA },
      },
    },
  };
  action.meta.offline.rollback.meta = action;
  return (dispatch, getState) => {
    dispatch(action);
  };
};

export const getRate = (first, second) => {
  return (dispatch) => {
    const header = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    return axiosInstance
      .get(
        `https://free.currconv.com/api/v7/convert?q=${first}_${second}&compact=ultra&apiKey=5a49beefa5e7696bc287`,
        header
      )
      .then((response) => {
        dispatch(SetRate(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const SetRate = (response) => {
  return (dispatch) => {
    dispatch({
      type: SET_RATE,
      exchangeRate: response,
    });
  };
};

export const SetSelectCurrency = (value) => {
  return {
    type: SELECT_CURRENCY,
    selectCurrency: value,
  };
};

export const SetSelectedClient = (value) => {
  return {
    type: SELECT_CLIENT_INFO,
    selectClientInfo: value,
  };
};

export const CreateCreditNote = (content) => {
  const action = {
    type: CREATE_CREDIT_NOTE,
    payload: content,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${process.env.REACT_APP_API_URI}/api/create-credit-note`,
          method: "POST",
          body: JSON.stringify({
            action: "Save",
            doc: content,
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user")).token,
          },
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: CREATE_CREDIT_NOTE_SUCCESS,
          meta: {
            notification: {
              code: "success",
              message: "notification:creditNoteAdded",
            },
          },
        },
        rollback: { type: FAILED_ADD_DATA },
      },
    },
  };
  action.meta.offline.rollback.meta = action;
  return (dispatch, getState) => {
    dispatch(action);
  };
};

export function CancelCreditNote() {
  return (dispatch, getState) => {
    dispatch({
      type: CANCEL_CREDIT_NOTE,
    });
  };
}

export const CreatePricingRules = (content) => {
  const action = {
    type: CREATE_PRICING_RULE,
    payload: content,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${process.env.REACT_APP_API_URI}/api/pricing-rule/create`,
          method: "POST",
          body: JSON.stringify({
            action: "Save",
            doc: {
              doctype: "Pricing Rule",
              docstatus: 0,
              __unsaved: 1,
              __islocal: 1,
              naming_series: content.naming_series,
              price_or_product_discount: content.price_or_product_discount,
              apply_on: content.apply_on,
              coupon_code_based: content.coupon_code_based,
              company: content.company,
              title: content.title,
              rate_or_discount: content.rate_or_discount,
              rate: content.rate,
              currency: content.currency,
              selling: content.selling,
              buying: content.buying,
              ...content,
            },
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user")).token,
          },
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: CREATE_PRICING_RULE_SUCCESS,
          meta: {
            notification: {
              code: "success",
              message: "notification:pricingRuleAdded",
            },
          },
        },
        rollback: { type: FAILED_ADD_DATA },
      },
    },
  };
  action.meta.offline.rollback.meta = action;
  return (dispatch, getState) => {
    dispatch(action);
  };
};

export function CancelPricingRule() {
  return (dispatch, getState) => {
    dispatch({
      type: CANCEL_PRICING_RULE,
    });
  };
}

export const CreateCouponCode = (content) => {
  const action = {
    type: CREATE_COUPON_CODE,
    payload: content,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${process.env.REACT_APP_API_URI}/api/coupon-codes/create`,
          method: "POST",
          body: JSON.stringify({
            action: "Save",
            doc: {
              doctype: "Coupon Code",
              docstatus: 0,
              coupon_type: content.coupon_type,
              coupon_name: content.coupon_name,
              valid_upto: content.valid_upto,
              valid_from: content.valid_from,
              pricing_rule: content.pricing_rule,
              customer: content.customer,
              pos_invoice: content.pos_invoice,
              validity_period: content.validity_period,
              ...content,
            },
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user")).token,
          },
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: CREATE_COUPON_CODE_SUCCESS,
          meta: {
            notification: {
              code: "success",
              message: "notification:couponAdded",
            },
            then: (data) => (dispatch, getState) => {
              dispatch(SetReturn(JSON.parse(data)?.docs[0]));
            },
          },
        },
        rollback: { type: FAILED_ADD_DATA },
      },
    },
  };
  action.meta.offline.rollback.meta = action;
  return (dispatch, getState) => {
    dispatch(action);
  };
};

export const SetReturn = (value) => {
  return {
    type: SET_RETURN,
    creditNoteCoupon: value,
  };
};

export const SetGiftCardCouponCode = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_GIFT_CARD_COUPON_CODE,
      selectedGiftCardCoupon: data,
    });
  };
};

export const LoadPOSInvById = (id) => {
  return (dispatch) => {
    const header = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    };
    return axiosInstance
      .get(
        `${process.env.REACT_APP_API_URI}/api/details?doctype=POS%20Invoice&name=${id}`,
        header
      )
      .then(({ data }) => {
        dispatch(SetPOSInvToPrint(data?.docs?.[0]));
      });
  };
};

export const SetPOSInvToPrint = (value) => {
  return {
    type: SET_POS_2_PRINT,
    posInv2Print: value,
  };
};

{
  /*AVOIR*/
}

export const CreateAvoirEntry = (content, dataReservation) => ({
  type: CREATE_AVOIR_ENTRY,
  payload: content,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/document/create-advance-payment`,
        method: "POST",
        body: JSON.stringify({
          action: "Submit",
          doc: content,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: AVOIR_ENTRY_CREATED,
        meta: {
          notification: {
            code: "success",
            message: "notification:creditAvoirAdded",
          },
          then: (data) => async (dispatch, getState) => {
            const document = JSON.parse(data)?.docs[0];
            dispatch(SetAvoirToPrint(document));
            if (dataReservation) {
              await dispatch(
                UpdatePaymentStatusHoldById(
                  JSON.stringify({
                    id: dataReservation?.id,
                    payment_status: "cancel",
                    avoiraccount_id: document?.name,
                  })
                )
              );
              await dispatch(
                loadHolds(dataReservation?.company, dataReservation?.store)
              );
            }
          },
        },
      },
      rollback: { type: FAILED_ADD_DATA },
    },
  },
});

export const SetAvoirToPrint = (value) => {
  return {
    type: SET_AVOIR_2_PRINT,
    avoir2Print: value,
  };
};

{
  /*fetchAvoirList*/
}

export const fetchAvoirList = (company) => ({
  type: LOADING_AVOIR_LIST,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/currency-rate`,
        method: "post",
        body: JSON.stringify({
          doctype: "Avoir Account",
          fields: [
            "`tabAvoir Account`.`name`",
            "`tabAvoir Account`.`credit_amount`",
            "`tabAvoir Account`.`customer`",
            "`tabAvoir Account`.`return_against`",
            "`tabAvoir Account`.`currency`",
            "`tabAvoir Account`.`creation_date`",
          ],
          filters: [
            ["Avoir Account", "company", "=", `${company}`],
            ["Avoir Account", "docstatus", "=", "1"],
            ["Avoir Account", "docstatus", "!=", "2"],
            ["Avoir Account", "docstatus", "!=", "3"],
          ],
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("user")).token,
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        },
      },
      // action to dispatch when effect succeeds:
      commit: { type: AVOIR_LIST_LOADED, meta: "" },
      // action to dispatch if network action fails permanently:
    },
  },
});

export const ignorePricingRules = (value) => {
  return {
    type: IGNORE_PRICING_RULES,
    ignorePricingRules: value,
  };
};

export const storebulkCheque = (content) => {
  const action = {
    type: SAVE_CHEQUE_BULK,
    payload: content,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${process.env.REACT_APP_API_URI}/api/cheques/storebulk`,
          method: "POST",
          body: JSON.stringify(content),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user")).token,
          },
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: SAVE_CHEQUE_BULK_SUCCESS,
          meta: {
            notification: {
              code: "success",
              message: "notification:successfullyregisteredcheques",
            },
          },
        },
        rollback: { type: FAILED_ADD_DATA },
      },
    },
  };
  action.meta.offline.rollback.meta = action;
  return (dispatch) => {
    dispatch(action);
  };
};
