import Box from "@material-ui/core/Box";
import AddItem from "../../../../components/addItem/AddItem";
import { SetNotification } from "../../../../store/notification/notification.action";
import { Add, Delete } from "@material-ui/icons";
import { useNavigate, useParams } from "react-router";
import { Loader } from "../../../../components/loader";
import BarcodeScannerComponent from "react-webcam-barcode-scanner";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
 
import { useTranslation } from "react-i18next";
import AddBoxIcon from "@mui/icons-material/AddBox";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Grid,
  TextField,
  Collapse,
  LinearProgress,
  FormControl,
  InputAdornment,
  Link,
  Select,
  Modal,
  OutlinedInput,
  InputLabel,
  MenuItem,
} from "@mui/material";
import { Button } from "@material-ui/core";
import {
  DatePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import useGetDoc from "../../../../hooks/useGetDoc";
import React, { useEffect, useState } from "react";
import { formatDate } from "../../../../helpers/utils";
import { fr } from "date-fns/esm/locale";
import DateFnsUtils from "@date-io/date-fns";
import { SpaceBar } from "@material-ui/icons";
import SelectForm from "../../../../components/selectForm/SelectForm";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import { handleBarcodeScan } from "../../../../utils/scanUtils";
import useScanDetection from "use-scan-detection";

const AddPurchaseReceipt = ({ setCloseModal }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation(["buying", "common"]);
  const [supplierList, setSupplierList] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [CurrencyList, setCurrencyList] = useState([]);
  const [taxList, setTaxList] = useState([]);
  const [supplierSelected, setSupplierSelected] = useState("");
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const [companySelected, setCompanySelected] = useState(globalDefaults?.default_company);
  const [quotationNo, setQuotationNo] = useState("");
  const [addressList, setAddressList] = useState([]);
  const [addressSelected, setAddressSelected] = useState("");
  const [contactList, setContactList] = useState([]);
  const [contactSelected, setContactSelected] = useState("");
  const [CurrencySelected, setCurrencySelected] = useState("");
  const [taxCategoryList, setTaxCategoryList] = useState([]);
  const [priceList, setPriceList] = useState([]);
  const [priceSelected, setPriceSelected] = useState("");
  const [taxCategorySelected, setTaxCategorySelected] = useState("");
  const [purchaseTaxTemplateList, setPurchaseTaxTemplateList] = useState([]);
  const [purchaseTaxTemplateSelected, setPurchaseTaxTemplateSelected] = useState("");
  const [warehouseList, setWarehouseList] = useState([]);
  const [warehouseSelected, setWarehouseSelected] = useState("");
  const [warehouseRejectedSelected, setWarehouseRejectedSelected] =
    useState("");
  const [loading, setLoading] = useState(false);
  const [scannedCode, setScannedCode] = useState("");
  const [showScanner, setShowScanner] = useState(false);
  const [applyDiscount, setApplyDiscount] = useState("");
  const [discountAmount, setDiscountAmount] = useState("");

  const [error, setError] = useState({
    supplier: false,
    scheduleDate: false,
    scheduleDateTill: false,
    items: false,
    taxes: false,
  });
  const [scheduleDate, setScheduleDate] = useState(
    formatDate(new Date(), "YYYY-MM-DD")
  );
  const [scheduleDateTill, setScheduleDateTill] = useState(
    formatDate(new Date(), "YYYY-MM-DD")
  );
  const [state, setState] = useState({
    items: [],
    taxes: [],
  });
  const [barcode, setBarcode] = useState("");
  useScanDetection({
     onComplete: setBarcode,
     minLength: 0,
     maxLength: 50,
     endChar: "Enter",
   });
  useEffect(() => {
    handleBarcodeScan(barcode, setItemList, setState, setError)
      .then(() => { 
         
        setBarcode("");  
      })
      .catch(error => {
         
        setBarcode("");  
      });
  }, [barcode]);  
  const handleQuantityChange = (index, value) => {
    const updatedItems = [...state.items];
    updatedItems[index].quantity = value;
    updatedItems[index].base_amount =
      value * updatedItems[index].last_purchase_rate;
    setState({ ...state, items: updatedItems });
  };

  const handleDeleteItem = (index) => {
    const updatedItems = [...state.items];
    updatedItems.splice(index, 1);
    setState({ ...state, items: updatedItems });
  };

  const getSupplierList = () => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        doctype: "Supplier",
        fields: ["`tabSupplier`.`name`","`tabSupplier`.`disabled`"],
        filters: [["Supplier", "disabled", "=", 0]]
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (Array.isArray(data)) {
          setLoading(false);
          setSupplierList(data);
        }
      });
  };

  const getItemList = () => {
    if (companySelected) {
      fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
        body: JSON.stringify({
          doctype: "Item",
          fields: [
            "`tabItem`.`name`",
            "`tabItem`.`item_collection`",
            "`tabItem`.`item_name`",
            "`tabItem`.`opening_stock`",
            "`tabItem`.`net_amount`",
            "`tabItem`.`net_rate`",
            "`tabItem`.`price_list_rate`",
            "`tabItem`.`discount_amount`",
            "`tabItem Tax`.`item_tax_template`",
            "`tabItem`.`is_stock_item`",
            "`tabItem`.`has_variants`",
            "`tabItem`.`default_material_request_type`",
            "`tabItem`.`net_rate`",
            "`tabItem`.`standard_rate`",
            "`tabItem`.`last_purchase_rate`",
            "`tabItem`.`item_code`",
            "`tabItem`.`description`",
            "`tabItem`.`item_group`",
            "`tabItem`.`total_projected_qty`",
          ],
          filters: [["Item Default", "company", "=", companySelected]],
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (Array.isArray(data)) {
            setItemList(data);
          }
        });
    }
  };
  const getItemPrice = () => {
    const requestBody = {
      items: state.items.map((item) => {
        return {
          item_code: item.item_code,
        };
      }),
    };

    fetch(`${process.env.REACT_APP_API_URI}/api/get-complete-item`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify(requestBody), // Convert the requestBody to a JSON string
    })
      .then((res) => res.json())
      .then((data) => {
        if (Array.isArray(data)) {
          state.items.map((item) => {
            data.map((price) => {
              if (item.item_code == price.item_code) {
                item.price_selling = price.price_list_rate;
                //   setState({  items: [...state.items, {price_list_rate: price.price_list_rate }] })
                console.log("state items === ", state.items);
              }
            });
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching item price:", error);
      });
  };
  const getCompanyList = () => {
    fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        doctype: "Company",
        fields: ["`tabCompany`.`name`"],
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (Array.isArray(data)) {
          setCompanyList(data);
        }
      });
  };
  const getTaxList = () => {
    if (companySelected) {
      fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
        body: JSON.stringify({
          doctype: "Account",
          fields: [
            "`tabAccount`.`name`",
            "`tabAccount`.`owner`",
            "`tabAccount`.`creation`",
            "`tabAccount`.`modified`",
            "`tabAccount`.`modified_by`",
            "`tabAccount`.`_user_tags`",
            "`tabAccount`.`_comments`",
            "`tabAccount`.`_assign`",
            "`tabAccount`.`_liked_by`",
            "`tabAccount`.`docstatus`",
            "`tabAccount`.`parent`",
            "`tabAccount`.`parenttype`",
            "`tabAccount`.`parentfield`",
            "`tabAccount`.`idx`",
            "`tabAccount`.`account_name`",
            "`tabAccount`.`account_number`",
            "`tabAccount`.`disabled`",
            "`tabAccount`.`account_type`",
            "`tabAccount`.`tax_rate`",
          ],
          filters: [
            ["Account", "account_type", "=", "Tax"],
            ["Account", "company", "=", companySelected],
          ],
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (Array.isArray(data)) {
            setTaxList(data);
          }
        });
    }
  };
  const getAddressList = () => {
    if (supplierSelected) {
      fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
        body: JSON.stringify({
          doctype: "Address",
          fields: [
            "`tabAddress`.`name`",
            "`tabAddress`.`owner`",
            "`tabAddress`.`creation`",
            "`tabAddress`.`modified`",
            "`tabAddress`.`modified_by`",
            "`tabAddress`.`_user_tags`",
            "`tabAddress`.`_comments`",
            "`tabAddress`.`_assign`",
            "`tabAddress`.`_liked_by`",
            "`tabAddress`.`docstatus`",
            "`tabAddress`.`parent`",
            "`tabAddress`.`parenttype`",
            "`tabAddress`.`parentfield`",
            "`tabAddress`.`idx`",
            "`tabAddress`.`address_type`",
            "`tabAddress`.`city`",
            "`tabAddress`.`disabled`",
          ],
          filters: [
            ["Address", "address_type", "=", "Billing"],
            ["Dynamic Link", "link_name", "=", supplierSelected],
          ],
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (Array.isArray(data)) {
            setAddressList(data);
          }
        });
    }
  };
  const getCurrencyList = () => {
    fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        doctype: "Currency",
        fields: [
          "`tabCurrency`.`name`",
          "`tabCurrency`.`owner`",
          "`tabCurrency`.`creation`",
          "`tabCurrency`.`modified`",
          "`tabCurrency`.`modified_by`",
          "`tabCurrency`.`_user_tags`",
          "`tabCurrency`.`_comments`",
          "`tabCurrency`.`_assign`",
          "`tabCurrency`.`_liked_by`",
          "`tabCurrency`.`docstatus`",
          "`tabCurrency`.`parent`",
          "`tabCurrency`.`parenttype`",
          "`tabCurrency`.`parentfield`",
          "`tabCurrency`.`idx`",
          "`tabCurrency`.`enabled`",
          "`tabCurrency`.`fraction`",
          "`tabCurrency`.`fraction_units`",
          "`tabCurrency`.`symbol`",
          "`tabCurrency`.`number_format`",
        ],
        filters: [["Currency", "enabled", "=", 1]],
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (Array.isArray(data)) {
          setCurrencyList(data);
        }
      });
  };
  const getConstactList = () => {
    if (supplierSelected) {
      fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
        body: JSON.stringify({
          doctype: "Contact",
          fields: [
            "`tabContact`.`name`",
            "`tabContact`.`owner`",
            "`tabContact`.`creation`",
            "`tabContact`.`modified`",
            "`tabContact`.`modified_by`",
            "`tabContact`.`_user_tags`",
            "`tabContact`.`_comments`",
            "`tabContact`.`_assign`",
            "`tabContact`.`_liked_by`",
            "`tabContact`.`docstatus`",
            "`tabContact`.`parent`",
            "`tabContact`.`parenttype`",
            "`tabContact`.`parentfield`",
            "`tabContact`.`idx`",
            "`tabContact`.`email_id`",
            "`tabContact`.`status`",
            "`tabContact`.`phone`",
            "`tabContact`.`image`",
          ],
          filters: [
            ["Dynamic Link", "link_name", "=", supplierSelected],
            ["Contact", "status", "=", "Passive"],
          ],
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (Array.isArray(data)) {
            setContactList(data);
          }
        });
    }
  };
  const getTaxCategoryList = () => {
    fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        doctype: "Tax Category",
        fields: [
          "`tabTax Category`.`name`",
          "`tabTax Category`.`owner`",
          "`tabTax Category`.`creation`",
          "`tabTax Category`.`modified`",
          "`tabTax Category`.`modified_by`",
          "`tabTax Category`.`_user_tags`",
          "`tabTax Category`.`_comments`",
          "`tabTax Category`.`_assign`",
          "`tabTax Category`.`_liked_by`",
          "`tabTax Category`.`docstatus`",
          "`tabTax Category`.`parent`",
          "`tabTax Category`.`parenttype`",
          "`tabTax Category`.`parentfield`",
          "`tabTax Category`.`idx`",
          "`tabTax Category`.`title`",
          "`tabTax Category`.`disabled`",
        ],
        filters: [["Tax Category", "disabled", "=", 0]],
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (Array.isArray(data)) {
          setTaxCategoryList(data);
        }
      });
  };
  const getPriceList = () => {
    fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        doctype: "Price List",
        fields: [
          "`tabPrice List`.`name`",
          "`tabPrice List`.`price_list_name`",
          "`tabPrice List`.`buying`",
          "`tabPrice List`.`selling`",
          "`tabPrice List`.`currency`",
        ],
        filters: [["Price List", "buying", "=", 1]],
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (Array.isArray(data)) {
          setPriceList(data);
        }
      });
  };
  const getPurchaseTaxTemplateList = () => {
    if (companySelected) {
      fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
        body: JSON.stringify({
          doctype: "Purchase Taxes and Charges Template",
          fields: [
            "`tabPurchase Taxes and Charges Template`.`name`",
            "`tabPurchase Taxes and Charges Template`.`owner`",
            "`tabPurchase Taxes and Charges Template`.`creation`",
            "`tabPurchase Taxes and Charges Template`.`modified`",
            "`tabPurchase Taxes and Charges Template`.`modified_by`",
            "`tabPurchase Taxes and Charges Template`.`_user_tags`",
            "`tabPurchase Taxes and Charges Template`.`_comments`",
            "`tabPurchase Taxes and Charges Template`.`_assign`",
            "`tabPurchase Taxes and Charges Template`.`_liked_by`",
            "`tabPurchase Taxes and Charges Template`.`docstatus`",
            "`tabPurchase Taxes and Charges Template`.`parent`",
            "`tabPurchase Taxes and Charges Template`.`parenttype`",
            "`tabPurchase Taxes and Charges Template`.`parentfield`",
            "`tabPurchase Taxes and Charges Template`.`idx`",
            "`tabPurchase Taxes and Charges Template`.`is_default`",
            "`tabPurchase Taxes and Charges Template`.`disabled`",
            "`tabPurchase Taxes and Charges Template`.`company`",
          ],
          filters: [
            [
              "Purchase Taxes and Charges Template",
              "company",
              "=",
              companySelected,
            ],
          ],
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (Array.isArray(data)) {
            setPurchaseTaxTemplateList(data);
          }
        });
    }
  };
  const getWarehouseList = () => {
    fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        doctype: "Warehouse",
        fields: [
          "`tabWarehouse`.`name`",
          "`tabWarehouse`.`owner`",
          "`tabWarehouse`.`creation`",
          "`tabWarehouse`.`modified`",
          "`tabWarehouse`.`modified_by`",
          "`tabWarehouse`.`_user_tags`",
          "`tabWarehouse`.`_comments`",
          "`tabWarehouse`.`_assign`",
          "`tabWarehouse`.`_liked_by`",
          "`tabWarehouse`.`docstatus`",
          "`tabWarehouse`.`parent`",
          "`tabWarehouse`.`parenttype`",
          "`tabWarehouse`.`parentfield`",
          "`tabWarehouse`.`idx`",
          "`tabWarehouse`.`is_group`",
          "`tabWarehouse`.`company`",
          "`tabWarehouse`.`disabled`",
          "`tabWarehouse`.`city`",
          "`tabWarehouse`.`warehouse_name`",
        ],
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (Array.isArray(data)) {
          setWarehouseList(data);
        }
      });
  };
  const handleReload = () => {
    window.location.reload();
  }

  const handleSaveQuotation = () => {
    setLoadingButton(true);
    const d = new Date();
    const data = {
      doc: {
        docstatus: 0,
        __islocal: 1,
        doctype: "Purchase Receipt",
        name: "new-purchase-receipt-1",
        naming_series: "MAT-PRE-.YYYY.-",
        company: companySelected,
        posting_date: scheduleDate,
        set_posting_time: 0,
        apply_putaway_rule: 0,
        is_return: 0,
        currency: "TND",
        buying_price_list: priceSelected,
        price_list_currency: CurrencySelected,
        ignore_pricing_rule: 0,
        is_subcontracted: "No",
        disable_rounded_total: 1,
        status: "Draft",
        is_internal_supplier: 0,
        group_same_items: 0,
        recoverable_standard_rated_expenses: 0,
        reverse_charge: "N",
        items: state.items.map((item, index) => {
          return {
            docstatus: 0,
            doctype: "Supplier Quotation Item",
            rate: item.price_list_rate,
            amount: item.price_list_rate,
            base_rate: item.base_rate,
            base_amount: item.base_amount,
            net_rate: item.net_rate,
            net_amount: item.net_amount,
            base_net_rate: item.base_net_rate,
            base_net_amount: item.base_net_amount,
            discount_amount: item.discount_amount,
            is_free_item: 0,
            item_group: "All Item Groups",
            stock_uom: "Nos",
            page_break: 0,
            stock_uom_rate: item.stock_uom_rate,
            parent: "new-purchase-receipt-1",
            parentfield: "items",
            parenttype: "Purchase Receipt",
            idx: index + 1,
            qty: item.quantity,
            received_qty: item.quantity,
            received_stock_qty: item.received_stock_qty,
            stock_qty: item.stock_qty,
            item_code: item.item_code,
            item_name: item.item_name,
            description: "test",
            transaction_date: scheduleDate,
            has_margin: false,
            warehouse: warehouseSelected,
            rejected_warehouse: warehouseRejectedSelected,
            barcode: scannedCode,
            child_docname: "new-purchase-receipt-item-1",
          };
        }),
        posting_time: d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds(),
        conversion_rate: 1,
        plc_conversion_rate: 1,
        company_trn: null,
        taxes_and_charges: purchaseTaxTemplateSelected,
        taxes: state.taxes.map((tax) => {
          return {
            docstatus: 0,
            doctype: "Purchase Taxes and Charges",
            account_currency: null,
            parent: "new-purchase-receipt-1",
            parentfield: "taxes",
            parenttype: "Purchase Receipt",
            idx: 1,
            account_head: tax.name,
            description: tax.account_name,
            rate: tax.tax_rate,
          };
        }),
        supplier: supplierSelected,
        supplier_name: supplierSelected,
        supplier_address: addressSelected,
        contact_person: contactSelected,
        tax_category: taxCategorySelected,
        set_warehouse: warehouseSelected,
        rejected_warehouse: warehouseRejectedSelected,
        total_net_weight: 0,
        apply_discount_on: "Grand Total",
        additional_discount_percentage:parseFloat(discountAmount),
      },
      action: "Save",
    };
    fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user"))?.token,
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        setLoadingButton(false);
        if (!res.ok) {
          dispatch(
            SetNotification({ code: "error", message: "Something went wrong" })
          );
        }
        return res.json();
      })
      .then((data) => {
        if (data?.success === false) {
          setLoadingButton(false);
          dispatch(
            SetNotification({ code: "warning", message: data?.message })
          );
        } else {
          setLoadingButton(false);
          dispatch(
            SetNotification({
              code: "success",
              message: `Le reçu d'achat a été créé avec succès`,
            })
          );
   
          handleCancel();
          handleReload();
        }
      });
  };

  useEffect(() => {
    getSupplierList();
    getCompanyList();
    getCurrencyList();
    getTaxCategoryList();
    getPriceList();
    getWarehouseList();
  }, []);
  useEffect(() => {
    getItemList();
    getTaxList();
    getPurchaseTaxTemplateList();
  }, [companySelected]);
  useEffect(() => {
    getItemPrice();
  }, [state.items]);
  useEffect(() => {
    getAddressList();
    getConstactList();
  }, [supplierSelected]);

  const handleChangeSelectmultiple = (event) => {
    setError({ ...error, [event.target.name]: false });
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleDateChange = (date, event) => {
    isNaN(Date.parse(date))
      ? setError({ ...error, scheduleDate: false })
      : setScheduleDate(date.toISOString().slice(0, 10));
  };

  const handleCancel = () => {
    setCloseModal();
  };

  const BarcodeField = () => {
    const handleScan = (data) => {
      if (data) {
        setScannedCode(data);
        setShowScanner(false); // Close the scanner after scanning
      }
    };

    const handleError = (err) => {
      console.error(err);
    };

    const resetScanner = () => {
      setScannedCode("");
    };

   
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div style={{ margin: "15px" }}>
        <Grid item xs={12} style={{ marginTop: 20, fontWeight: "bold" }}>
          <Box
            display={"flex"}
            alignItems={"center"}
            style={{ fontSize: "1.25rem", fontWeight: 800 }}
          >
            {/* <AddBoxIcon sx={{ mr: 1 }} style={{ color: "#0b68ff" }} /> */}
            <div style={{ color: "#2F4B7C" }}>{t("common:Recu")}</div>
          </Box>
        </Grid>
      </div>
      <Divider orientation="horizontal" fullWidth />
      <div style={{ marginLeft: "40px" }}>
        <Grid container spacing={5} style={{ paddingLeft: "3%" }}>
          <Grid item xs={6}>
            <FormControl style={{ marginTop: 35 }}>
              <InputLabel
                id="demo-simple-select-label"
                style={{ fontSize: "16px" }}
              >
                {t("common:supplier")}
              </InputLabel>
              <Select
                className="CuFormInput"
                style={{ width: "80%" }}
                labelId="select-label"
                id="simple-select"
                value={supplierSelected}
                label={t("common:supplier")}
                onChange={(e) => {
                  setSupplierSelected(e.target.value);
                }}
              >
                {supplierList?.map((item) => (
                  <MenuItem style={{ fontSize: 12 }} value={item?.name}>
                    {item?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Grid style={{ marginTop: 25 }}>
              <MuiPickersUtilsProvider
                padding={50}
                locale={fr}
                utils={DateFnsUtils}
              >
                <InputLabel className={"label"}>{t("common:date")}</InputLabel>
                <KeyboardDatePicker
                  className={"date"}
                  style={{ marginTop: 10, width: "80%" }}
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  name="scheduleDate"
                  inputVariant="outlined"
                  id="date-picker-inline"
                  value={scheduleDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  minDate={formatDate(new Date(), "YYYY-MM-DD")}
                  minDateMessage={"common:minDateMessage"}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth style={{ marginTop: 35 }}>
              <InputLabel
                id="demo-simple-select-label"
                style={{ fontSize: "16px" }}
              >
                {t("common:company")}
              </InputLabel>
              <Select
                className="CuFormInput"
                style={{ width: "80%" }}
                labelId="select-label"
                id="simple-select"
                value={companySelected}
                label={t("common:company")}
                onChange={(e) => setCompanySelected(e.target.value)}
              >
                {companyList?.map((item) => (
                  <MenuItem style={{ fontSize: 12 }} value={item?.name}>
                    {item?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth style={{ marginTop: "55px" }}>
              <InputLabel
                id="demo-simple-select-label"
                style={{ fontSize: "16px" }}
              >
                {t("common:SupplierAddress")}
              </InputLabel>
              <Select
                labelId="select-label"
                className="CuFormInput"
                style={{ width: "80%" }}
                id="simple-select"
                value={addressSelected}
                label={t("common:SupplierAddress")}
                onChange={(e) => {
                  setAddressSelected(e.target.value);
                }}
              >
                {addressList?.map((item) => (
                  <MenuItem style={{ fontSize: 12 }} value={item?.name}>
                    {item?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              multiple
              id="items-autocomplete"
              options={itemList}
              getOptionLabel={(option) =>
                option.item_name + " - " + option.item_code + " - " + option.barcode
              }
              value={state.items}
              onChange={(event, newValue) => {
                handleChangeSelectmultiple({
                  target: {
                    name: "items",
                    value: newValue.map((item) => ({
                      ...item,
                      quantity: 1,
                      last_purchase_rate: item.last_purchase_rate,
                      base_amount: item.last_purchase_rate,
                    })),
                  },
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("common:Items")}
                  error={!!error.items}
                  helperText={error.items}
                  fullWidth
                  sx={{ width: "80%" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth style={{ marginTop: "25px" }}>
              <InputLabel
                id="demo-simple-select-label"
                style={{ fontSize: "16px" }}
              >
                {t("common:SetTargetWarehouse")}
              </InputLabel>
              <Select
                labelId="select-label-warehouse"
                style={{ width: "80%" }}
                className="CuFormInput"
                id="simple-select"
                value={warehouseSelected}
                label={t("common:SetTargetWarehouse")}
                onChange={(e) => {
                  setWarehouseSelected(e.target.value);
                }}
              >
                {warehouseList?.map((item) => (
                  <MenuItem style={{ fontSize: 12 }} value={item?.name}>
                    {item?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth style={{ marginTop: "25px" }}>
              <InputLabel
                id="demo-simple-select-label"
                style={{ fontSize: "16px" }}
              >
                {t("common:RejectedWarehouse")}
              </InputLabel>
              <Select
                labelId="select-label-warehouse"
                style={{ width: "80%" }}
                className="CuFormInput"
                id="simple-select"
                value={warehouseRejectedSelected}
                label={t("common:SetRejectedWarehouse")}
                onChange={(e) => {
                  setWarehouseRejectedSelected(e.target.value);
                }}
              >
                {warehouseList?.map((item) => (
                  <MenuItem style={{ fontSize: 12 }} value={item?.name}>
                    {item?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {state.items.length > 0 ? (
            <Grid item xs={11}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          fontSize: 15,
                          textAlign: "Left",
                        }}
                      >
                        {t("common:Items")}
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          fontSize: 15,
                          textAlign: "Left",
                        }}
                      >
                        {t("Code Article")}
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          fontSize: 15,
                          textAlign: "center",
                        }}
                      >
                        {t("common:qty")}
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          fontSize: 15,
                          textAlign: "center",
                        }}
                      >
                        {t("prix")}
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          fontSize: 15,
                          textAlign: "center",
                        }}
                      >
                        {t("Total")}
                      </TableCell>
                      <TableCell> </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {state.items.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {item.item_name}
                        </TableCell>
                        <TableCell>
                          {item.item_code}
                        </TableCell>
                        <TableCell>
                          <TextField
                            required
                            type="number"
                            value={item.quantity}
                            onChange={(e) =>
                              handleQuantityChange(index, e.target.value)
                            }
                          />
                        </TableCell>
                        <TableCell>{item.last_purchase_rate}</TableCell>
                        <TableCell>{item.base_amount}</TableCell>
                        <TableCell>
                          <IconButton onClick={() => handleDeleteItem(index)}>
                            <Delete color="error" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          ) : null}
          <Grid item xs={6}>
            <FormControl fullWidth style={{ marginTop: "26px" }}>
              <InputLabel
                id=" simple-select-label"
                style={{ fontSize: "16px" }}
              >
                {t("common:ContactPerson")}
              </InputLabel>
              <Select
                labelId="select-label-item"
                className="CuFormInput"
                style={{ width: "80%" }}
                id="simple-select-item"
                value={contactSelected}
                label={t("common:ContactPerson")}
                onChange={(e) => setContactSelected(e.target.value)}
              >
                {contactList?.map((item) => (
                  <MenuItem style={{ fontSize: 12 }} value={item?.name}>
                    {item?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth style={{ marginTop: "22px" }}>
              <InputLabel
                id=" simple-select-label"
                style={{ fontSize: "16px" }}
              >
                {t("common:currency")}
              </InputLabel>
              <Select
                labelId="select-label-item"
                className="CuFormInput"
                style={{ width: "80%" }}
                id="simple-select-item"
                value={CurrencySelected}
                label={t("common:currency")}
                onChange={(e) => setCurrencySelected(e.target.value)}
              >
                {CurrencyList?.map((item) => (
                  <MenuItem style={{ fontSize: 12 }} value={item?.name}>
                    {item?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth style={{ marginTop: "24px" }}>
              <InputLabel
                id=" simple-select-label"
                style={{ fontSize: "16px" }}
              >
                {t("common:PriceList")}
              </InputLabel>
              <Select
                labelId="select-label-item"
                className="CuFormInput"
                style={{ width: "80%" }}
                id="simple-select-item"
                value={priceSelected}
                label={t("common:PriceList")}
                onChange={(e) => setPriceSelected(e.target.value)}
              >
                {priceList?.map((item) => (
                  <MenuItem style={{ fontSize: 12 }} value={item?.name}>
                    {item?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* <Grid item xs={6} style={{ marginTop: "26px" }}>
            <FormControl fullWidth>
              <InputLabel
                id=" simple-select-label"
                style={{ fontSize: "16px" }}
              >
                {t("common:TaxCategory")}
              </InputLabel>
              <Select
                labelId="select-label-item"
                className="CuFormInput"
                style={{ width: "80%" }}
                id="simple-select-item"
                value={taxCategorySelected}
                label={t("common:TaxCategory")}
                onChange={(e) => setTaxCategorySelected(e.target.value)}
              >
                {taxCategoryList?.map((item) => (
                  <MenuItem style={{ fontSize: 12 }} value={item?.name}>
                    {item?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} style={{ marginTop: "26px" }}>
            <FormControl fullWidth>
              <InputLabel
                id=" simple-select-label"
                style={{ fontSize: "16px" }}
              >
                {t("common:PurchaseTax")}
              </InputLabel>
              <Select
                labelId="select-label-item"
                className="CuFormInput"
                style={{ width: "80%" }}
                id="simple-select-item"
                value={purchaseTaxTemplateSelected}
                label={t("common:PurchaseTax")}
                onChange={(e) => setPurchaseTaxTemplateSelected(e.target.value)}
              >
                {purchaseTaxTemplateList?.map((item) => (
                  <MenuItem style={{ fontSize: 12 }} value={item?.name}>
                    {item?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid> */}
          <Grid item xs={6} >
            <AddItem
              label={t("common:Taxes")}
              name="taxes"
              value={state.taxes}
              error={error.taxes}
              propToRender={"name"}
              handleChange={handleChangeSelectmultiple}
              defaultItem={""}
              data={taxList}
            />
          </Grid>
          {/* <Grid item xs={6} style={{ marginTop: "22px" }}>
            <TextField
              name="qnumber"
              className="CuFormInput"
              style={{ marginTop: "28px", width: "80%" }}
              onChange={(e) => setScannedCode(e.target.value)}
              label={t("common:barcode")}
              variant="outlined"
              value={scannedCode ? scannedCode : ""}
            />
          </Grid> */}
          <Grid item xs={12} >
            <Box style={{ display: "flex", justifyContent: "center" }}>
              <FormControl fullWidth>
                <InputLabel
                    id=" simple-select-label"
                    style={{ fontSize: "16px" }}
                >
                  {t("Appliquer une remise supplémentaire sur")}
                </InputLabel>
                <Select
                    labelId="select-label-item"
                    className="CuFormInput"
                    style={{ width: "80%" }}
                    id="simple-select-item"
                    value={applyDiscount}
                    label={t("Appliquer une remise supplémentaire sur")}
                    onChange={(e) => setApplyDiscount(e.target.value)}
                >
                  {["","Grand Total"].map((item,index) => (
                      <MenuItem style={{ fontSize: 12 }} key={index} value={item}>
                        {item}
                      </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <TextField
                    sx={{ width: "80%" }}
                    label={"Pourcentage de remise supplémentaire"}
                    type="number"
                    value={discountAmount}
                    onChange={(e) => setDiscountAmount(e.target.value)}
                />
              </FormControl>
              </Box>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: "20px", marginRight: "15%" }}>
            <Box style={{ display: "flex", justifyContent: "center" }}>
              <Button
                size="large"
                disabled={loadingButton}
                color="primary"
                variant="contained"
                onClick={handleSaveQuotation}
                style={{ marginRight: "16px" }} // Add margin to separate buttons
              >
                {t("common:save")}
              </Button>
              <Button
                size="large"
                color="error"
                variant="contained"
                onClick={handleCancel}
              >
                {t("common:annuler")}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default AddPurchaseReceipt;
