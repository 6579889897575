import React, { useEffect, useMemo, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Products } from "../checkout/components/products";
import Box from "@material-ui/core/Box";
import { BasicTable } from "./components/basictable";
import { Freebutton } from "../checkout/components/freebutton";
import { Calculator } from "../../components/calculator";
import { WidthProvider, Responsive } from "react-grid-layout";
import { useDispatch, useSelector } from "react-redux";
import {
  UpdatePayment,
  UpdateProducts,
  loadItemDetail,
  UpdateSalesPerson,
  updateItemDetails,
  ignorePricingRules,
} from "../../store/checkout/checkout.action";
import { ButtonsFunction } from "../../helpers/buttonsFunction";
import { TransitionAlerts } from "../../components/TransitionAlerts";
import { useTranslation } from "react-i18next";
import { SetNotification } from "../../store/notification/notification.action";
import { formatDate } from "../../helpers/utils";
import ProductFinancierCheckout from "../../components/productItem/productFinancier";
const ResponsiveReactGridLayout = WidthProvider(Responsive);
const originalLayouts = getFromLS("layouts") || {};
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: 17,
  },
  absolute: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
  productSection: {
    [theme.breakpoints.up("md")]: {
      paddingRight: 18,
    },
  },
  calculator: {
    marginBottom: 20,
  },
  hiden: {
    display: "none",
  },
}));

function getFromLS(key) {
  let ls = {};
  if (global.localStorage) {
    try {
      ls = JSON.parse(global.localStorage.getItem("rgl-8")) || {};
    } catch (e) {
      /*Ignore*/
    }
  }
  return ls[key];
}

export const Checkout = (props) => {
  const { t } = useTranslation(["checkout"]);
  const [alert, setAlert] = React.useState({
    open: false,
    message: "",
    severity: "",
  });
  const classes = useStyles();
  const dispatch = useDispatch();
  const talonProps = ButtonsFunction();
  const [state, setState] = React.useState({
    layouts: JSON.parse(JSON.stringify(originalLayouts)),
  });
  const products = useSelector((state) => state.Product.products);
  const amount = useSelector((state) => state.Checkout.amount);
  const discount = useSelector((state) => state.Checkout.discount);
  const coupon = useSelector((state) => state.Checkout.coupon);
  const pricingRules = useSelector((state) => state.Checkout.pricingRules);
  const currencyExchangeRate = useSelector(
    (state) => state.Checkout.currencyExchangeRate
  );
  const payementsMethods = useSelector(
    (state) => state.Checkout.payementsMethods
  );
  const order = useSelector((state) => state.Checkout.selectedorder);
  const showCalculator = useSelector((state) => state.Checkout.showCalculator);
  const itemTaxList = useSelector((state) => state.Product.itemTax);

  const [searchProduct, setSearchProduct] = React.useState("");
  const [initialLines, setInitialLines] = React.useState([0, 1, 2, 3]);

  const rows = useSelector((state) => state.Checkout.products);
  const selectedSalesPerson = useSelector(
    (state) => state.Checkout.selectedSalesPerson
  );
  const itemDetails = useSelector((state) => state.Checkout.itemDetails);
  const selectedData = useSelector((state) => state.Login.selectedData);
  const ignorePricingRule = useSelector(
    (state) => state.Checkout.ignorePricingRules
  );

  const itemsWithPricingRules = itemDetails?.filter((item) =>
    Object.keys(item).includes("pricing_rules")
  );

  const returnedInvoice = useSelector(
    (state) => state.Checkout.returnedInvoice
  );
  const productsPos = useSelector((state) => state.Product.productsPos);
  const [suggestion, setsuggestion] = React.useState([]);
  const [changes, setChanges] = React.useState(1);

  const selectedClient = useSelector((state) => state.Checkout.client);
  const defaultClient = useSelector((state) => state.Checkout.defaultClient);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const store = useSelector((state) => state.Login.selectedData?.store);
  const hold = useSelector((state) => state.Checkout.holds);

  const isReservedInPOS = (item_code) => {
    let totalQtyReserved = 0;
    hold &&
      hold
        ?.filter((element) => element?.payment_status === "partly paid")
        ?.forEach((element) => {
          if (element?.amount?.payments.length > 0) {
            const reservedProducts = element?.products.filter(
              (el) => el.item_code === item_code
            );

            reservedProducts?.forEach((reservedProduct) => {
              totalQtyReserved += reservedProduct.qty;
            });
          }
        });

    return totalQtyReserved;
  };
  const availableStock = (id) => {
    const IndexinstockPOS = productsPos.findIndex((s) => s.item_code == id);
    const ItemIndex = rows.findIndex((s) => s.item_code == id);
    var res = true;
    if (rows[ItemIndex]?.item_group != "Services") {
      return (
        rows[ItemIndex]?.quantity <
          productsPos[IndexinstockPOS].actual_qty - isReservedInPOS(id) &&
        rows[ItemIndex]?.quantity <
          productsPos[IndexinstockPOS].actual_qty - isReservedInPOS(id)
      );
    } else {
      return res;
    }
  };

  const GetTotalPrice = (rows) => {
    var total = 0;
    rows.forEach((element) => {
      if (!element.item_tax_template) {
        total += element.net_amount;
      } else {
        let taxRate = itemTaxList.find(
          (s) => s.name == element.item_tax_template
        )?.tax_rate;
        if (!taxRate) {
          taxRate = 0;
        }
        total +=
          element.net_amount +
          parseInt(element.qty) * ((element.net_rate * taxRate) / 100);
      }
    });
    return parseFloat(total);
  };
  const loadItemDetails = (items) => {
    items?.forEach((item) => {
      let doc = {
        doctype: "POS Invoice",
        company: globalDefaults.default_company,
        posting_date: formatDate(new Date(), "YYYY-MM-DD"),
        currency: globalDefaults.default_currency,
        price_list_currency: globalDefaults.default_currency,
        ignore_pricing_rule: 0,
        party_account_currency: globalDefaults.default_currency,
        items: [
          {
            doctype: "POS Invoice Item",
            item_group: item.item_group,
            parentfield: "items",
            parenttype: "POS Invoice",
            qty: item.qty,
            item_code: item.item_code,
          },
        ],
        pos_profile: selectedData.cashier,
        set_warehouse: store,
        customer_name: selectedClient?.customer_name
          ? selectedClient?.customer_name
          : defaultClient?.customer_name,
        customer: selectedClient?.customer_name
          ? selectedClient?.customer_name
          : defaultClient?.customer_name,
      };
      let args = {
        item_code: item.item_code,
        set_warehouse: store,
        customer: selectedClient?.customer_name
          ? selectedClient?.customer_name
          : defaultClient?.customer_name,
        currency: globalDefaults.default_currency,
        price_list_currency: globalDefaults.default_currency,
        company: globalDefaults.default_company,
        ignore_pricing_rule: 0,
        doctype: "POS Invoice",
        pos_profile: selectedData.cashier,
      };
      dispatch(loadItemDetail(args, doc));
    });
  };

  const handleAddproduct = async (data) => {
    // Check if discounted products are available
    var discountedProducts =
      pricingRules && pricingRules.items
        ? pricingRules.items.map((i) => i.item_code)
        : null;
  
    // If data exists, find the matching item in the productsPos array
    if (data) {
      const match = productsPos.find((pos) => pos.item_code === data.item_code);
  
      if (match) {
        data = {
          ...data,
          ...match, // Merging matched product data with current data
        };
      }
    }
  
    // Find the item in the rows array using exact item_code match
    const ItemIndex = rows.findIndex((s) => s.item_code === data.item_code);
  
    if (ItemIndex != -1) {
      const teststock = await availableStock(rows[ItemIndex]?.item_code);
  
      if (teststock) {
        // If stock is available, update the quantity and other fields
        rows[ItemIndex].quantity = parseInt(rows[ItemIndex].quantity) + 1;
        rows[ItemIndex].qty = parseInt(rows[ItemIndex].qty) + 1;
        rows[ItemIndex].price_list_rate = parseFloat(
          rows[ItemIndex].price_list_rate
            ? rows[ItemIndex].price_list_rate
            : rows[ItemIndex].standard_rate
        );
        rows[ItemIndex].net_amount =
          rows[ItemIndex].net_rate * rows[ItemIndex].quantity;
        rows[ItemIndex].discount_amount =
          rows[ItemIndex].discount_amount * rows[ItemIndex].quantity;
      } else {
        // If stock is unavailable, show a warning
        setAlert({
          open: true,
          message: t("checkout:stockunavailable"),
          severity: "warning",
        });
      }
    } else {
      // If the item does not exist in rows, add a new item
      data.discount = 0;
      data.quantity = 1;
      data.qty = 1;
      data.price_list_rate = data.price_list_rate
        ? parseFloat(data.price_list_rate)
        : parseFloat(data.standard_rate);
      data.net_rate =
        (data.price_list_rate -
          (data.price_list_rate * parseFloat(data.discount)) / 100) /
        currencyExchangeRate;
      data.net_amount = data.net_rate * data.quantity;
      data.discount_amount =
        (data.price_list_rate - data.net_rate) * data.quantity;
  
      const IndexinstockPOS = productsPos.findIndex(
        (s) => s.item_code === data.item_code
      );
  
      if (IndexinstockPOS > -1 && productsPos[IndexinstockPOS].actual_qty > 0) {
        // Apply discounts based on pricing rules
        if (
          discountedProducts &&
          discountedProducts.includes(data.item_code) &&
          pricingRules?.discount_percentage !== 0
        ) {
          data.discount = pricingRules?.discount_percentage;
          data.net_rate =
            (data.price_list_rate -
              (data.price_list_rate *
                parseFloat(pricingRules?.discount_percentage)) /
                100) /
            currencyExchangeRate;
          data.net_amount = data.net_rate * data.quantity;
        } else if (
          discountedProducts &&
          discountedProducts.includes(data.item_code) &&
          pricingRules?.discount_amount !== 0
        ) {
          data.discount_amount = pricingRules?.discount_amount;
          data.net_rate = data.price_list_rate - pricingRules?.discount_amount;
          data.net_amount = data.net_rate * data.quantity;
        }
  
        // Add the new item to rows
        rows.push(data);
        initialLines.splice(-1);
      } else {
        // If the item is unavailable, show an error
        setAlert({
          open: true,
          message: t("checkout:itemunavailable"),
          severity: "error",
        });
      }
    }
  
    // Clear suggestions and reset search product
    setsuggestion([]);
    setSearchProduct("");
  
    // Update the product list in the global state
    dispatch(UpdateProducts(rows));
  
    // Load the details for the newly added item(s)
    loadItemDetails(rows);
  
    // Calculate the total and tax amounts
    const totalAmount = GetTotalPrice(rows);
    const taxAmount =
      totalAmount -
      rows.reduce((a, v) => (a = a + parseFloat(v.net_amount)), 0);
  
    // Update the payment with the new totals
    dispatch(
      UpdatePayment({
        ...amount,
        totalAmount,
        taxAmount,
      })
    );
  
    // Track changes for re-rendering purposes
    setChanges(changes + 1);
  };
  

  const updatedRows = useMemo(() => {
    if (!itemsWithPricingRules || itemsWithPricingRules.length === 0) {
      return rows.map((row) => ({
        ...row,
        discount: 0,
        net_rate: row.price_list_rate,
        net_amount: row.price_list_rate * row.quantity,
      }));
    }
    if (itemsWithPricingRules && itemsWithPricingRules.length > 0) {
      // Create a map of item_code to discount_amount and discount_percentage for faster lookup
      const discountMap = {};
      itemsWithPricingRules.forEach((item) => {
        if (item.free_item_data.length !== 0) {
          item.free_item_data.forEach((freeItem) => {
            const IndexinstockPOS = productsPos.findIndex(
              (s) => s.item_code == freeItem?.item_code
            );
            if (IndexinstockPOS != -1) {
              if (productsPos[IndexinstockPOS].actual_qty <= 0) {
                dispatch(
                  SetNotification({
                    code: "error",
                    message: `${t("checkout:freeitem")} ${
                      freeItem?.item_code
                    } ${t("checkout:availableInWarehouse")} ${
                      selectedData?.store
                    }`,
                  })
                );

                dispatch(ignorePricingRules(true));
              } else if (
                productsPos[IndexinstockPOS].actual_qty < freeItem?.qty
              ) {
                dispatch(
                  SetNotification({
                    code: "error",
                    message: `${t("checkout:freeitem")} ${
                      freeItem?.item_code
                    } ${t("checkout:availableInWarehouse")} ${
                      selectedData?.store
                    }`,
                  })
                );
                dispatch(ignorePricingRules(true));
              }
            }
          });
          discountMap[item.item_code] = {
            type: "free_item",
            value: item.free_item_data,
          };
        }
        if (item.discount_amount !== 0) {
          discountMap[item.item_code] = {
            type: "amount",
            value: item.discount_amount,
          };
        } else if (item.discount_percentage !== 0) {
          discountMap[item.item_code] = {
            type: "percentage",
            value: item.discount_percentage,
          };
        }
      });

      // Update the discount information for items in the rows array
      return rows.map((row) => {
        if (discountMap.hasOwnProperty(row.item_code)) {
          const discountInfo = discountMap[row.item_code];
          let net_rate, net_amount, discount, item_tax_template;

          if (ignorePricingRule) {
            // When ignorePricingRule is true, don't apply any discount
            net_rate = row.price_list_rate;
            net_amount = net_rate * row.quantity;
            discount = 0;
          } else {
            // Apply the discount based on the discount type
            net_rate =
              discountInfo.type === "amount"
                ? row.price_list_rate - discountInfo.value
                : discountInfo.type === "percentage"
                ? row.price_list_rate -
                  (row.price_list_rate * discountInfo.value) / 100
                : row.price_list_rate;
            net_amount = net_rate * row.quantity;
            discount = discountInfo.type === "percentage" && discountInfo.value;
            // Check if there are free items
            if (
              discountInfo?.type === "free_item" &&
              discountInfo?.value.length !== 0
            ) {
              discountInfo?.value.forEach((freeItem) => {
                const net_free_rate = freeItem.rate;
                const net_free_amount = net_free_rate * freeItem.qty;
                item_tax_template = row.item_tax_template;
                net_rate = net_free_rate + row.price_list_rate;
                net_amount =
                  net_free_amount + row.price_list_rate * row.quantity;
              });
            }
          }

          // Create a new object with updated properties
          return {
            ...row,
            ...(discountInfo.type === "amount"
              ? { discount_amount: discountInfo.value }
              : discountInfo.type === "percentage"
              ? { discount_percentage: discountInfo.value }
              : {}),
            net_rate,
            net_amount,
            discount,
          };
        } else {
          return row; // No discount update for this item
        }
      });
    }

    return rows;
  }, [itemDetails, ignorePricingRule]);

  // Dispatch the action outside of the useMemo
  useEffect(() => {
    dispatch(UpdateProducts(updatedRows));
  }, [updatedRows]);

  const HandleQuantityChange = async (onAdd, id) => {
    const IndexinstockPOS = productsPos.findIndex((s) => s.item_code == id);
    const ItemIndex = rows.findIndex((s) => s.item_code == id);
    const data = [...rows];
    if (ItemIndex != -1 && IndexinstockPOS != -1) {
      const teststock = await availableStock(id);
      if ((onAdd && teststock) || !onAdd) {
        data[ItemIndex].quantity = onAdd
          ? parseInt(data[ItemIndex].quantity) + 1
          : Math.sign(data[ItemIndex].quantity - 1) == 1
          ? parseInt(data[ItemIndex].quantity) - 1
          : 0;
        data[ItemIndex].qty = onAdd
          ? parseInt(data[ItemIndex].qty) + 1
          : Math.sign(data[ItemIndex].qty - 1) == 1
          ? parseInt(data[ItemIndex].qty) - 1
          : 0;
        data[ItemIndex].net_amount =
          data[ItemIndex].net_rate * data[ItemIndex].quantity;
        data[ItemIndex].discount_amount =
          data[ItemIndex].discount_amount * data[ItemIndex].qty;
        dispatch(UpdateProducts(data));
        loadItemDetails(data);
        const totalAmount = GetTotalPrice(data);
        const taxAmount =
          totalAmount -
          data.reduce((a, v) => (a = a + parseFloat(v.net_amount)), 0);
        dispatch(UpdatePayment({ ...amount, totalAmount, taxAmount }));
      } else {
        setAlert({
          open: true,
          message: t("checkout:stockunavailable"),
          severity: "warning",
        });
      }
    } else {
      setAlert({
        open: true,
        message: t("checkout:itemunavailable"),
        severity: "error",
      });
    }
  };

  const HandleDelete = (id) => {
    const data = rows.filter((s) => s.item_code !== id);
    const updatedSalesPerson = selectedSalesPerson.filter((s) => s.item != id);
    dispatch(UpdateProducts(data));
    const filteredItemDetails = itemDetails?.filter((s) => s.item_code !== id);
    dispatch(updateItemDetails(filteredItemDetails));
    dispatch(UpdateSalesPerson(updatedSalesPerson));
    const totalAmount = GetTotalPrice(data);
    const taxAmount =
      totalAmount -
      data.reduce((a, v) => (a = a + parseFloat(v.net_amount)), 0);
    dispatch(UpdatePayment({ ...amount, totalAmount, taxAmount }));
  };

  const HandleUpdateQuantity = (e, id) => {
    const IndexinstockPOS = productsPos.findIndex((s) => s.item_code == id);
    const ItemIndex = rows.findIndex((s) => s.item_code == id);
    const data = [...rows];

    if (ItemIndex != -1 && !isNaN(e.target.value)) {
      if (IndexinstockPOS > -1) {
        if (
          productsPos[IndexinstockPOS].actual_qty - isReservedInPOS(id) >=
          e.target.value
        ) {
          if (e.target.value >= 0) {
            data[ItemIndex].quantity = e.target.value;
            data[ItemIndex].qty = e.target.value;
          }
        } else {
          setAlert({
            open: true,
            message: t("checkout:stockunavailable"),
            severity: "warning",
          });
        }
      } else {
        setAlert({
          open: true,
          message: t("checkout:itemunavailable"),
          severity: "error",
        });
        data[ItemIndex].quantity = 0;
        data[ItemIndex].qty = 0;
      }
      data[ItemIndex].net_amount =
        data[ItemIndex].net_rate * data[ItemIndex].quantity;
      data[ItemIndex].discount_amount =
        data[ItemIndex].discount_amount * data[ItemIndex].quantity;
      setsuggestion([]);
      setSearchProduct("");
      dispatch(UpdateProducts(rows));
      loadItemDetails(rows);
      const totalAmount = GetTotalPrice(rows);
      const taxAmount =
        totalAmount -
        rows.reduce((a, v) => (a = a + parseFloat(v.net_amount)), 0);
      dispatch(UpdatePayment({ ...amount, totalAmount, taxAmount }));
      if (amount.isReturn) {
        dispatch(UpdatePayment({ ...amount, totalAmount, taxAmount }));
      }
    }
  };

  const handleEnterKey = (e, value) => {
    var data = suggestion.find((s) => s.item_code == value);
    const ItemIndex = suggestion.findIndex((s) => s.item_code == value);
    if (ItemIndex != -1) {
      if (e.key === "Enter") {
        handleAddproduct(data.item_name);
      }
    } else {
      if (e.key === "Enter") {
        setsuggestion([]);
        setSearchProduct("");
      }
    }
  };

  useEffect(() => {
    if (amount.isOrder) {
      const totalAmount = order.grand_total;
      dispatch(UpdatePayment({ ...amount, totalAmount }));
    } else {
      let finalTotalAmount;
      const totalAmount = GetTotalPrice(rows);
      const depositAmount = amount?.depositAmount;
      if (depositAmount != 0) {
        finalTotalAmount = GetTotalPrice(rows) - Number(depositAmount);
      } else {
        finalTotalAmount = GetTotalPrice(rows);
      }
      const taxAmount =
        totalAmount -
        rows.reduce((a, v) => (a = a + parseFloat(v.net_amount)), 0);

      dispatch(
        UpdatePayment({ ...amount, totalAmount: finalTotalAmount, taxAmount })
      );
    }
    var initLines = [];
    if (rows.length >= 4) {
      setInitialLines([]);
    } else {
      for (let i = 0; i < 4 - rows.length; i++) {
        initLines.push(i);
      }
      setInitialLines(initLines);
    }
  }, [rows, itemDetails, changes]);

  return (
    <Box className={classes.root}>
      <div style={{ width: "100%" }}>
        {showCalculator ? (
          <ResponsiveReactGridLayout
            containerPadding={[0, 0]}
            className="layout"
            autoSize={true}
            cols={{ lg: 12, md: 12, sm: 12, xs: 8, xxs: 8 }}
            layouts={state.layouts}
            margin={[20, 20]}
            isDraggable={false}
            isResizable={false}
          >
            <div
              key="2"
              data-grid={{ w: 9, h: 3, x: 0, y: 0, minW: 4, minH: 3 }}
            >
              <Box justifyContent="center" display="flex">
                <TransitionAlerts
                  severity={alert.severity}
                  setAlert={setAlert}
                  open={alert.open}
                  message={alert.message}
                  timeout={3000}
                />
              </Box>
              <BasicTable
                discount={discount}
                amount={amount}
                coupon={coupon}
                suggestion={suggestion}
                initialLines={initialLines}
                rows={rows}
                handleAddproduct={handleAddproduct}
                HandleDelete={HandleDelete}
                CancelCheckoutData={talonProps.CancelCheckoutData}
                HandleQuantityChange={HandleQuantityChange}
                searchProduct={searchProduct}
                transfer={false}
                HandleUpdateQuantity={HandleUpdateQuantity}
                GetTotalPrice={GetTotalPrice}
                handleEnterKey={handleEnterKey}
                setsuggestion={setsuggestion}
                setSearchProduct={setSearchProduct}
                setInitialLines={setInitialLines}
                checkout={true}
                barcode={true}
              />
            </div>
            <div
              key="1"
              data-grid={{ w: 3, h: 2.5, x: 9, y: 0, minW: 2, minH: 3 }}
            >
              <Calculator
                isCalcul={true}
                amount={0}
                HandleAmount={(amount) => {}}
                basic={true}
              />
            </div>
            <div
              key="3"
              data-grid={{ w: 3, h: 1, x: 9, y: 2, minW: 2, minH: 3 }}
            >
              <Freebutton
                checkout={true}
                rows={rows}
                initialLines={initialLines}
                GetTotalPrice={GetTotalPrice}
              />
            </div>
            <div
              key="0"
              data-grid={{ w: 9, h: 3, x: 0, y: 3, minW: 2, minH: 2 }}
            >
              <Products
                initialLines={initialLines}
                GetTotalPrice={GetTotalPrice}
              />
            </div>
          </ResponsiveReactGridLayout>
        ) : (
          <ResponsiveReactGridLayout
            containerPadding={[0, 0]}
            className="layout"
            autoSize={true}
            cols={{ lg: 12, md: 12, sm: 12, xs: 8, xxs: 8 }}
            layouts={state.layouts}
            margin={[20, 20]}
            isDraggable={false}
            isResizable={false}
          >
            <div
              key="2"
              data-grid={{ w: 9, h: 3, x: 0, y: 0, minW: 4, minH: 3 }}
            >
              <Box justifyContent="center" display="flex">
                <TransitionAlerts
                  severity={alert.severity}
                  setAlert={setAlert}
                  open={alert.open}
                  message={alert.message}
                  timeout={3000}
                />
              </Box>
              <BasicTable
                discount={discount}
                amount={amount}
                coupon={coupon}
                suggestion={suggestion}
                initialLines={initialLines}
                rows={rows}
                handleAddproduct={handleAddproduct}
                HandleDelete={HandleDelete}
                CancelCheckoutData={talonProps.CancelCheckoutData}
                HandleQuantityChange={HandleQuantityChange}
                searchProduct={searchProduct}
                transfer={false}
                HandleUpdateQuantity={HandleUpdateQuantity}
                GetTotalPrice={GetTotalPrice}
                handleEnterKey={handleEnterKey}
                setsuggestion={setsuggestion}
                setSearchProduct={setSearchProduct}
                setInitialLines={setInitialLines}
                checkout={true}
              />
            </div>
            <div
              key="3"
              data-grid={{ w: 3, h: 1, x: 9, y: 2, minW: 2, minH: 3 }}
            >
              <Freebutton
                checkout={true}
                rows={rows}
                initialLines={initialLines}
                GetTotalPrice={GetTotalPrice}
              />
            </div>
            <div
              key="0"
              data-grid={{ w: 9, h: 3, x: 0, y: 3, minW: 2, minH: 2 }}
            >
              <Products
                initialLines={initialLines}
                GetTotalPrice={GetTotalPrice}
              />
            </div>
            {/* <div
              key="0"
              data-grid={{ w: 9, h: 3, x: 0, y: 3, minW: 2, minH: 2 }}
            >
              <ProductFinancierCheckout
                initialLines={initialLines}
                GetTotalPrice={GetTotalPrice}
              />
            </div> */}
          </ResponsiveReactGridLayout>
        )}
      </div>
    </Box>
  );
};
